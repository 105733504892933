import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from './../../shared/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { saveAs } from 'file-saver';
import { FormBuilder, FormGroup ,FormControl, Validators} from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { UserService } from './../../@core/data/users.service';
import { ConfirmationService } from 'primeng/api';



@Component({
  selector: 'educational',
  template: `
  <style>
  .mat-form-field--inline {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .mat-form-field--inline .mat-form-field {
        display: inline-block;
        width: 100%;
        vertical-align: middle;
    }

    .mat-form-field--inline .mat-form-field:nth-child(1) {
        margin-right: 10px;
    }
    
    @media (max-width: 767px) {
      #selectLine{  
        width: 65%;
      } 
    }
      
    @media (min-width: 1200px) {
      #selectLine{  
        width: 145%;
      } 
    }
      
    @media (min-width: 768px) {
      #selectLine{  
        width: 65%;
      } 
    }
      
    @media (min-width: 992px) {
      #selectLine{  
        width: 145%;
      } 
    }

    .example-full-width {
      
    }
  </style>
  <nb-card status="success"  [style.height.px]="630" [style.overflow]="'auto'">
    <nb-card-header>Add Educational Details</nb-card-header>
    <nb-card-body> 
      <span *ngIf="instructionValidation===false" style="color:red;">Please add medium of instruction which is below subjects.</span>
      <form [formGroup]="edu_form"  class="step-container">
        <div class="row"><h5>{{autoName}}</h5></div>
        <div class="row">
          <div class="col-md-3">Course Name : </div>
          <div class="col-md-2"> 
            <!--<input  ngModel="{{edu_data?.CourseName}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.courseNameCtrl.invalid && (edu_form.controls.courseNameCtrl.dirty || edu_form.controls.courseNameCtrl.touched)}" formControlName="courseNameCtrl" placeholder="" id="" class="form-control">-->
            <mat-form-field id="selectLine" >
              <mat-select ngModel="{{course_name}}" name="coursename" formControlName="courseNameCtrl" disabled>
                  <mat-option value="{{course.CourseShortForm}}" *ngFor="let course of course_data">{{course.CourseName}}</mat-option>
              </mat-select>
            </mat-form-field>
            <span *ngIf="courseValidation===false" style="color:red;">Please select College Year.</span>  
          </div>
          <div class="col-md-3" style="padding-left: 9%;" *ngIf="admin_show == 'true'">Total Hours : </div>
          <div class="col-md-2" style="padding-left: 5%;" *ngIf="admin_show == 'true'">
            <input  ngModel="{{edu_data?.total_weeks}}" nbInput type="text" formControlName="totalweeksCtrl" placeholder="" id="" class="form-control"> 
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">College Year : </div>
          <div class="col-md-2">
            <mat-form-field id="selectLine" >
              <mat-select ngModel="{{college_year}}" name="collegeYear" formControlName="collegeYearCtrl" disabled>
                <mat-option *ngIf="edu_level == 'Course' || edu_level == 'Degree' || edu_level == 'Master' || edu_level == 'Bachelor'" value="First">First</mat-option>
                <mat-option *ngIf="edu_level == 'Course' || edu_level == 'Degree' || edu_level == 'Master' || edu_level == 'Bachelor'" value="Second">Second</mat-option>
                <mat-option *ngIf="edu_level == 'Degree' || edu_level == 'Bachelor'" value="Third">Third</mat-option>
              </mat-select>
            </mat-form-field>
            <span *ngIf="collegeYearValidation===false" style="color:red;">Please select College Year.</span> 
          </div>
          <div class="col-md-3" style="padding-left: 9%;">Class Obtained : </div>
          <div class="col-md-2" style="padding-left: 5%;">
              <mat-form-field id="selectLine" >
                  <mat-select ngModel="{{edu_data?.overall_class}}" name="overall_class" formControlName="overallclassCtrl">
                      <mat-option value="First Class">First</mat-option>
                      <mat-option value="Second Class">Second</mat-option>
                      <mat-option value="Third Class">Third</mat-option>
                      <mat-option value="Pass">Pass</mat-option>
                      <mat-option value="Passes">Passes</mat-option>
                      <mat-option value="Distinction">Distinction</mat-option>
                      <mat-option value="First Class with Distinction">First Class with Distinction</mat-option>
                  </mat-select>
              </mat-form-field>
              <span *ngIf="classValidation===false" style="color:red;">Please select class</span> 
          </div>
      </div>
      <div class="row">
          <div class="col-md-3">Month : </div>
          <div class="col-md-2">
            <mat-form-field id="selectLine" >
              <mat-select  ngModel="{{edu_data?.Month}}" name="month" formControlName="monthCtrl">
                  <mat-option value="January">January</mat-option>
                  <mat-option value="February">February</mat-option>
                  <mat-option value="March">March</mat-option>
                  <mat-option value="April">April</mat-option>
                  <mat-option value="May">May</mat-option>
                  <mat-option value="June">June</mat-option>
                  <mat-option value="July">July</mat-option>
                  <mat-option value="August">August</mat-option>
                  <mat-option value="September">September</mat-option>
                  <mat-option value="October">October</mat-option>
                  <mat-option value="November">November</mat-option>
                  <mat-option value="December">December</mat-option>
              </mat-select>
            </mat-form-field>
            <span *ngIf="monthValidation===false" style="color:red;">Please select month</span> 
          </div>
          <div class="col-md-3" style="padding-left: 9%;">Year : </div>
          <div class="col-md-2" style="padding-left: 5%;">
              <mat-form-field id="selectLine" >
                  <mat-select ngModel="{{edu_data?.Year}}" name="year" formControlName="yearCtrl">
                      <mat-option value="{{years}}" *ngFor="let years of years_array">{{years}}</mat-option>
                  </mat-select>
              </mat-form-field>
              <span *ngIf="yearValidation===false" style="color:red;">Please select year.</span> 
          </div>
      </div>
      <div class="row" *ngIf = 'showSpecialization == "show"'>
        <div class="col-md-3">Specialization in : </div>
        <div class="col-md-2">
            <mat-form-field id="selectLine">
                <mat-select ngModel="{{edu_data?.specialization}}" name="year" formControlName="specializationCtrl">
                    <mat-option value="Arts">Arts</mat-option>
                    <mat-option value="Science">Science</mat-option>
                    <mat-option value="Commerce">Commerce</mat-option>
                </mat-select>
            </mat-form-field>
          <!-- <input  ngModel="{{edu_data?.specialization}}" nbInput type="text"  formControlName="specializationCtrl" placeholder="eg. Geology"  id="" class="form-control"> -->
        </div>
      </div>
      <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-2" style="text-align: center;"><label >Subject Name</label></div>
          <div class="col-md-3"  style="text-align: center;"><label>Marks Obtained</label></div>
          <div class="col-md-2"  style="text-align: center;"><label>Marks Out Of</label></div>
          <div class="col-md-2"  style="text-align: center;" *ngIf="admin_show == 'true'" ><label>No. of lec per week</label></div>
          <div class="col-md-2"  style="text-align: center;" *ngIf="admin_show == 'true' && edu_form.controls['courseNameCtrl'].value == 'B.Sc.' || edu_form.controls['courseNameCtrl'].value == 'M.Sc.' || edu_form.controls['courseNameCtrl'].value == 'J.C.'  " ><label>No. of hrs/week in practs</label></div>
  
        </div>
      <div class="row">
        <div class="col-md-1">Subject 1 :</div>
        <div class="col-md-3">
          <div class="row" *ngIf= "edu_data?.Subject_First != null && edu_data?.Subject_First != '' && edu_data?.Subject_First != undefined && showcodeDropdown == false">
            <input  disabled   [value]="edu_data?.Subject_First" style="width: 80%;" nbInput type="text" id="" >
            <i class="fas fa-pen-alt fa-2x" (click)="showcoursecodeDropdown()"></i>
          </div>

          <mat-form-field appearance="outline" [formGroup]="formData" *ngIf = "showcodeDropdown == true">
            <mat-select placeholder="Subject" style="width: 75%;" #singleSelect >
              <mat-option >
                <ngx-mat-select-search [formControl]="filterInput2"  [placeholderLabel]="'Subjects...'"   >
                  <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let college2 of course_code2 | filter: {Course_Subject_name : filterText2}" [value]="college2" (click)="getCourse(college2)">
                {{college2.Course_Subject_name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
    
          <div class="row">
            Can't Find ? &nbsp; <i class="fas fa-phone-square fa-lg" title="Contact" style="color: green;" (click)= "showContactDetails()"></i>
          </div>
          <!-- <mat-form-field class="example-full-width" appearance="outline">
            <textarea matInput  type="text" [value]="filterText2 ? filterText2 : edu_data?.Subject_First" 
            [formControl]="filterInput2" 
            [matAutocomplete]="phdauto2" [matAutocompleteDisabled]="autocheck" (change)='getSubject($event)' ></textarea>
          </mat-form-field> 

          <mat-autocomplete #phdauto2="matAutocomplete"  style="width:150% !important;  ">
              <mat-option style="width:500% !important;  " *ngFor="let college2 of course_code2 | filter: {Course_Subject_name : filterText2}" [value]="college2.Course_Subject_name" (click)="getCourse(college2)">
                  {{ college2.Course_Subject_name }}
              </mat-option>
              </mat-autocomplete> -->
        </div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_obt_First}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.markFirstCtrl.invalid && (edu_form.controls.markFirstCtrl.dirty || edu_form.controls.markFirstCtrl.touched)}" formControlName="markFirstCtrl" placeholder="Marks Obtained" (change)="hetmarksObt()"  id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_out_First}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.markFirstOutCtrl.invalid && (edu_form.controls.markFirstOutCtrl.dirty || edu_form.controls.markFirstOutCtrl.touched)}" formControlName="markFirstOutCtrl" placeholder="Out of" (change)="totalsMarksOut()" id="" class="form-control"></div>
        
        <div class="col-md-1"><input *ngIf="admin_show == 'true'"  
        style = 'width: 200% !important; ' ngModel=" {{edu_data?.lecture_hour_First }}" 
        nbInput type="text" formControlName="lecturehourFirstCtrl" 
        placeholder="No. of lec per week" id="" class="form-control"></div>


        <div class="col-md-1"><i *ngIf ='edu_form.controls.lecturehourFirstCtrl.value' title = 'Copy'
         class="fa fa-clone" style="text-align: right;
          padding-left: 72% !important;" aria-hidden="true" 
          fa-lg (click)= "copyLecValue()"></i></div>
        
        <div class="col-md-1"><input *ngIf="admin_show == 'true'  && edu_form.controls['courseNameCtrl'].value == 'B.Sc.' || edu_form.controls['courseNameCtrl'].value == 'M.Sc.' || edu_form.controls['courseNameCtrl'].value == 'J.C.'" style = 'width: 200% !important; '  [ngModel]="prac_hour1 ? prac_hour1 : edu_data?.practical_hour_First" nbInput type="text" formControlName="practicalhourFirstCtrl" placeholder="No. of hrs/week in practs" id="" class="form-control"></div>
        
        <div class="col-md-1"><i *ngIf ="admin_show == 'true'  && edu_form.controls.practicalhourFirstCtrl.value && edu_form.controls['courseNameCtrl'].value == 'B.Sc.' || edu_form.controls['courseNameCtrl'].value == 'M.Sc.' || edu_form.controls['courseNameCtrl'].value == 'J.C.'" title = 'Copy' class="fa fa-clone" style="text-align: right; padding-left: 72% !important;" aria-hidden="true" fa-lg (click)= "copyHoursValue()"></i></div>
      </div>
      <div class="row">
        <div class="col-md-1">Subject 2 :</div>
        <div class="col-md-3">
          <div class="row" *ngIf= "showcodeDropdown2 == false">
            <input  disabled   [value]="edu_data?.Subject_Second" style="width: 79%;" nbInput type="text" id="" >
            <i class="fas fa-pen-alt fa-2x" (click)="showcoursecodeDropdown2()"></i>
          </div>

          <mat-form-field appearance="outline" [formGroup]="formData" *ngIf = "showcodeDropdown2 == true">
            <mat-select placeholder="Subject" style="width: 75%;"   #singleSelect2 >
              <mat-option >
                <ngx-mat-select-search [formControl]="filterInput"  [placeholderLabel]="'Subjects...'"   >
                  <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let college of course_code | filter: {Course_Subject_name : filterText}" [value]="college" (click)="getCourse2(college)">
                {{college.Course_Subject_name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
    
          <div class="row">
            Can't Find ? &nbsp; <i class="fas fa-phone-square fa-lg" title="Contact" style="color: green;" (click)= "showContactDetails()"></i>
          </div>
          <!-- <mat-form-field class="example-full-width" appearance="outline">
            <textarea matInput  type="text"   [value]="filterText ? filterText : edu_data?.Subject_Second"
              [matAutocomplete]="phdauto" [formControl]="filterInput" [matAutocompleteDisabled]="autocheck" (change)='getSubject2($event)' ></textarea>
          </mat-form-field> 
          
          <mat-autocomplete #phdauto="matAutocomplete"  style="width:200% !important;  ">
            <mat-option style="width:800% !important;  " *ngFor="let college of course_code | filter: {Course_Subject_name : filterText}" [value]="college.Course_Subject_name" (click)="getCourse2(college)">
              {{ college.Course_Subject_name }}
            </mat-option>
          </mat-autocomplete> -->
          <!-- <textarea  ngModel="{{edu_data?.Subject_Second}}" nbInput type="text"  formControlName="subjectSecondCtrl" placeholder="name" id="" class="form-control"></textarea> -->
        </div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_obt_Second}}" nbInput type="text" formControlName="markSecondCtrl" placeholder="Marks Obtained" (change)="hetmarksObt()"  id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_out_Second}}" nbInput type="text"  formControlName="markSecondOutCtrl" placeholder="Out of" (change)="totalsMarksOut()" id="" class="form-control"></div>
        <div class="col-md-2"><input *ngIf="admin_show == 'true'"  ngModel="{{lec_per_week2 ? lec_per_week2 : edu_data?.lecture_hour_Second}}" nbInput type="text" formControlName="lecturehourSecondCtrl" placeholder="No. of lec per week" id="" class="form-control"></div>
        <div class="col-md-2"><input *ngIf="admin_show == 'true'  &&  edu_form.controls['courseNameCtrl'].value == 'B.Sc.' || edu_form.controls['courseNameCtrl'].value == 'M.Sc.' || edu_form.controls['courseNameCtrl'].value == 'J.C.'" ngModel="{{prac_hour2 ? prac_hour2 : edu_data?.practical_hour_Second }}" nbInput type="text" formControlName="practicalhourSecondCtrl" placeholder="No. of hrs/week in practs" id="" class="form-control"></div>
      </div>
      <div class="row">
        <div class="col-md-1">Subject 3 :</div>
        <div class="col-md-3">
          <div class="row" *ngIf= "edu_data?.Subject_Third != null && edu_data?.Subject_Third != '' && edu_data?.Subject_Third != undefined && showcodeDropdown3 == false">
            <input  disabled   [value]="edu_data?.Subject_Third" style="width: 80%;" nbInput type="text" id="" >
            <i class="fas fa-pen-alt fa-2x" (click)="showcoursecodeDropdown3()"></i>
          </div>

          <mat-form-field appearance="outline" [formGroup]="formData" *ngIf = "showcodeDropdown3 == true">
            <mat-select placeholder="Subject" style="width: 75%;"   #singleSelect2 >
              <mat-option >
                <ngx-mat-select-search [formControl]="filterInput3" [placeholderLabel]="'Subjects...'"    >
                  <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let college of course_code3 | filter: {Course_Subject_name : filterText3}" [value]="college" (click)="getCourse3(college)">
                {{college.Course_Subject_name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
  
          <div class="row">
            Can't Find ? &nbsp; <i class="fas fa-phone-square fa-lg" title="Contact" style="color: green;" (click)= "showContactDetails()"></i>
          </div>
  
          <!-- <mat-form-field class="example-full-width" appearance="outline">
            <textarea matInput  type="text" [value]="filterText3 ? filterText3 : edu_data?.Subject_Third"
            [formControl]="filterInput3"   [matAutocomplete]="phdauto3" [matAutocompleteDisabled]="autocheck" (change)='getSubject3($event)' ></textarea>
          </mat-form-field> 

          <mat-autocomplete #phdauto3="matAutocomplete"  style="width:200% !important;  ">
              <mat-option style="width:800% !important;  " *ngFor="let college of course_code3 | filter: {Course_Subject_name : filterText3}" [value]="college.Course_Subject_name" (click)="getCourse3(college)">
                  {{ college.Course_Subject_name }}
              </mat-option>
          </mat-autocomplete> --> 
          <!-- <textarea  ngModel="{{edu_data?.Subject_Third}}" nbInput type="text"  formControlName="subjectThirdCtrl" placeholder="name" id="" class="form-control"></textarea> -->
        </div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_obt_Third}}" nbInput type="text"  formControlName="markThirdCtrl" placeholder="Marks Obtained" (change)="hetmarksObt()"  id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_out_Third}}" nbInput type="text"  formControlName="markThirdOutCtrl" placeholder="Out of" (change)="totalsMarksOut()" id="" class="form-control"></div>
        <div class="col-md-2"><input *ngIf="admin_show == 'true'" ngModel="{{prac_hour3 ? prac_hour3 : edu_data?.lecture_hour_Third}}" nbInput type="text" formControlName="lecturehourThirdCtrl" placeholder="No. of lec per week" id="" class="form-control"></div>
        <div class="col-md-2"><input *ngIf="admin_show == 'true'  && edu_form.controls['courseNameCtrl'].value == 'B.Sc.' || edu_form.controls['courseNameCtrl'].value == 'M.Sc.' || edu_form.controls['courseNameCtrl'].value == 'J.C.'"  ngModel="{{lec_per_week3 ? lec_per_week3 : edu_data?.practical_hour_Third  }}" nbInput type="text" formControlName="practicalhourThirdCtrl" placeholder="No. of hrs/week in practs" id="" class="form-control"></div>
      </div>
      <div class="row">
        <div class="col-md-1">Subject 4 :</div>
        <div class="col-md-3">
          <div class="row" *ngIf= "edu_data?.Subject_Fourth != null && edu_data?.Subject_Fourth != '' && edu_data?.Subject_Fourth != undefined && showcodeDropdown4 == false">
            <input  disabled   [value]="edu_data?.Subject_Fourth" style="width: 80%;" nbInput type="text" id="" >
            <i class="fas fa-pen-alt fa-2x" (click)="showcoursecodeDropdown4()"></i>
          </div>

          <mat-form-field appearance="outline" [formGroup]="formData" *ngIf = "showcodeDropdown4 == true">
            <mat-select placeholder="Course Code" style="width: 75%;"   #singleSelect2 >
              <mat-option >
                <ngx-mat-select-search [formControl]="filterInput4" [placeholderLabel]="'Subjects...'"    >
                  <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let college of course_code4 | filter: {Course_Subject_name : filterText4}" [value]="college" (click)="getCourse4(college)">
                {{college.Course_Subject_name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
  
          <div class="row">
            Can't Find ? &nbsp; <i class="fas fa-phone-square fa-lg" title="Contact" style="color: green;" (click)= "showContactDetails()"></i>
          </div>  
          <!-- <mat-form-field class="example-full-width" appearance="outline">
            <textarea matInput  type="text" [value]="filterText4 ? filterText4 : edu_data?.Subject_Fourth"
            [formControl]="filterInput4"   [matAutocomplete]="phdauto4" [matAutocompleteDisabled]="autocheck" (change)='getSubject4($event)' ></textarea>
          </mat-form-field> 

          <mat-autocomplete #phdauto4="matAutocomplete"  style="width:200% !important;  ">
              <mat-option style="width:800% !important;  " *ngFor="let college of course_code4 | filter: {Course_Subject_name : filterText4}" [value]="college.Course_Subject_name" (click)="getCourse4(college)">
                  {{ college.Course_Subject_name }}
              </mat-option>
          </mat-autocomplete> -->
          <!-- <textarea  ngModel="{{edu_data?.Subject_Fourth}}" nbInput type="text"  formControlName="subjectFourthCtrl" placeholder="name" id="" class="form-control"></textarea> -->
        </div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_obt_Fourth}}" nbInput type="text"  formControlName="markFourthCtrl" placeholder="Marks Obtained" (change)="hetmarksObt()"  id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_out_Fourth}}" nbInput type="text"  formControlName="markFourthOutCtrl" placeholder="Out of" (change)="totalsMarksOut()" id="" class="form-control"></div>
        <div class="col-md-2"><input *ngIf="admin_show == 'true'"  ngModel="{{lec_per_week4 ? lec_per_week4 : edu_data?.lecture_hour_Fourth}}" nbInput type="text" formControlName="lecturehourFourthCtrl" placeholder="No. of lec per week" id="" class="form-control"></div>
        <div class="col-md-2"><input *ngIf="admin_show == 'true'  && edu_form.controls['courseNameCtrl'].value == 'B.Sc.' || edu_form.controls['courseNameCtrl'].value == 'M.Sc.' || edu_form.controls['courseNameCtrl'].value == 'J.C.'"  ngModel="{{prac_hour4 ? prac_hour4 : edu_data?.practical_hour_Fourth  }}" nbInput type="text" formControlName="practicalhourFourthCtrl" placeholder="No. of hrs/week in practs" id="" class="form-control"></div>
      </div>
      <div class="row">
        <div class="col-md-1">Subject 5 :</div>
        <div class="col-md-3">
          <div class="row" *ngIf= "edu_data?.Subject_Fifth != null && edu_data?.Subject_Fifth != '' && edu_data?.Subject_Fifth != undefined && showcodeDropdown5 == false">
            <input  disabled   [value]="edu_data?.Subject_Fifth" style="width: 80%;" nbInput type="text" id="" >
            <i class="fas fa-pen-alt fa-2x" (click)="showcoursecodeDropdown5()"></i>
          </div>

          <mat-form-field appearance="outline" [formGroup]="formData" *ngIf = "showcodeDropdown5 == true">
            <mat-select placeholder="Subject" style="width: 75%;"   #singleSelect2 >
              <mat-option >
                <ngx-mat-select-search [formControl]="filterInput5" [placeholderLabel]="'Subjects...'"    >
                  <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let college of course_code5 | filter: {Course_Subject_name : filterText5}" [value]="college" (click)="getCourse5(college)">
                {{college.Course_Subject_name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
      
            <div class="row">
              Can't Find ? &nbsp; <i class="fas fa-phone-square fa-lg" title="Contact" style="color: green;" (click)= "showContactDetails()"></i>
            </div>
          <!-- <mat-form-field class="example-full-width" appearance="outline">
            <textarea matInput  type="text" [value]="filterText5 ? filterText5 : edu_data?.Subject_Fifth"
            [formControl]="filterInput5"   [matAutocomplete]="phdauto5" [matAutocompleteDisabled]="autocheck" (change)='getSubject5($event)' ></textarea>
          </mat-form-field> 

          <mat-autocomplete #phdauto5="matAutocomplete"  style="width:200% !important;  ">
              <mat-option style="width:800% !important;  " *ngFor="let college of course_code5 | filter: {Course_Subject_name : filterText5}" [value]="college.Course_Subject_name" (click)="getCourse5(college)">
                  {{ college.Course_Subject_name }}
              </mat-option>
          </mat-autocomplete> -->
         <!-- <textarea  ngModel="{{edu_data?.Subject_Fifth}}" nbInput type="text"  formControlName="subjectFifthCtrl" placeholder="name" id="" class="form-control"></textarea> -->
        </div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_obt_Fifth}}" nbInput type="text" formControlName="markFifthCtrl" placeholder="Marks Obtained" (change)="hetmarksObt()"  id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_out_Fifth}}" nbInput type="text" formControlName="markFifthOutCtrl" placeholder="Out of" (change)="totalsMarksOut()" id="" class="form-control"></div>
        <div class="col-md-2"><input *ngIf="admin_show == 'true'"  ngModel="{{lec_per_week5 ? lec_per_week5 :edu_data?.lecture_hour_Fifth}}" nbInput type="text" formControlName="lecturehourFifthCtrl" placeholder="No. of lec per week" id="" class="form-control"></div> 
        <div class="col-md-2"><input *ngIf="admin_show == 'true'  && edu_form.controls['courseNameCtrl'].value == 'B.Sc.' || edu_form.controls['courseNameCtrl'].value == 'M.Sc.' || edu_form.controls['courseNameCtrl'].value == 'J.C.'"  ngModel="{{prac_hour5 ? prac_hour5 : edu_data?.practical_hour_Fifth}}" nbInput type="text" formControlName="practicalhourFifthCtrl" placeholder="No. of hrs/week in practs" id="" class="form-control"></div>
      </div>
      <div class="row">
        <div class="col-md-1">Subject 6 :</div>
        <div class="col-md-3">
          <div class="row" *ngIf= "edu_data?.Subject_Six != null && edu_data?.Subject_Six != '' && edu_data?.Subject_Six != undefined && showcodeDropdown6 == false">
            <input  disabled   [value]="edu_data?.Subject_Six" style="width: 80%;" nbInput type="text" id="" >
            <i class="fas fa-pen-alt fa-2x" (click)="showcoursecodeDropdown6()"></i>
          </div>

          <mat-form-field appearance="outline" [formGroup]="formData" *ngIf = "showcodeDropdown6 == true">
            <mat-select placeholder="Course Code" style="width: 75%;"   #singleSelect2 >
              <mat-option >
                <ngx-mat-select-search [formControl]="filterInput6" [placeholderLabel]="'Subjects...'"    >
                  <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let college of course_code6 | filter: {Course_Subject_name : filterText6}" [value]="college" (click)="getCourse6(college)">
                {{college.Course_Subject_name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div class="row">
            Can't Find ? &nbsp; <i class="fas fa-phone-square fa-lg" title="Contact" style="color: green;" (click)= "showContactDetails()"></i>
          </div>

          <!-- <mat-form-field class="example-full-width" appearance="outline">
            <textarea matInput  type="text" [value]="filterText6 ? filterText6 : edu_data?.Subject_Six"
            [formControl]="filterInput6"   [matAutocomplete]="phdauto6" [matAutocompleteDisabled]="autocheck" (change)='getSubject6($event)' ></textarea>
          </mat-form-field> 

          <mat-autocomplete #phdauto6="matAutocomplete"  style="width:200% !important;  ">
              <mat-option style="width:800% !important;  " *ngFor="let college of course_code6 | filter: {Course_Subject_name : filterText6}" [value]="college.Course_Subject_name" (click)="getCourse6(college)">
                  {{ college.Course_Subject_name }}
              </mat-option>
          </mat-autocomplete> -->
          <!-- <textarea  ngModel="{{edu_data?.Subject_Six}}" nbInput type="text"  formControlName="subjectSixthCtrl" placeholder="name" id="" class="form-control"></textarea> --> 
        </div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_obt_Six}}" nbInput type="text" formControlName="markSixthCtrl" placeholder="Marks Obtained" (change)="hetmarksObt()"  id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_out_Six}}" nbInput type="text" formControlName="markSixthOutCtrl" placeholder="Out of" (change)="totalsMarksOut()" id="" class="form-control"></div>
        <div class="col-md-2"><input *ngIf="admin_show == 'true'"  ngModel="{{lec_per_week6 ? lec_per_week6 : edu_data?.lecture_hour_Six}}" nbInput type="text" formControlName="lecturehourSixCtrl" placeholder="No. of lec per week" id="" class="form-control"></div>
        <div class="col-md-2"><input *ngIf="admin_show == 'true'  && edu_form.controls['courseNameCtrl'].value == 'B.Sc.' || edu_form.controls['courseNameCtrl'].value == 'M.Sc.' || edu_form.controls['courseNameCtrl'].value == 'J.C.'"  ngModel="{{prac_hour6 ? prac_hour6 : edu_data?.practical_hour_Six }}" nbInput type="text" formControlName="practicalhourSixCtrl" placeholder="No. of hrs/week in practs" id="" class="form-control"></div>
      </div>
      <div class="row">
        <div class="col-md-1">Subject 7 :</div>
        <div class="col-md-3">
          <div class="row" *ngIf= "edu_data?.Subject_Seventh != null && edu_data?.Subject_Seventh != '' && edu_data?.Subject_Seventh != undefined && showcodeDropdown7 == false">
            <input  disabled   [value]="edu_data?.Subject_Seventh" style="width: 80%;" nbInput type="text" id="" >
            <i class="fas fa-pen-alt fa-2x" (click)="showcoursecodeDropdown7()"></i>
          </div>

          <mat-form-field appearance="outline" [formGroup]="formData" *ngIf = "showcodeDropdown7 == true">
            <mat-select placeholder="Course Code" style="width: 75%;"   #singleSelect2 >
              <mat-option >
                <ngx-mat-select-search [formControl]="filterInput7"  [placeholderLabel]="'Subjects...'"   >
                  <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let college of course_code7 | filter: {Course_Subject_name : filterText7}" [value]="college" (click)="getCourse7(college)">
                {{college.Course_Subject_name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div class="row">
            Can't Find ? &nbsp; <i class="fas fa-phone-square fa-lg" title="Contact" style="color: green;" (click)= "showContactDetails()"></i>
          </div>
          <!-- <mat-form-field class="example-full-width" appearance="outline">
            <textarea matInput  type="text" [value]="filterText7 ? filterText7 : edu_data?.Subject_Seventh"
            [formControl]="filterInput7"   [matAutocomplete]="phdauto7" [matAutocompleteDisabled]="autocheck" (change)='getSubject7($event)' ></textarea>
          </mat-form-field> 

          <mat-autocomplete #phdauto7="matAutocomplete"  style="width:200% !important;  ">
              <mat-option style="width:800% !important;  " *ngFor="let college of course_code7 | filter: {Course_Subject_name : filterText7}" [value]="college.Course_Subject_name" (click)="getCourse7(college)">
                  {{ college.Course_Subject_name }}
              </mat-option>
          </mat-autocomplete> -->
         <!--  <textarea  ngModel="{{edu_data?.Subject_Seventh}}" nbInput type="text"  formControlName="subjectSeventhCtrl" placeholder="name" id="" class="form-control"></textarea> --> 
        </div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_obt_Seventh}}" nbInput type="text" formControlName="markSeventhCtrl" placeholder="Marks Obtained" (change)="hetmarksObt()"  id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_out_Seventh}}" nbInput type="text" formControlName="markSeventhOutCtrl" placeholder="Out of" (change)="totalsMarksOut()" id="" class="form-control"></div>
        <div class="col-md-2"><input *ngIf="admin_show == 'true'"  ngModel="{{lec_per_week7 ? lec_per_week7 : edu_data?.lecture_hour_Seventh}}" nbInput type="text" formControlName="lecturehourSeventhCtrl" placeholder="No. of lec per week" id="" class="form-control"></div>
        <div class="col-md-2"><input *ngIf="admin_show == 'true'  && edu_form.controls['courseNameCtrl'].value == 'B.Sc.' || edu_form.controls['courseNameCtrl'].value == 'M.Sc.' || edu_form.controls['courseNameCtrl'].value == 'J.C.'"  ngModel="{{prac_hour7 ? prac_hour7 : edu_data?.practical_hour_Seventh }} " nbInput type="text" formControlName="practicalhourSeventhCtrl" placeholder="No. of hrs/week in practs" id="" class="form-control"></div>
      </div>
      <div class="row">
        <div class="col-md-1">Subject 8 :</div>
        <div class="col-md-3">
          <div class="row" *ngIf= "edu_data?.Subject_Eighth != null && edu_data?.Subject_Eighth != '' && edu_data?.Subject_Eighth != undefined && showcodeDropdown8 == false">
            <input  disabled   [value]="edu_data?.Subject_Eighth" style="width: 80%;" nbInput type="text" id="" >
            <i class="fas fa-pen-alt fa-2x" (click)="showcoursecodeDropdown8()"></i>
          </div>

          <mat-form-field appearance="outline" [formGroup]="formData" *ngIf = "showcodeDropdown8 == true">
            <mat-select placeholder="Subject" style="width: 75%;"   #singleSelect2 >
              <mat-option >
                <ngx-mat-select-search [formControl]="filterInput8"  [placeholderLabel]="'Subjects...'"   >
                  <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let college of course_code8 | filter: {Course_Subject_name : filterText8}" [value]="college" (click)="getCourse8(college)">
                {{college.Course_Subject_name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div class="row">
            Can't Find ? &nbsp; <i class="fas fa-phone-square fa-lg" title="Contact" style="color: green;" (click)= "showContactDetails()"></i>
          </div>
   </div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_obt_Eighth}}" nbInput type="text" formControlName="markEighthCtrl" placeholder="Marks Obtained" (change)="hetmarksObt()"  id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_out_Eighth}}" nbInput type="text" formControlName="markEighthOutCtrl" placeholder="Out of" (change)="totalsMarksOut()" id="" class="form-control"></div>
        <div class="col-md-2"><input *ngIf="admin_show == 'true'" ngModel="{{lec_per_week8 ? lec_per_week8 : edu_data?.lecture_hour_Eighth}}" nbInput type="text" formControlName="lecturehourEighthCtrl" placeholder="No. of lec per week" id="" class="form-control"></div>
      <div class="col-md-2"><input *ngIf="admin_show == 'true'  && edu_form.controls['courseNameCtrl'].value == 'B.Sc.' || edu_form.controls['courseNameCtrl'].value == 'M.Sc.' || edu_form.controls['courseNameCtrl'].value == 'J.C.'"  ngModel="{{prac_hour8 ? prac_hour8 :edu_data?.practical_hour_Eighth }} " nbInput type="text" formControlName="practicalhourEighthCtrl" placeholder="No. of hrs/week in practs" id="" class="form-control"></div>
      </div>
      <br>
      <div class="row">
        <div class="col-md-1">Subject 9 :</div>
        <div class="col-md-3">
          <div class="row" *ngIf= "edu_data?.Subject_Ninth != null && edu_data?.Subject_Ninth != '' && edu_data?.Subject_Ninth != undefined && showcodeDropdown9 == false">
            <input  disabled   [value]="edu_data?.Subject_Ninth" style="width: 80%;" nbInput type="text" id="" >
            <i class="fas fa-pen-alt fa-2x" (click)="showcoursecodeDropdown9()"></i>
          </div>

          <mat-form-field appearance="outline" [formGroup]= "formData" *ngIf = "showcodeDropdown9 == true">
            <mat-select placeholder="Subject" style="width: 75%;"   #singleSelect2 >
              <mat-option >
                <ngx-mat-select-search [formControl]="filterInput9"  [placeholderLabel]="'Subjects...'"   >
                  <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let college of course_code9 | filter: {Course_Subject_name : filterText9}" [value]="college" (click)="getCourse9(college)">
                {{college.Course_Subject_name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div class="row">
            Can't Find ? &nbsp; <i class="fas fa-phone-square fa-lg" title="Contact" style="color: green;" (click)= "showContactDetails()"></i>
          </div>
         </div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_obt_Ninth}}" nbInput type="text" formControlName="markNinthCtrl" placeholder="Marks Obtained"  id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_out_Ninth}}" nbInput type="text" formControlName="markNinthOutCtrl" placeholder="Out of" id="" class="form-control"></div>
        <div class="col-md-2"><input *ngIf="admin_show == 'true'" ngModel="{{lec_per_week9 ? lec_per_week9 : edu_data?.lecture_hour_Ninth}}" nbInput type="text" formControlName="lecturehourNinthCtrl" placeholder="No. of lec per week" id="" class="form-control"></div>
      <div class="col-md-2"><input *ngIf="admin_show == 'true'  && edu_form.controls['courseNameCtrl'].value == 'B.Sc.' || edu_form.controls['courseNameCtrl'].value == 'M.Sc.'|| edu_form.controls['courseNameCtrl'].value == 'J.C.'"  ngModel="{{prac_hour9 ? prac_hour9 :edu_data?.practical_hour_Ninth }} " nbInput type="text" formControlName="practicalhourNinthCtrl" placeholder="No. of hrs/week in practs" id="" class="form-control"></div>
      </div>
      <br>
      <div class="row">
        <div class="col-md-1">Subject 10 :</div>
        <div class="col-md-3">
          <div class="row" *ngIf= "edu_data?.Subject_Tenth != null && edu_data?.Subject_Tenth != '' && edu_data?.Subject_Tenth != undefined && showcodeDropdown10 == false">
            <input  disabled   [value]="edu_data?.Subject_Tenth" style="width: 80%;" nbInput type="text" id="" >
            <i class="fas fa-pen-alt fa-2x" (click)="showcoursecodeDropdown10()"></i>
          </div>

          <mat-form-field appearance="outline" [formGroup]= "formData" *ngIf = "showcodeDropdown10 == true">
            <mat-select placeholder="Subject" style="width: 75%;"   #singleSelect2 >
              <mat-option >
                <ngx-mat-select-search [formControl]="filterInput10"  [placeholderLabel]="'Subjects...'"   >
                  <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let college of course_code10 | filter: {Course_Subject_name : filterText10}" [value]="college" (click)="getCourse10(college)">
                {{college.Course_Subject_name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div class="row">
            Can't Find ? &nbsp; <i class="fas fa-phone-square fa-lg" title="Contact" style="color: green;" (click)= "showContactDetails()"></i>
          </div>
         </div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_obt_Tenth}}" nbInput type="text" formControlName="markTenthCtrl" placeholder="Marks Obtained"  id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_out_Tenth}}" nbInput type="text" formControlName="markTenthOutCtrl" placeholder="Out of" id="" class="form-control"></div>
        <div class="col-md-2"><input *ngIf="admin_show == 'true'" ngModel="{{lec_per_week10 ? lec_per_week10 : edu_data?.lecture_hour_Tenth}}" nbInput type="text" formControlName="lecturehourTenthCtrl" placeholder="No. of lec per week" id="" class="form-control"></div>
      <div class="col-md-2"><input *ngIf="admin_show == 'true'  && edu_form.controls['courseNameCtrl'].value == 'B.Sc.' || edu_form.controls['courseNameCtrl'].value == 'M.Sc.' || edu_form.controls['courseNameCtrl'].value == 'J.C.'"  ngModel="{{prac_hour10 ? prac_hour10 :edu_data?.practical_hour_Tenth }} " nbInput type="text" formControlName="practicalhourTenthCtrl" placeholder="No. of hrs/week in practs" id="" class="form-control"></div>
      </div>
      <div class="row">
          <div class="col-md-2">Total obtained marks : </div>
          <div class="col-md-2">
            <input  ngModel="{{totalMarks ? totalMarks : edu_data?.overall_marks_obt}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.overmarksobtCtrl.invalid && (edu_form.controls.overmarksobtCtrl.dirty || edu_form.controls.overmarksobtCtrl.touched)}" formControlName="overmarksobtCtrl" placeholder="" id="" class="form-control"> 
          </div>
          <div class="col-md-2">Out of : </div>
          <div class="col-md-2">
            <input  ngModel="{{totalMarksOut ? totalMarksOut : edu_data?.overall_marks_out}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.overmarksoutCtrl.invalid && (edu_form.controls.overmarksoutCtrl.dirty || edu_form.controls.overmarksoutCtrl.touched)}" formControlName="overmarksoutCtrl" placeholder="" id="" class="form-control"> 
          </div>
      </div>
      <br>
      <div class="row">
        <div class="col-md-2">Medium of instruction : </div>
        <div class="col-md-2"> <input  [ngModel]="edu_data ? edu_data?.medium_instruction : 'English' " nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.instructionCtrl.invalid && (edu_form.controls.instructionCtrl.dirty || edu_form.controls.instructionCtrl.touched)}" formControlName="instructionCtrl" placeholder="" id="" class="form-control"> </div>
        <div class="col-md-2" *ngIf="admin_show == 'true'">Special Instruction : </div>
        <div class="col-md-2" *ngIf="admin_show == 'true'"><input  ngModel="{{edu_data?.special_instruction}}" nbInput type="text" formControlName="specialInstrctionCtrl" placeholder="" id="" class="form-control"> </div>          
      </div>
      </form>
    </nb-card-body>
    <nb-card-footer>
        <button nbButton hero status="primary" (click)="dismiss()">Close</button> 
        <button *ngIf="amount_paid == 'false'" nbButton status="info" (click)="saveEdu()">save</button>
    </nb-card-footer>
</nb-card>
  `,
  providers: [ ConfirmationService]
})
export class EducationalFormYearComponent implements OnInit {
  FeedbackForm : FormGroup;
  @Input() user_id: any;
  @Input() edu_id: any;
  @Input() amount_paid:string;
  @Input() admin_show:string;
  @Input() autoName :string;
  @Input() showSpecialization :string;
  @Input() course_name_auto :string;
  @Input() education_level_auto : string;
  @Input() college_year_auto : string;
  // @Input() courseShortName: string;

  validations_flag = false;
  feedback_message ;
  showExperience = false;
  totalMarks: number;
  totalMarksOut: number;
  @Input() yearValue = [];  

  edu_form: FormGroup;
  years_array;
  courseValidation = true;
  collegeYearValidation = true;
  classValidation  = true;
  monthValidation  = true;
  yearValidation = true;
  attemptValidation  = true;
  instructionValidation = true;
  edu_data;
  course_data;
  user: any;
  hourValue: any;
  hourValue8: any;
  hourValue7: any;
  hourValue6: any;
  hourValue5: any;
  hourValue4: any;
  hourValue3: any;
  hourValue2: any;
  lecVal1: any;
  lecVal2: any;
  lecVal3: any;
  lecVal4: any;
  lecVal5: any;
  lecVal6: any;
  lecVal7: any;
  lecVal8: any;
  lecVal9: any;
  hourValue9: any;
  lecVal10: any;
  hourValue10: any;

  public filterInput = new FormControl();
  public filterInput2 = new FormControl();
  public filterInput3 = new FormControl();
  public filterInput4 = new FormControl();
  public filterInput5 = new FormControl();
  public filterInput6 = new FormControl();
  public filterInput7= new FormControl();
  public filterInput8 = new FormControl();
  public filterInput9 = new FormControl();
  public filterInput10 = new FormControl();
  subjectName: any;
  withoutCodeSub: any;
  subjectName2: any;
  withoutCodeSub2: any;
  withoutCodeSub3: any;
  subjectName3: any;
  subjectName4: any;
  withoutCodeSub4: any;
  subjectName5: any;
  withoutCodeSub5: any;
  subjectName6: any;
  withoutCodeSub6: any;
  subjectName7: any;
  withoutCodeSub7: any;
  subjectName8: any;
  withoutCodeSub8: any;
  subjectName9: any;
  withoutCodeSub9: any;
  subjectName10: any;
  withoutCodeSub10: any;
  course_code: any;
  course_code8: any;
  course_code2:  any;
  course_code3: any;
  course_code4:  any;
  course_code5: any;
  course_code6:  any;
  course_code7: any;
  course_code9:  any;
  course_code10: any;
  prac_hour1: any;
  prac_hour2: any;
  prac_hour3: any;
  prac_hour4: any;
  prac_hour5: any;
  prac_hour6: any;
  prac_hour7: any;
  prac_hour8: any;
  prac_hour9: any;
  prac_hour10: any;
  lec_per_week1: string;
  lec_per_week2: string;
  lec_per_week3: string;
  lec_per_week4: string;
  lec_per_week5: string;
  lec_per_week6: string;
  lec_per_week7: string;
  lec_per_week8: string;
  lec_per_week9: string;
  lec_per_week10: string;
  public filterText: any;
  public filterText2: any;
  filterText3 : any
  public filterText4: any;
  public filterText5: any;
  public filterText6: any;
  public filterText7: any;
  public filterText8: any;
  public filterText9: any;
  public filterText10: any;
  autocheck = true;
  showcodeDropdown: boolean = true;
  showcodeDropdown2: boolean = true;
  showcodeDropdown3: boolean = true;
  showcodeDropdown4: boolean = true;
  showcodeDropdown5: boolean = true;
  showcodeDropdown6: boolean = true;
  showcodeDropdown7: boolean = true;
  showcodeDropdown8: boolean = true;
  showcodeDropdown9: boolean = true;
  showcodeDropdown10: boolean = true;
  newcourse_code = [];
  course_name;
  college_year;
  edu_level;
  constructor(protected api : ApiService,
    private router: Router,
    private userService: UserService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private confirmationService: ConfirmationService,
    protected ref: NbDialogRef<EducationalFormYearComponent>) { }

  async ngOnInit() {
    // this.userService.onUserChange()
    // .subscribe((user: any) => this.user = user);
    // if(this.user.role == 'admin'){
    //   this.admin_show = false;
    // }

    this.edu_form = this.formBuilder.group({
      courseNameCtrl: [ '' ,  Validators.required],
      collegeYearCtrl : [ '' ,  Validators.required],
      overallclassCtrl : [ '' ,  Validators.required],
      monthCtrl : [ '' ,  Validators.required],
      yearCtrl : [ '' ,  Validators.required],
      overmarksobtCtrl : [ '' ,  Validators.required],
      overmarksoutCtrl : [ '' ,  Validators.required],
      subjectFirstCtrl : [ ''],
      markFirstCtrl : [ '' ,  Validators.required],
      markFirstOutCtrl : [ '' ,  Validators.required],
      subjectSecondCtrl : [ ''],
      markSecondCtrl : [ ''],
      markSecondOutCtrl : [ ''],
      subjectThirdCtrl : [ '' ],
      markThirdCtrl : [ '' ],
      markThirdOutCtrl : [ '' ],
      subjectFourthCtrl : [ '' ],
      markFourthCtrl : [ '' ],
      markFourthOutCtrl : [ '' ],
      subjectFifthCtrl : [ ''],
      markFifthCtrl : [ '' ],
      markFifthOutCtrl : [ '' ],
      subjectSixthCtrl : [ '' ],
      markSixthCtrl : [ ''],
      markSixthOutCtrl : [ ''],
      subjectSeventhCtrl : [ ''],
      markSeventhCtrl : [ ''],
      markSeventhOutCtrl : [ ''],
      subjectEighthCtrl : [ ''],
      markEighthCtrl : [ ''],
      markEighthOutCtrl: [ ''],
      instructionCtrl : [ '' ,  Validators.required],
      lecturehourFirstCtrl : [ ''],
      lecturehourSecondCtrl : [ ''],
      lecturehourThirdCtrl : [ ''],
      lecturehourFourthCtrl : [ ''],
      lecturehourFifthCtrl : [ ''],
      lecturehourSixCtrl : [ ''],
      lecturehourSeventhCtrl : [ ''],
      lecturehourEighthCtrl : [ ''],
      practicalhourFirstCtrl : [''],
      practicalhourSecondCtrl : [ ''],
      practicalhourThirdCtrl : [ ''],
      practicalhourFourthCtrl : [ ''],
      practicalhourFifthCtrl : [ ''],
      practicalhourSixCtrl : [ ''],
      practicalhourSeventhCtrl : [ ''],
      practicalhourEighthCtrl : [ ''],
      specialInstrctionCtrl: [ ''],
      totalweeksCtrl : [ ''],
      specializationCtrl: [ ''],
      markNinthCtrl : [''],
      markTenthCtrl : [''],
      markNinthOutCtrl :[''],
      markTenthOutCtrl : [''],
      lecturehourNinthCtrl : [''],
      lecturehourTenthCtrl : [''],
      practicalhourNinthCtrl : [''],
      practicalhourTenthCtrl : ['']
    })


    if(this.college_year_auto == 'firstYear'){
      this.college_year = 'First';
    }else if(this.college_year_auto == 'secondYear'){
      this.college_year = 'Second';
    }else if(this.college_year_auto == 'thirdYear'){
      this.college_year = 'Third';
    }

    this.api.getCouses().subscribe((data: any) => { 
          if(data['status'] == 200){
            this.course_data = data['data'];
           
            if(this.course_name_auto!=null){
              this.course_data.forEach(element => {
                if(element.CourseName == this.course_name_auto || element.CourseShortForm == this.course_name_auto){
                  this.course_name = element.CourseShortForm;
                  this.edu_level = element.type;
                }
              });
            }
          }else{

          }
   

    this.api.getCouseCodeYearly(this.course_name,this.college_year ).subscribe( (data: any) => { 
          if(data['status'] == 200){
            this.autocheck = false
            this.filterText = "";
            this.course_code = data['data'];
            this.filterInput
            .valueChanges
            .debounceTime(200)
            .subscribe(term => {
            this.filterText = term;

         });

           
          }else{

          }
    });

   this.api.getCouseCodeYearly(this.course_name,this.college_year ).subscribe( (info: any) => { 
        if(info['status'] == 200){
          this.autocheck = false
          this.filterText2 = "";
          this.course_code2 = info['data'];

          this.filterInput2
            .valueChanges
            .debounceTime(200)
            .subscribe(term => {
            //this.filterText = term;
            this.filterText2 = term;
          });
         
        }else{

        }
  });
  
  this.api. getCouseCodeYearly(this.course_name,this.college_year ).subscribe((data: any) => { 

      if(data['status'] == 200){
        this.autocheck = false
        this.course_code3 = data['data']
        this.filterText3 = "";
          this.filterInput3
          .valueChanges
          .debounceTime(200)
          .subscribe(term => {
          this.filterText3 = term;
          //this.filterText2 = term;
        });


       
      }else{

      }
  });

  this.api. getCouseCodeYearly(this.course_name,this.college_year ).subscribe((data: any) => { 

      if(data['status'] == 200){
        this.autocheck = false
        this.course_code4 = data['data']
        this.filterText4 = "";
          this.filterInput4
          .valueChanges
          .debounceTime(200)
          .subscribe(term => {
          this.filterText4 = term;
          //this.filterText2 = term;
        });


       
      }else{

      }
  });

  this.api. getCouseCodeYearly(this.course_name,this.college_year ).subscribe((data: any) => { 

      if(data['status'] == 200){
        this.autocheck = false
        this.course_code5 = data['data']
        this.filterText5 = "";
          this.filterInput5
          .valueChanges
          .debounceTime(200)
          .subscribe(term => {
          this.filterText5 = term;
          //this.filterText2 = term;
        });


       
      }else{

      }
  });

  this.api. getCouseCodeYearly(this.course_name,this.college_year ).subscribe( (data: any) => { 

      if(data['status'] == 200){
        this.autocheck = false
        this.course_code6 = data['data']
        this.filterText6 = "";
          this.filterInput6
          .valueChanges
          .debounceTime(200)
          .subscribe(term => {
          this.filterText6 = term;
          //this.filterText2 = term;
        });


       
      }else{

      }
  });

  this.api. getCouseCodeYearly(this.course_name,this.college_year ).subscribe((data: any) => { 

      if(data['status'] == 200){
        this.autocheck = false
        this.course_code7 = data['data']
        this.filterText7 = "";
          this.filterInput7
          .valueChanges
          .debounceTime(200)
          .subscribe(term => {
          this.filterText7 = term;
          //this.filterText2 = term;
        });


       
      }else{

      }
  });

  this.api. getCouseCodeYearly(this.course_name,this.college_year ).subscribe((data: any) => { 

      if(data['status'] == 200){
        this.autocheck = false
        this.course_code8 = data['data']
        this.filterText8 = "";
          this.filterInput8
          .valueChanges
          .debounceTime(200)
          .subscribe(term => {
          this.filterText8 = term;
          //this.filterText2 = term;
        });


       
      }else{

      }
  });

  this.api. getCouseCodeYearly(this.course_name,this.college_year ).subscribe((data: any) => { 

      if(data['status'] == 200){
        this.autocheck = false
        this.course_code9 = data['data']
        this.filterText9 = "";
          this.filterInput9
          .valueChanges
          .debounceTime(200)
          .subscribe(term => {
          this.filterText9 = term;
          //this.filterText2 = term;
        });


       
      }else{

      }
  });

  this.api. getCouseCodeYearly(this.course_name,this.college_year ).subscribe((data: any) => { 

      if(data['status'] == 200){
        this.autocheck = false
        this.course_code10 = data['data']
        this.filterText10 = "";
          this.filterInput10
          .valueChanges
          .debounceTime(200)
          .subscribe(term => {
          this.filterText10 = term;
          //this.filterText2 = term;
        });


       
      }else{

      }
  });

});


   

    const now = new Date().getUTCFullYear();    
    this.years_array = Array(now - (now - 50)).fill('').map((v, idx) => now - idx);
    
    
    if(this.user_id != null && this.edu_id != null){
      this.api.getEducation(this.edu_id,this.user_id)
      .subscribe(
        (data: any) => { 
          if(data['status'] == 200){
            this.edu_data = data['data'];
            this.course_name = this.edu_data.CourseName;
            this.college_year = this.edu_data.CollegeYear;
            if(this.edu_data.Subject_First != null && this.edu_data.Subject_First != '' && this.edu_data.Subject_First != undefined){
              this.showcodeDropdown = false
              
            }else{
              
              this.showcodeDropdown = true
            }
            if(this.edu_data.Subject_Second != null){
              this.showcodeDropdown2 = false
              
            }else{
              
              this.showcodeDropdown2 = true
            }
            if(this.edu_data.Subject_Third != null && this.edu_data.Subject_Third != '' && this.edu_data.Subject_Third != undefined){
              this.showcodeDropdown3 = false
              
            }else{
              
              this.showcodeDropdown3 = true
            }
            if(this.edu_data.Subject_Fourth != null && this.edu_data.Subject_Fourth != '' && this.edu_data.Subject_Fourth != undefined){
              this.showcodeDropdown4 = false
              
            }else{
              
              this.showcodeDropdown4 = true
            }
            if(this.edu_data.Subject_Fifth != null && this.edu_data.Subject_Fifth != '' && this.edu_data.Subject_Fifth != undefined){
              this.showcodeDropdown5 = false
              
            }else{
              
              this.showcodeDropdown5 = true
            }
            if(this.edu_data.Subject_Six != null && this.edu_data.Subject_Six != '' && this.edu_data.Subject_Six != undefined){
              this.showcodeDropdown6 = false
              
            }else{
              
              this.showcodeDropdown6 = true
            }
            if(this.edu_data.Subject_Seventh != null && this.edu_data.Subject_Seventh != '' && this.edu_data.Subject_Seventh != undefined){
              this.showcodeDropdown7 = false
              
            }else{
              
              this.showcodeDropdown7 = true
            }
            if(this.edu_data.Subject_Eighth != null && this.edu_data.Subject_Eighth != '' && this.edu_data.Subject_Eighth != undefined){
              this.showcodeDropdown8 = false
              
            }else{
              
              this.showcodeDropdown8 = true
            }
            if(this.edu_data.Subject_Ninth != null && this.edu_data.Subject_Ninth != '' && this.edu_data.Subject_Ninth != undefined){
              this.showcodeDropdown9 = false
              
            }else{
              
              this.showcodeDropdown9 = true
            }
            if(this.edu_data.Subject_Tenth != null && this.edu_data.Subject_Tenth != '' && this.edu_data.Subject_Tenth != undefined){
              this.showcodeDropdown10 = false
              
            }else{
              
              this.showcodeDropdown10 = true
            }
            if(this.edu_data.practical_hour_First){

            }else {
            
            }
           
          }else{
            
          }
      });
    }else{
     

      
    }
  }

  getCourse(value){
    
    //this.filterText = "";
    this.subjectName = value

    if(this.course_name_auto == 'B.Sc.' || this.course_name_auto == 'Bachelor of Science' || this.course_name_auto == 'M.Sc.' || this.course_name_auto == 'Master of Science'  || this.course_name_auto == 'J.C.'){
      if (value.prac == "yes") {
        
        this.prac_hour1 = value.Lec_per_Week;
        this.lec_per_week1 = "-";
      } else {
        this.lec_per_week1 = value.Lec_per_Week;
        this.prac_hour1 = "-";
        
      }
    }else{
      this.lec_per_week1 = value.Lec_per_Week;
      this.prac_hour1 = "";
    }

    var count = 0
    this.newcourse_code = [];
    this.course_code.forEach(element => {
      count++
      if(element['id'] == this.subjectName['id']){
        
      }else{
        
        this.newcourse_code.push(element)

       
      }

      if(count == this.course_code.length){
        this.course_code = this.newcourse_code
      }
    })
    //this.filterPlaceholder = "Search";
    // this.adminApi.getAllCourse(value).subscribe((data)=>{
    //   this.courses = data['data'];
    // })
    // this.filterInput
    //   .valueChanges
    //   .debounceTime(200)
    //   .subscribe(term => {
    //   this.filterText = term;
    // });
  }

  getSubject(event : any){
    
    this.withoutCodeSub = event.target.value
    if(this.subjectName){
      
    }else{
      
    }
  }

  getCourse2(value){
    
    //this.filterText = "";
    this.subjectName2 = value
    if(this.course_name_auto == 'B.Sc.' || this.course_name_auto == 'Bachelor of Science' || this.course_name_auto == 'M.Sc.' || this.course_name_auto == 'Master of Science'  || this.course_name_auto == 'J.C.'){
      if (value.prac == "yes") {
        
        this.prac_hour2 = value.Lec_per_Week;
        this.lec_per_week2 = "-";
      } else {
        this.lec_per_week2 = value.Lec_per_Week;
        this.prac_hour2 = "-";
        
      }
    }else{
      this.lec_per_week2 = value.Lec_per_Week;
        this.prac_hour2 = "";
    }

    var count = 0
    this.newcourse_code = [];
    this.course_code3.forEach(element => {
      count++
      if(element['id'] == this.subjectName['id'] || element['id'] == this.subjectName2['id']){
        
      }else{
        
        this.newcourse_code.push(element)

       
      }

      if(count == this.course_code3.length){
        this.course_code3 = [];
        this.course_code3 = this.newcourse_code
      }
    })
  }

  getSubject2(event : any){
    
    this.withoutCodeSub2 = event.target.value
    
  }

  getCourse3(value){
    this.subjectName3 = value
    if(this.course_name_auto == 'B.Sc.' || this.course_name_auto == 'Bachelor of Science' || this.course_name_auto == 'M.Sc.' || this.course_name_auto == 'Master of Science'  || this.course_name_auto == 'J.C.'){
      if (value.prac == "yes") {
        
        this.prac_hour3 = value.Lec_per_Week;
        this.lec_per_week3 = "-";
      } else {
        this.lec_per_week3 = value.Lec_per_Week;
        this.prac_hour3 = "-";
        
      }
    }else{
      this.lec_per_week3 = value.Lec_per_Week;
      this.prac_hour3 = "-";
    }

    var count = 0
    this.newcourse_code = [];
    this.course_code4.forEach(element => {
      count++
      if(element['id'] == this.subjectName['id'] || element['id'] == this.subjectName2['id'] || element['id'] == this.subjectName3['id']){
        
      }else{
        
        this.newcourse_code.push(element)

       
      }

      if(count == this.course_code4.length){
        this.course_code4 = [];
        this.course_code4 = this.newcourse_code
      }
    })
  }

  getSubject3(event : any){
    this.withoutCodeSub3 = event.target.value  
  }

  getCourse4(value){
    
    this.subjectName4 = value
    if(this.course_name_auto == 'B.Sc.' || this.course_name_auto == 'Bachelor of Science' || this.course_name_auto == 'M.Sc.' || this.course_name_auto == 'Master of Science' || this.course_name_auto == 'J.C.'){
      if (value.prac == "yes") {
        
        this.prac_hour4 = value.Lec_per_Week;
        this.lec_per_week4 = "-";
      } else {
        this.lec_per_week4 = value.Lec_per_Week;
        this.prac_hour4 = "-";
        
      }
    }else{
      this.lec_per_week4 = value.Lec_per_Week;
        this.prac_hour4 = "";
    }
    var count = 0
    this.newcourse_code = [];
    this.course_code5.forEach(element => {
      count++
      if(element['id'] == this.subjectName['id'] || element['id'] == this.subjectName2['id'] || element['id'] == this.subjectName3['id'] || element['id'] == this.subjectName4['id']){
        
      }else{
        
        this.newcourse_code.push(element)

       
      }

      if(count == this.course_code5.length){
        this.course_code5 = [];
        this.course_code5 = this.newcourse_code
      }
    })
  }

  getSubject4(event : any){
    
    this.withoutCodeSub4 = event.target.value
    
  }

  getCourse5(value){
    
    this.subjectName5 = value
    if(this.course_name_auto == 'B.Sc.' || this.course_name_auto == 'Bachelor of Science' || this.course_name_auto == 'M.Sc.' || this.course_name_auto == 'Master of Science'  || this.course_name_auto == 'J.C.'){

      if (value.prac == "yes") {
        
        this.prac_hour5 = value.Lec_per_Week;
        this.lec_per_week5 = "-";
      } else {
        this.lec_per_week5 = value.Lec_per_Week;
        this.prac_hour5 = "-";
        
      }
    }else{
      this.lec_per_week5 = value.Lec_per_Week;
        this.prac_hour5 = "";
    }

    var count = 0
    this.newcourse_code = [];
    this.course_code6.forEach(element => {
      count++
      if(element['id'] == this.subjectName['id'] || element['id'] == this.subjectName2['id'] || element['id'] == this.subjectName3['id'] || element['id'] == this.subjectName4['id'] || element['id'] == this.subjectName5['id']){
        
      }else{
        
        this.newcourse_code.push(element)

       
      }

      if(count == this.course_code6.length){
        this.course_code6 = [];
        this.course_code6 = this.newcourse_code
      }
    })
  }

  getSubject5(event : any){
    
    this.withoutCodeSub5 = event.target.value
    
  }

  getCourse6(value){
   
    this.subjectName6 = value
    if(this.course_name_auto == 'B.Sc.' || this.course_name_auto == 'Bachelor of Science' || this.course_name_auto == 'M.Sc.' || this.course_name_auto == 'Master of Science'  || this.course_name_auto == 'J.C.'){
      if (value.prac == "yes") {
        
        this.prac_hour6 = value.Lec_per_Week;
        this.lec_per_week6 = "-";
      } else {
        this.lec_per_week6 = value.Lec_per_Week;
        this.prac_hour6 = "-";
        
      }
    }else{
      this.lec_per_week6 = value.Lec_per_Week;
        this.prac_hour6 = "";
    }

    var count = 0
    this.newcourse_code = [];
    this.course_code7.forEach(element => {
      count++
      if(element['id'] == this.subjectName['id'] || element['id'] == this.subjectName2['id'] || element['id'] == this.subjectName3['id'] || element['id'] == this.subjectName4['id'] || element['id'] == this.subjectName5['id'] || element['id'] == this.subjectName6['id']){
        
      }else{
        
        this.newcourse_code.push(element)

       
      }

      if(count == this.course_code7.length){
        this.course_code7 = [];
        this.course_code7 = this.newcourse_code
      }
    })
  }

  getSubject6(event : any){
    
    this.withoutCodeSub6 = event.target.value
    
  }

  getCourse7(value){
    
    this.subjectName7 = value
    if(this.course_name_auto == 'B.Sc.' || this.course_name_auto == 'Bachelor of Science' || this.course_name_auto == 'M.Sc.' || this.course_name_auto == 'Master of Science'  || this.course_name_auto == 'J.C.'){
      if (value.prac == "yes") {
        
        this.prac_hour7 = value.Lec_per_Week;
        this.lec_per_week7 = "-";
      } else {
        this.lec_per_week7 = value.Lec_per_Week;
        this.prac_hour7 = "-";
        
      }
    }else{
      this.lec_per_week7 = value.Lec_per_Week;
        this.prac_hour7 = "";
    }

    var count = 0
    this.newcourse_code = [];
    this.course_code8.forEach(element => {
      count++
      if(element['id'] == this.subjectName['id'] || element['id'] == this.subjectName2['id'] || element['id'] == this.subjectName3['id'] || element['id'] == this.subjectName4['id'] || element['id'] == this.subjectName5['id'] || element['id'] == this.subjectName6['id'] || element['id'] == this.subjectName7['id']){
        
      }else{
        
        this.newcourse_code.push(element)

       
      }

      if(count == this.course_code8.length){
        this.course_code8 = [];
        this.course_code8 = this.newcourse_code
      }
    })
  }

  getSubject8(event : any){
    
    this.withoutCodeSub8 = event.target.value
    
  }
  getCourse8(value){
    
    this.subjectName8 = value
    if(this.course_name_auto == 'B.Sc.' || this.course_name_auto == 'Bachelor of Science' || this.course_name_auto == 'M.Sc.' || this.course_name_auto == 'Master of Science'  || this.course_name_auto == 'J.C.'){
      if (value.prac == "yes") {
        
        this.prac_hour8 = value.Lec_per_Week;
        this.lec_per_week8 = "-";
      } else {
        this.lec_per_week8 = value.Lec_per_Week;
        this.prac_hour8 = "-";
        
      }
    }else{
      this.lec_per_week8 = value.Lec_per_Week;
        this.prac_hour8 = "";
    }
  }
  getSubject9(event : any){
    
    this.withoutCodeSub9 = event.target.value
    
  }
  getCourse10(value){
    
    this.subjectName10 = value
    if(this.course_name_auto == 'B.Sc.' || this.course_name_auto == 'Bachelor of Science' || this.course_name_auto == 'M.Sc.' || this.course_name_auto == 'Master of Science'  || this.course_name_auto == 'J.C.'){
      if (value.prac == "yes") {
        
        this.prac_hour10 = value.Lec_per_Week;
        this.lec_per_week10 = "-";
      } else {
        this.lec_per_week10 = value.Lec_per_Week;
        this.prac_hour10 = "-";
        
      }
    }else{
      this.lec_per_week10 = value.Lec_per_Week;
        this.prac_hour10 = "";
    }
  }

  getSubject10(event : any){
    
    this.withoutCodeSub10 = event.target.value
    
  }
  getCourse9(value){
    
    this.subjectName9 = value
    if(this.course_name_auto == 'B.Sc.' || this.course_name_auto == 'Bachelor of Science' || this.course_name_auto == 'M.Sc.' || this.course_name_auto == 'Master of Science'  || this.course_name_auto == 'J.C.'){
      if (value.prac == "yes") {
        
        this.prac_hour9 = value.Lec_per_Week;
        this.lec_per_week9 = "-";
      } else {
        this.lec_per_week9 = value.Lec_per_Week;
        this.prac_hour9 = "-";
        
      }
    }else{
      this.lec_per_week9 = value.Lec_per_Week;
        this.prac_hour9 = "";
    }
  }

  getSubject7(event : any){
    
    this.withoutCodeSub7 = event.target.value
    
  }


  saveEdu() {
    this.courseValidation = true;
    this.collegeYearValidation = true;
    this.classValidation = true;
    this.monthValidation = true;
    this.yearValidation = true;
    this.attemptValidation = true;
    this.instructionValidation = true;

    if (this.edu_form.valid==false){
      this.edu_form.controls.courseNameCtrl.markAsDirty();
      this.edu_form.controls.overmarksoutCtrl.markAsDirty();
      this.edu_form.controls.overmarksobtCtrl.markAsDirty();
      this.edu_form.controls.subjectFirstCtrl.markAsDirty();
      this.edu_form.controls.markFirstCtrl.markAsDirty();
      this.edu_form.controls.markFirstOutCtrl.markAsDirty();
      // this.edu_form.controls.subjectSecondCtrl.markAsDirty();
      // this.edu_form.controls.markSecondCtrl.markAsDirty();
      // this.edu_form.controls.markSecondOutCtrl.markAsDirty();
      // this.edu_form.controls.subjectThirdCtrl.markAsDirty();
      // this.edu_form.controls.markThirdCtrl.markAsDirty();
      // this.edu_form.controls.markThirdOutCtrl.markAsDirty();
      // this.edu_form.controls.subjectFourthCtrl.markAsDirty();
      // this.edu_form.controls.markFourthCtrl.markAsDirty();
      // this.edu_form.controls.markFourthOutCtrl.markAsDirty();
      this.edu_form.controls.instructionCtrl.markAsDirty();

      if(this.edu_form.controls.instructionCtrl.value === null || this.edu_form.controls.instructionCtrl.value ==='' || this.edu_form.controls.instructionCtrl.value ===undefined){
        this.instructionValidation = false;
      }else {
        this.instructionValidation = true;
      }

      if(this.edu_form.controls.courseNameCtrl.value === null || this.edu_form.controls.courseNameCtrl.value ==='' || this.edu_form.controls.courseNameCtrl.value ===undefined){
        this.courseValidation = false;
      }else {
        this.courseValidation = true;
      }
      
      if(this.edu_form.controls.collegeYearCtrl.value === null || this.edu_form.controls.collegeYearCtrl.value ==='' || this.edu_form.controls.collegeYearCtrl.value ===undefined){
        this.collegeYearValidation = false;
      }else {
        this.collegeYearValidation = true;
      }

      if(this.edu_form.controls.overallclassCtrl.value === null || this.edu_form.controls.overallclassCtrl.value ==='' || this.edu_form.controls.overallclassCtrl.value ===undefined){
        this.classValidation = false;
      }else {
        this.classValidation = true;
      }

      if(this.edu_form.controls.monthCtrl.value === null || this.edu_form.controls.monthCtrl.value ==='' || this.edu_form.controls.monthCtrl.value ===undefined){
        this.monthValidation = false;
      }else {
        this.monthValidation = true;
      }

      if(this.edu_form.controls.yearCtrl.value === null || this.edu_form.controls.yearCtrl.value ==='' || this.edu_form.controls.yearCtrl.value ===undefined){
        this.yearValidation = false;
      }else {
        this.yearValidation = true;
      }
    }else{
      var edu_data ={
        courseName : this.edu_form.controls.courseNameCtrl.value,
        collegeYear : this.edu_form.controls.collegeYearCtrl.value,
        overall_class: this.edu_form.controls.overallclassCtrl.value,
        month : this.edu_form.controls.monthCtrl.value,
        year : this.edu_form.controls.yearCtrl.value,
        overall_marks_obt : this.edu_form.controls.overmarksobtCtrl.value,
        overall_marks_out : this.edu_form.controls.overmarksoutCtrl.value,
        subjectFirst : this.subjectName ? this.subjectName['Course_Subject_name'] : this.withoutCodeSub,
        marks_obt_First : this.edu_form.controls.markFirstCtrl.value,
        marks_out_First: this.edu_form.controls.markFirstOutCtrl.value,
        subjectSecond : this.subjectName2 ? this.subjectName2['Course_Subject_name'] : this.withoutCodeSub2,
        marks_obt_Second: this.edu_form.controls.markSecondCtrl.value,
        marks_out_Second: this.edu_form.controls.markSecondOutCtrl.value,
        subjectThird : this.subjectName3 ? this.subjectName3['Course_Subject_name'] : this.withoutCodeSub3,
        marks_obt_Third : this.edu_form.controls.markThirdCtrl.value,
        marks_out_Third : this.edu_form.controls.markThirdOutCtrl.value,
        subjectFourth : this.subjectName4 ? this.subjectName4['Course_Subject_name'] : this.withoutCodeSub4,
        marks_obt_Fourth : this.edu_form.controls.markFourthCtrl.value,
        marks_out_Fourth : this.edu_form.controls.markFourthOutCtrl.value,
        subjectFifth : this.subjectName5 ? this.subjectName5['Course_Subject_name'] : this.withoutCodeSub5,
        marks_obt_Fifth : this.edu_form.controls.markFifthCtrl.value,
        marks_out_Fifth : this.edu_form.controls.markFifthOutCtrl.value,
        subjectSixth : this.subjectName6 ? this.subjectName6['Course_Subject_name'] : this.withoutCodeSub6,
        marks_obt_Six : this.edu_form.controls.markSixthCtrl.value,
        marks_out_Six : this.edu_form.controls.markSixthOutCtrl.value,
        subjectSeventh : this.subjectName7 ? this.subjectName7['Course_Subject_name'] : this.withoutCodeSub7,
        marks_obt_Seventh : this.edu_form.controls.markSeventhCtrl.value,
        marks_out_Seventh : this.edu_form.controls.markSeventhOutCtrl.value,
        subjectEighth : this.subjectName8 ? this.subjectName8['Course_Subject_name'] : this.withoutCodeSub8,
        marks_obt_Eighth : this.edu_form.controls.markEighthCtrl.value,
        marks_out_Eighth : this.edu_form.controls.markEighthOutCtrl.value,
        user_id : this.user_id,
        medium_instruction : this.edu_form.controls.instructionCtrl.value,
        id : this.edu_id,
        lecture_hour_First :this.subjectName ? this.lec_per_week1 :  this.edu_form.controls.lecturehourFirstCtrl.value,
        lecture_hour_Second :this.subjectName2 ? this.lec_per_week2 : this.edu_form.controls.lecturehourSecondCtrl.value,
        lecture_hour_Third :this.subjectName3 ? this.lec_per_week3 : this.edu_form.controls.lecturehourThirdCtrl.value,
        lecture_hour_Fourth :this.subjectName4 ? this.lec_per_week4 : this.edu_form.controls.lecturehourFourthCtrl.value,
        lecture_hour_Fifth : this.subjectName5 ? this.lec_per_week5 : this.edu_form.controls.lecturehourFifthCtrl.value,
        lecture_hour_Six : this.subjectName6 ? this.lec_per_week6 : this.edu_form.controls.lecturehourSixCtrl.value,
        lecture_hour_Seventh :this.subjectName7 ? this.lec_per_week7 :  this.edu_form.controls.lecturehourSeventhCtrl.value,
        lecture_hour_Eighth : this.subjectName8 ? this.lec_per_week8 :  this.edu_form.controls.lecturehourEighthCtrl.value,
        lecture_hour_Ninth : this.subjectName9 ? this.lec_per_week9 :  this.edu_form.controls.lecturehourNinthCtrl.value,
        lecture_hour_Tenth : this.subjectName10 ? this.lec_per_week10 :  this.edu_form.controls.lecturehourTenthCtrl.value,
        practical_hour_First : this.subjectName ? this.prac_hour1 : this.edu_form.controls.practicalhourFirstCtrl.value,
        practical_hour_Second : this.subjectName2 ? this.prac_hour2 : this.edu_form.controls.practicalhourSecondCtrl.value,
        practical_hour_Third :  this.subjectName3 ? this.prac_hour3 : this.edu_form.controls.practicalhourThirdCtrl.value,
        practical_hour_Fourth : this.subjectName4 ? this.prac_hour4 :  this.edu_form.controls.practicalhourFourthCtrl.value,
        practical_hour_Fifth :this.subjectName5 ? this.prac_hour5 : this.edu_form.controls.practicalhourFifthCtrl.value,
        practical_hour_Six :this.subjectName6 ? this.prac_hour6 :  this.edu_form.controls.practicalhourSixCtrl.value,
        practical_hour_Seventh :this.subjectName7 ? this.prac_hour7 :  this.edu_form.controls.practicalhourSeventhCtrl.value,
        practical_hour_Eighth : this.subjectName8 ? this.prac_hour8 : this.edu_form.controls.practicalhourEighthCtrl.value,
        practical_hour_Ninth : this.subjectName9 ? this.prac_hour9 : this.edu_form.controls. practicalhourNinthCtrl.value,
         practical_hour_Tenth : this.subjectName10 ? this.prac_hour10 : this.edu_form.controls.practicalhourNinthCtrl.value,
        total_weeks : this.edu_form.controls.totalweeksCtrl.value,
        special_instruction : this.edu_form.controls.specialInstrctionCtrl.value,
        specialization : this.edu_form.controls.specializationCtrl.value,
        marks_obt_Ninth : this.edu_form.controls.markNinthCtrl.value,
        marks_obt_Tenth : this.edu_form.controls.markTenthCtrl.value,
        marks_out_Ninth : this.edu_form.controls.markNinthOutCtrl.value,
        marks_out_Tenth : this.edu_form.controls.markTenthOutCtrl.value,
        subjectNinth : this.subjectName9 ? this.subjectName9['Course_Subject_name'] : this.withoutCodeSub9,
        subjectTenth : this.subjectName10 ? this.subjectName10['Course_Subject_name'] : this.withoutCodeSub10,
        

      }
     
      this.api.setEducationalValues(edu_data)
      .subscribe(
        (data: any) => {  
          this.ref.close(data);
          err => console.error(err) 
      });
    }
  }

  dismiss() {
    this.ref.close();
  }

  hetmarksObt(){
    this.totalMarks = 0
    
    if(this.edu_form.controls.markSecondCtrl.value != '' || this.edu_form.controls.markThirdCtrl.value != '' || this.edu_form.controls.markFourthCtrl.value != '' || this.edu_form.controls.markFifthCtrl.value != '' || this.edu_form.controls.markSixthCtrl.value != '' || this.edu_form.controls.markSeventhCtrl.value != '' || this.edu_form.controls.markEighthCtrl.value != ''){
      this.totalMarks = (Number(this.edu_form.controls.markFirstCtrl.value) + Number(this.edu_form.controls.markSecondCtrl.value) + Number(this.edu_form.controls.markThirdCtrl.value) + Number(this.edu_form.controls.markFourthCtrl.value) + Number(this.edu_form.controls.markFifthCtrl.value) + Number(this.edu_form.controls.markSixthCtrl.value) + Number(this.edu_form.controls.markSeventhCtrl.value) + Number(this.edu_form.controls.markEighthCtrl.value))
      
    }else if(this.edu_form.controls.markSecondCtrl.value != '' || this.edu_form.controls.markThirdCtrl.value != '' || this.edu_form.controls.markFourthCtrl.value != '' || this.edu_form.controls.markFifthCtrl.value != '' || this.edu_form.controls.markSixthCtrl.value != '' || this.edu_form.controls.markSeventhCtrl.value != ''){
      this.totalMarks = (Number(this.edu_form.controls.markFirstCtrl.value) + Number(this.edu_form.controls.markSecondCtrl.value) + Number(this.edu_form.controls.markThirdCtrl.value) + Number(this.edu_form.controls.markFourthCtrl.value) + Number(this.edu_form.controls.markFifthCtrl.value) + Number(this.edu_form.controls.markSixthCtrl.value) + Number(this.edu_form.controls.markSeventhCtrl.value))
      
    }else if(this.edu_form.controls.markSecondCtrl.value != '' || this.edu_form.controls.markThirdCtrl.value != '' || this.edu_form.controls.markFourthCtrl.value != '' || this.edu_form.controls.markFifthCtrl.value != '' || this.edu_form.controls.markSixthCtrl.value != '' ){
      this.totalMarks = (Number(this.edu_form.controls.markFirstCtrl.value) + Number(this.edu_form.controls.markSecondCtrl.value) + Number(this.edu_form.controls.markThirdCtrl.value) + Number(this.edu_form.controls.markFourthCtrl.value) + Number(this.edu_form.controls.markFifthCtrl.value) + Number(this.edu_form.controls.markSixthCtrl.value))
      
    }else if(this.edu_form.controls.markSecondCtrl.value != '' || this.edu_form.controls.markThirdCtrl.value != '' || this.edu_form.controls.markFourthCtrl.value != '' || this.edu_form.controls.markFifthCtrl.value != '' ){
      this.totalMarks = (Number(this.edu_form.controls.markFirstCtrl.value) + Number(this.edu_form.controls.markSecondCtrl.value) + Number(this.edu_form.controls.markThirdCtrl.value) + Number(this.edu_form.controls.markFourthCtrl.value) + Number(this.edu_form.controls.markFifthCtrl.value))
      
    }else if(this.edu_form.controls.markSecondCtrl.value != '' || this.edu_form.controls.markThirdCtrl.value != '' || this.edu_form.controls.markFourthCtrl.value != ''){
      this.totalMarks = (Number(this.edu_form.controls.markFirstCtrl.value) + Number(this.edu_form.controls.markSecondCtrl.value) + Number(this.edu_form.controls.markThirdCtrl.value) + Number(this.edu_form.controls.markFourthCtrl.value))
      
    }else if(this.edu_form.controls.markSecondCtrl.value != '' || this.edu_form.controls.markThirdCtrl.value != '' ){
      this.totalMarks = (Number(this.edu_form.controls.markFirstCtrl.value) + Number(this.edu_form.controls.markSecondCtrl.value) + Number(this.edu_form.controls.markThirdCtrl.value) )
      
    }else if(this.edu_form.controls.markSecondCtrl.value != ''){
      this.totalMarks = (Number(this.edu_form.controls.markSecondCtrl.value) + Number(this.edu_form.controls.markFirstCtrl.value))
      
    }
  }

  totalsMarksOut(){
    this.totalMarksOut = 0
    if(this.edu_form.controls.markSecondOutCtrl.value != '' || this.edu_form.controls.markThirdOutCtrl.value != '' || this.edu_form.controls.markFourthOutCtrl.value != '' || this.edu_form.controls.markFifthOutCtrl.value != '' || this.edu_form.controls.markSixthOutCtrl.value != '' || this.edu_form.controls.markSeventhOutCtrl.value != '' || this.edu_form.controls.markEighthOutCtrl.value){
     this.totalMarksOut = (Number(this.edu_form.controls.markFirstOutCtrl.value) + Number(this.edu_form.controls.markSecondOutCtrl.value) + Number(this.edu_form.controls.markThirdOutCtrl.value)+ Number(this.edu_form.controls.markFourthOutCtrl.value) + Number(this.edu_form.controls.markFifthOutCtrl.value) + Number(this.edu_form.controls.markSixthOutCtrl.value) + Number(this.edu_form.controls.markSeventhOutCtrl.value) + Number(this.edu_form.controls.markEighthOutCtrl.value))
    }else if(this.edu_form.controls.markSecondOutCtrl.value != '' || this.edu_form.controls.markThirdOutCtrl.value != '' || this.edu_form.controls.markFourthOutCtrl.value != '' || this.edu_form.controls.markFifthOutCtrl.value != '' || this.edu_form.controls.markSixthOutCtrl.value != '' || this.edu_form.controls.markSeventhOutCtrl.value != ''){
     this.totalMarksOut = (Number(this.edu_form.controls.markFirstOutCtrl.value) + Number(this.edu_form.controls.markSecondOutCtrl.value) + Number(this.edu_form.controls.markThirdOutCtrl.value)+ Number(this.edu_form.controls.markFourthOutCtrl.value) + Number(this.edu_form.controls.markFifthOutCtrl.value) + Number(this.edu_form.controls.markSixthOutCtrl.value) + Number(this.edu_form.controls.markSeventhOutCtrl.value))
    }else if(this.edu_form.controls.markSecondOutCtrl.value != '' || this.edu_form.controls.markThirdOutCtrl.value != '' || this.edu_form.controls.markFourthOutCtrl.value != '' || this.edu_form.controls.markFifthOutCtrl.value != '' || this.edu_form.controls.markSixthOutCtrl.value != '' ){
     this.totalMarksOut = (Number(this.edu_form.controls.markFirstOutCtrl.value) + Number(this.edu_form.controls.markSecondOutCtrl.value) + Number(this.edu_form.controls.markThirdOutCtrl.value)+ Number(this.edu_form.controls.markFourthOutCtrl.value) + Number(this.edu_form.controls.markFifthOutCtrl.value) + Number(this.edu_form.controls.markSixthOutCtrl.value))
    }else if(this.edu_form.controls.markSecondOutCtrl.value != '' || this.edu_form.controls.markThirdOutCtrl.value != '' || this.edu_form.controls.markFourthOutCtrl.value != '' || this.edu_form.controls.markFifthOutCtrl.value != '' ){
     this.totalMarksOut = (Number(this.edu_form.controls.markFirstOutCtrl.value) + Number(this.edu_form.controls.markSecondOutCtrl.value) + Number(this.edu_form.controls.markThirdOutCtrl.value)+ Number(this.edu_form.controls.markFourthOutCtrl.value) + Number(this.edu_form.controls.markFifthOutCtrl.value))
    }else if(this.edu_form.controls.markSecondOutCtrl.value != '' || this.edu_form.controls.markThirdOutCtrl.value != '' || this.edu_form.controls.markFourthOutCtrl.value != '' ){
     this.totalMarksOut = (Number(this.edu_form.controls.markFirstOutCtrl.value) + Number(this.edu_form.controls.markSecondOutCtrl.value) + Number(this.edu_form.controls.markThirdOutCtrl.value)+ Number(this.edu_form.controls.markFourthOutCtrl.value))
    }else if(this.edu_form.controls.markSecondOutCtrl.value != '' || this.edu_form.controls.markThirdOutCtrl.value != ''){
     this.totalMarksOut = (Number(this.edu_form.controls.markFirstOutCtrl.value) + Number(this.edu_form.controls.markSecondOutCtrl.value) + Number(this.edu_form.controls.markThirdOutCtrl.value))
    }else if(this.edu_form.controls.markSecondOutCtrl.value != ''){
     this.totalMarksOut = (Number(this.edu_form.controls.markFirstOutCtrl.value) + Number(this.edu_form.controls.markSecondOutCtrl.value))
    }
  }


 copyHoursValue(){
  
  if(this.edu_form.controls.practicalhourFirstCtrl.value){
    if(this.edu_form.controls.subjectFirstCtrl.value && this.edu_form.controls.subjectSecondCtrl.value && this.edu_form.controls.subjectThirdCtrl.value && this.edu_form.controls.subjectFourthCtrl.value && this.edu_form.controls.subjectFifthCtrl.value && this.edu_form.controls.subjectSixthCtrl.value && this.edu_form.controls.subjectSeventhCtrl.value && this.edu_form.controls.subjectEighthCtrl.value      ){
      this.hourValue =  this.edu_form.controls.practicalhourFirstCtrl.value;
      this.hourValue2 =  this.edu_form.controls.practicalhourFirstCtrl.value;
      this.hourValue3 =  this.edu_form.controls.practicalhourFirstCtrl.value;
      this.hourValue4 =  this.edu_form.controls.practicalhourFirstCtrl.value;
      this.hourValue5 =  this.edu_form.controls.practicalhourFirstCtrl.value;
      this.hourValue6 =  this.edu_form.controls.practicalhourFirstCtrl.value;
      this.hourValue7 =  this.edu_form.controls.practicalhourFirstCtrl.value;
      this.hourValue8 =  this.edu_form.controls.practicalhourFirstCtrl.value;

     


    }else if(this.edu_form.controls.subjectFirstCtrl.value && this.edu_form.controls.subjectSecondCtrl.value && this.edu_form.controls.subjectThirdCtrl.value && this.edu_form.controls.subjectFourthCtrl.value && this.edu_form.controls.subjectFifthCtrl.value && this.edu_form.controls.subjectSixthCtrl.value && this.edu_form.controls.subjectSeventhCtrl.value ){
     this.hourValue =  this.edu_form.controls.practicalhourFirstCtrl.value;
     this.hourValue2 =  this.edu_form.controls.practicalhourFirstCtrl.value;
     this.hourValue3 =  this.edu_form.controls.practicalhourFirstCtrl.value;
     this.hourValue4 =  this.edu_form.controls.practicalhourFirstCtrl.value;
     this.hourValue5 =  this.edu_form.controls.practicalhourFirstCtrl.value;
     this.hourValue6 =  this.edu_form.controls.practicalhourFirstCtrl.value;
     this.hourValue7 =  this.edu_form.controls.practicalhourFirstCtrl.value;
     }else if(this.edu_form.controls.subjectFirstCtrl.value && this.edu_form.controls.subjectSecondCtrl.value && this.edu_form.controls.subjectThirdCtrl.value && this.edu_form.controls.subjectFourthCtrl.value && this.edu_form.controls.subjectFifthCtrl.value && this.edu_form.controls.subjectSixthCtrl.value ){
       this.hourValue =  this.edu_form.controls.practicalhourFirstCtrl.value;
       this.hourValue2 =  this.edu_form.controls.practicalhourFirstCtrl.value;
       this.hourValue3 =  this.edu_form.controls.practicalhourFirstCtrl.value;
       this.hourValue4 =  this.edu_form.controls.practicalhourFirstCtrl.value;
       this.hourValue5 =  this.edu_form.controls.practicalhourFirstCtrl.value;
       this.hourValue6 =  this.edu_form.controls.practicalhourFirstCtrl.value;
      
     }else if(this.edu_form.controls.subjectFirstCtrl.value && this.edu_form.controls.subjectSecondCtrl.value && this.edu_form.controls.subjectThirdCtrl.value && this.edu_form.controls.subjectFourthCtrl.value && this.edu_form.controls.subjectFifthCtrl.value  ){
       this.hourValue =  this.edu_form.controls.practicalhourFirstCtrl.value;
       this.hourValue2 =  this.edu_form.controls.practicalhourFirstCtrl.value;
       this.hourValue3 =  this.edu_form.controls.practicalhourFirstCtrl.value;
       this.hourValue4 =  this.edu_form.controls.practicalhourFirstCtrl.value;
       this.hourValue5 =  this.edu_form.controls.practicalhourFirstCtrl.value;
      
     }else if(this.edu_form.controls.subjectFirstCtrl.value && this.edu_form.controls.subjectSecondCtrl.value && this.edu_form.controls.subjectThirdCtrl.value && this.edu_form.controls.subjectFourthCtrl.value ){
       this.hourValue =  this.edu_form.controls.practicalhourFirstCtrl.value;
       this.hourValue2 =  this.edu_form.controls.practicalhourFirstCtrl.value;
       this.hourValue3 =  this.edu_form.controls.practicalhourFirstCtrl.value;
       this.hourValue4 =  this.edu_form.controls.practicalhourFirstCtrl.value;
       
     }else if(this.edu_form.controls.subjectFirstCtrl.value && this.edu_form.controls.subjectSecondCtrl.value && this.edu_form.controls.subjectThirdCtrl.value ){
       this.hourValue =  this.edu_form.controls.practicalhourFirstCtrl.value;
       this.hourValue2 =  this.edu_form.controls.practicalhourFirstCtrl.value;
       this.hourValue3 =  this.edu_form.controls.practicalhourFirstCtrl.value;
       
       
     }else if(this.edu_form.controls.subjectFirstCtrl.value && this.edu_form.controls.subjectSecondCtrl.value ){
       this.hourValue =  this.edu_form.controls.practicalhourFirstCtrl.value;
       this.hourValue2 =  this.edu_form.controls.practicalhourFirstCtrl.value;
       
       
       
     }
    
   
  }
}

copyLecValue(){
 if(this.edu_form.controls.lecturehourFirstCtrl.value){
   if(this.edu_form.controls.subjectFirstCtrl.value && this.edu_form.controls.subjectSecondCtrl.value && this.edu_form.controls.subjectThirdCtrl.value && this.edu_form.controls.subjectFourthCtrl.value && this.edu_form.controls.subjectFifthCtrl.value && this.edu_form.controls.subjectSixthCtrl.value && this.edu_form.controls.subjectSeventhCtrl.value && this.edu_form.controls.subjectEighthCtrl.value      ){
     this.lecVal1 =  this.edu_form.controls.lecturehourFirstCtrl.value;
     this.lecVal2=  this.edu_form.controls.lecturehourFirstCtrl.value;
     this.lecVal3=  this.edu_form.controls.lecturehourFirstCtrl.value;
     this.lecVal4=  this.edu_form.controls.lecturehourFirstCtrl.value;
     this.lecVal5=  this.edu_form.controls.lecturehourFirstCtrl.value;
     this.lecVal6=  this.edu_form.controls.lecturehourFirstCtrl.value;
     this.lecVal7=  this.edu_form.controls.lecturehourFirstCtrl.value;
     this.lecVal8=  this.edu_form.controls.lecturehourFirstCtrl.value;
   }else if(this.edu_form.controls.subjectFirstCtrl.value && this.edu_form.controls.subjectSecondCtrl.value && this.edu_form.controls.subjectThirdCtrl.value && this.edu_form.controls.subjectFourthCtrl.value && this.edu_form.controls.subjectFifthCtrl.value && this.edu_form.controls.subjectSixthCtrl.value && this.edu_form.controls.subjectSeventhCtrl.value     ){
     this.lecVal1 =  this.edu_form.controls.lecturehourFirstCtrl.value;
     this.lecVal2=  this.edu_form.controls.lecturehourFirstCtrl.value;
     this.lecVal3=  this.edu_form.controls.lecturehourFirstCtrl.value;
     this.lecVal4=  this.edu_form.controls.lecturehourFirstCtrl.value;
     this.lecVal5=  this.edu_form.controls.lecturehourFirstCtrl.value;
     this.lecVal6=  this.edu_form.controls.lecturehourFirstCtrl.value;
     this.lecVal7=  this.edu_form.controls.lecturehourFirstCtrl.value;
     
   }else if(this.edu_form.controls.subjectFirstCtrl.value && this.edu_form.controls.subjectSecondCtrl.value && this.edu_form.controls.subjectThirdCtrl.value && this.edu_form.controls.subjectFourthCtrl.value && this.edu_form.controls.subjectFifthCtrl.value && this.edu_form.controls.subjectSixthCtrl.value    ){
     this.lecVal1 =  this.edu_form.controls.lecturehourFirstCtrl.value;
     this.lecVal2=  this.edu_form.controls.lecturehourFirstCtrl.value;
     this.lecVal3=  this.edu_form.controls.lecturehourFirstCtrl.value;
     this.lecVal4=  this.edu_form.controls.lecturehourFirstCtrl.value;
     this.lecVal5=  this.edu_form.controls.lecturehourFirstCtrl.value;
     this.lecVal6=  this.edu_form.controls.lecturehourFirstCtrl.value;
     
     
   }else if(this.edu_form.controls.subjectFirstCtrl.value && this.edu_form.controls.subjectSecondCtrl.value && this.edu_form.controls.subjectThirdCtrl.value && this.edu_form.controls.subjectFourthCtrl.value && this.edu_form.controls.subjectFifthCtrl.value   ){
     this.lecVal1 =  this.edu_form.controls.lecturehourFirstCtrl.value;
     this.lecVal2=  this.edu_form.controls.lecturehourFirstCtrl.value;
     this.lecVal3=  this.edu_form.controls.lecturehourFirstCtrl.value;
     this.lecVal4=  this.edu_form.controls.lecturehourFirstCtrl.value;
     this.lecVal5=  this.edu_form.controls.lecturehourFirstCtrl.value;
     
   }else if(this.edu_form.controls.subjectFirstCtrl.value && this.edu_form.controls.subjectSecondCtrl.value && this.edu_form.controls.subjectThirdCtrl.value && this.edu_form.controls.subjectFourthCtrl.value   ){
     this.lecVal1 =  this.edu_form.controls.lecturehourFirstCtrl.value;
     this.lecVal2=  this.edu_form.controls.lecturehourFirstCtrl.value;
     this.lecVal3=  this.edu_form.controls.lecturehourFirstCtrl.value;
     this.lecVal4=  this.edu_form.controls.lecturehourFirstCtrl.value;
     
     
   }else if(this.edu_form.controls.subjectFirstCtrl.value && this.edu_form.controls.subjectSecondCtrl.value && this.edu_form.controls.subjectThirdCtrl.value  ){
     this.lecVal1 =  this.edu_form.controls.lecturehourFirstCtrl.value;
     this.lecVal2=  this.edu_form.controls.lecturehourFirstCtrl.value;
     this.lecVal3=  this.edu_form.controls.lecturehourFirstCtrl.value;
   
   }else if(this.edu_form.controls.subjectFirstCtrl.value && this.edu_form.controls.subjectSecondCtrl.value  ){
     this.lecVal1 =  this.edu_form.controls.lecturehourFirstCtrl.value;
     this.lecVal2=  this.edu_form.controls.lecturehourFirstCtrl.value;
     
   
   }
  }
}

showcoursecodeDropdown(){
  this.showcodeDropdown = true
}

showcoursecodeDropdown2(){
  this.showcodeDropdown2 = true
}

showcoursecodeDropdown3(){
  this.showcodeDropdown3 = true
}

showcoursecodeDropdown4(){
  this.showcodeDropdown4 = true
}

showcoursecodeDropdown5(){
  this.showcodeDropdown5 = true
}

showcoursecodeDropdown6(){
  this.showcodeDropdown6 = true
}

showcoursecodeDropdown7(){
  this.showcodeDropdown7 = true
}

showcoursecodeDropdown8(){
  this.showcodeDropdown8 = true
}
showcoursecodeDropdown9(){
  this.showcodeDropdown9 = true
}
showcoursecodeDropdown10(){
  this.showcodeDropdown10 = true
}

showContactDetails(){
  this.confirmationService.confirm({
    message: 'Email - stxaviers@etranscript.in &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp;'+' Whatsapp - +91 99306 00066'  ,
    header: 'Please contact the admin on ',
    icon: 'pi pi-exclamation-triangle',
    accept: () => {
              
    },
    reject: () => {
      
    }
  });
}


}