export var config = {
    baseUrl : "https://devxaviers.etranscript.in/",
    //serverUrl : "http://xaviers.etranscript.in:3537",
    serverUrl : "https://devxaviers.etranscript.in",
    socketioUrl : "https://localhost:6",
    ENV_sendgrid_Twilio : "production",
    transUploadUrl:"https://devxaviers.etranscript.in/api/attestation/upload_document",
    markListUploadUrl_ : "https://devxaviers.etranscript.in/api/attestation/uploadMarkList",
    //docUploadUrl :  "http://devxaviers.etranscript.in/api/etranscript/upload_doc",
    docUploadUrl :  "https://devxaviers.etranscript.in/api/etranscript/upload_doc",

    markListUploadUrl : "https://devxaviers.etranscript.in/api/attestation/uploadUserMarkList",
    curriculumUploadUrl: "https://devxaviers.etranscript.in/api/attestation/upload_curriculum",
     siteKey :"6LeCCj4cAAAAAKwtR9iCbj__5DA1H7ALOLPjZk2T",
  }