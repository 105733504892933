
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';
import { FeedbackNewComponent } from './feedback.component';
import { NbDialogService } from '@nebular/theme';

@Component({
  selector: 'firstcancel',
  //styleUrls: ['./application.component.scss'],
  template: `
  <div>
    <h1>PAYMENT CANCELLED</h1>
  </div>
  <div>
    <h4 >Please Try again</h4><br>
      <a href="" ng-click="redirect()">Click here</a>
        To Go Back
  </div>
  `,
})
export class FirstCancelComponent  {
  user_id;
  role;
  constructor(private router: Router,
    private authService: NbAuthService,
    private dialogService: NbDialogService) {
    // this.authService.onTokenChange()
    // .subscribe((token: NbAuthJWTToken) => {
    //     this.user_id = token.getPayload()['id'];
    //     this.role = token.getPayload()['role'];
    // });
  }
  async ngOnInit() {

    this.feedbackfun();
  }

  feedbackfun(){
    // if(value == false){
       this.dialogService.open(FeedbackNewComponent, {
         closeOnBackdropClick : false,
         context: {
           user_id : this.user_id
         },
       }).onClose
       .subscribe(
         (data: any) => {
           
         }
       )
  }
  
  redirect(){
    this.router.navigateByUrl('/pages/dashboard');
  }

  

  
  

}

