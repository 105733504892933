import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import {Observable, Subject, Subscriber} from 'rxjs/Rx';
import { NbAuthService } from '@nebular/auth';
import { NbThemeService } from '@nebular/theme';
import { config } from '../../../config';
import { applicationApi } from '../model/applicationApi';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  
  private baseUrl = config.serverUrl;
  constructor(private httpClient : HttpClient,
    public authService : NbAuthService,
    public themeService : NbThemeService) { }
    

    get(url: string): Observable<any> {
      return new Observable((observer: Subscriber<any>) => {
          let objectUrl: string = null;
          this.httpClient
              .get(url, {
                  responseType: 'blob'
              })
              .subscribe(m => {
                  objectUrl = URL.createObjectURL(m);
                  observer.next(objectUrl);
              });
  
          return () => {
              if (objectUrl) {
                  URL.revokeObjectURL(objectUrl);
                  objectUrl = null;
              }
          };
      });
  }

    login(email,password){
      try{
        return this.httpClient.post(`${this.baseUrl}/api/auth/login`,{"email":email,"password":password});
      }catch(error) {
        this.handleError("login from api : "+error);
      }
    }


    RegisterValues(data)
    {
      try{
          return this.httpClient.post(`${this.baseUrl}/api/auth/register`,{data : data});
      }catch(error){
        this.handleError('register errors')
      }
    }

    emailValues(data){
      try{
        
            return  this.httpClient.post(`${this.baseUrl}/api/auth/checkEmail`,{
              data : data
          
            });
       
      }catch(error){
        this.handleError("emailValues: "+JSON.stringify(error));
      }
    }


    ForgotPassword(email)
    {
      try{
          
          // return this.httpClient.post(`${this.baseUrl}/api/auth/register`,{data : data});
          return  this.httpClient.post(`${this.baseUrl}/api/auth/forgot-password`,{
                     data : email});
      }catch(error){
        this.handleError('register errors')
      }
    }

    resetPasswordValues(data){  
      try{  
        
            return  this.httpClient.post(`${this.baseUrl}/api/auth/resetpassword`,{
              data : data
            }); 
      }catch(error){
        this.handleError("resetPasswordValues: "+JSON.stringify(error));
      }
    }

    Otpvalue(data){
    try{
      return this.httpClient.post(`${this.baseUrl}/api/auth/verify-otp-reg`,{
         data : data
      });
  }catch(error) {
    this.handleError("Otpvalue : "+JSON.stringify(error));
  }
  }

  UpdateNumberOTP(data){
    try{
      return this.httpClient.post(`${this.baseUrl}/api/login/resend-otp`,{
         data : data
      });
  }catch(error) {
    this.handleError("UpdateNumberOTP : "+JSON.stringify(error));
  }
  }

  confirmPass(email,pass){
          try{
            return this.httpClient.post(`${this.baseUrl}/mobile/user/confirm_api_password`,{"email":email,"pass":pass});
          }catch(error) {
            this.handleError("confirmPass : "+error);
          }
        }

          getCaptcha(){
    try{
        return  this.httpClient.get(`${this.baseUrl}/api/auth/captcha`);
            
    }catch(error) {
      this.handleError("getCaptcha: "+JSON.stringify(error));
    }
    
  }

//created by namrata
addNewCollegebyStudent(college){
  try{
    return  this.httpClient.post(`${this.baseUrl}/api/addNewCollegebyStudent`,college);     
  }catch(error){
    this.handleError('createInstitute: ' + JSON.stringify(error));
  }  
}

createInstitute(universityName,email,country,contactNumber,contact_person,type,address, landmark,pincode,wesNumber,iqasno,cesno,eduperno,icasno,studyrefno,emprefno,visarefno,studyaccno,empaccno,visaaccno,otheraccno,myieeno,icesno,nasbano){
  try{
    return  this.httpClient.post(`${this.baseUrl}/api/attestation/createInstitute`,{"universityName":universityName,"email":email,"country":country,"contactNumber":contactNumber,"contact_person":contact_person,"type":type,"address":address, "landmark":landmark,"pincode":pincode,
    "wesNumber":wesNumber,"iqasno":iqasno,"cesno":cesno,"eduperno":eduperno,"icasno":icasno,"studyrefno":studyrefno,"emprefno":emprefno,"visarefno":visarefno
    ,"studyaccno":studyaccno,"empaccno":empaccno,"visaaccno":visaaccno,"otheraccno":otheraccno,"myieeno":myieeno,"icesno":icesno,"nasbano":nasbano});     
  }catch(error){
    this.handleError('createInstitute: ' + JSON.stringify(error));
  }
}

suggestInstitute(){
  try{
    return  this.httpClient.get(`${this.baseUrl}/api/attestation/suggestInstitute`);
  }catch (error){
    this.handleError('suggestInstitute() : ' + JSON.stringify(error));
  }
}

suggestInstituteallDATA(universityName){
  try{
    return  this.httpClient.get(`${this.baseUrl}/api/attestation/suggestInstituteallDATA?universityName=`+universityName);
  }catch (error){
    this.handleError('suggestInstituteallDATA() : ' + JSON.stringify(error));
  }
}



updateInstituteData(id,universityName,email,country,contactNumber,contact_person,address,landmark,pincode,wesNumber,iqasno,cesno,eduperno,icasno,studyrefno,emprefno,visarefno,studyaccno,empaccno,visaaccno,otheraccno,myieeno,icesno,nasbano){
  try{
    return  this.httpClient.post(`${this.baseUrl}/api/attestation/updateInstituteData`,{"id":id,"universityName":universityName,"email":email,
    "country":country,"contactNumber":contactNumber,"contact_person":contact_person,"address":address, "landmark":landmark,
    "pincode":pincode,"wesNumber":wesNumber,"iaqsno":iqasno,"cesno":cesno,"eduperno":eduperno,"icasno" :icasno,"studyrefno":studyrefno,
    "emprefno":emprefno,"visarefno":visarefno,
    "studyaccno":studyaccno,"empaccno":empaccno,"visaaccno":visaaccno,"otheraccno":otheraccno,"mynieeno":myieeno,"icesno":icesno,"nasbano":nasbano});     
  }catch(error){
    this.handleError('updateInstituteData: ' + JSON.stringify(error));
  }
}

deleteInstituteData(id){
  try{
    return  this.httpClient.post(`${this.baseUrl}/api/attestation/deleteInstituteData`,{"id":id});     
  }catch(error){
    this.handleError('deleteInstituteData: ' + JSON.stringify(error));
  }
}

getInstituteData(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/getInstituteData`); 
  }catch(error){
    this.handleError('getInstituteData: ' + JSON.stringify(error));
  }
}

addToCart(){
  try{
    return  this.httpClient.get(`${this.baseUrl}/api/attestation/addToCart`);
  }catch(error){
    this.handleError('addToCart: ' + JSON.stringify(error));
  }
}

getCartData(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/getCartData`); 
  }catch(error){
    this.handleError('getCartData: ' + JSON.stringify(error));
  }
}

getUserData(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/getUserData`); 
  }catch(error){
    this.handleError('getUserData: ' + JSON.stringify(error));
  }
}

PaymentDetails(order_id){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/payment/PaymentDetails`,{"order_id":order_id});
  }catch(error) {
    this.handleError("PaymentDetails : "+error);
  }
}

OnlinePaymentChallan(transaction_id,payment_amount,payment_status,application_id,payment_date_time,user_id){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/payment/OnlinePaymentChallan`,{user_id:user_id,payment_amount:payment_amount,transaction_id:transaction_id,date_time:payment_date_time,status_payment:payment_status,application_id:application_id});
  }catch(error) {
    this.handleError("OnlinePaymentChallan : "+error);
  }
} 

saveFeedBack(feedBack,user_id){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/attestation/kcFeedBack`,{user_id:user_id,feedBack:feedBack});
  }catch(error) {
    this.handleError("OnlinePaymentChallan : "+error);
  }
}

getFeedback(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/feedback`);
  }catch(error) {
      this.handleError("getFeedback : "+JSON.stringify(error));
    }
}

// downloadFiles(file_name):Observable<Blob>{
//   try{
//   let headers = new HttpHeaders();
//   headers.append('Content-Type', 'application/json');
//   return this.httpClient.get(`${this.baseUrl}/api/payment/download?file_name=`+file_name, { headers: headers, responseType: 'blob'}).map(
//       (res) => {
//           return new Blob([res], { type: 'application/pdf' });
//       });
//   }catch(error) {
//     this.handleError("unable to get Files : "+JSON.stringify(error));
//   }
// }

downloadMarksheetFiles(file_name):Observable<Blob>{
  try{
  let headers = new HttpHeaders();
  headers.append('Content-Type', 'application/json');
  return this.httpClient.get(`${this.baseUrl}/api/attestation/downloadMarkshhet?file_name=`+file_name, { headers: headers, responseType: 'blob'}).map(
      (res) => {
          return new Blob([res], { type: 'application/pdf' });
      });
  }catch(error) {
    this.handleError("unable to get Files : "+JSON.stringify(error));
  }
}

My_pplication(){
  try{
    return  this.httpClient.get(`${this.baseUrl}/api/attestation/my_applications`);
  }catch (error){
    this.handleError('get my applications Details : ' + JSON.stringify(error));
  }
}


Details(){
  try{
    return  this.httpClient.get(`${this.baseUrl}/api/cart`);
  }catch (error){
    this.handleError('get cart Details : ' + JSON.stringify(error));
  }
}

async cartRemove(id,institute_id){
  try{
      return await this.httpClient.post(`${this.baseUrl}/api/removeCartvalue`,{'id' : id,'institute_id':institute_id});
    }catch(error){
      this.handleError("cartRemove : "+JSON.stringify(error));
  }
}

emailupdate(email,updated_email){
  try{
    return  this.httpClient.post(`${this.baseUrl}/api/emailUpdate`,{"email": email,"updated_email" : updated_email});
  }catch (error){
    this.handleError('emailupdate : ' + JSON.stringify(error));
  }
}




setEducationDetails(data){  
  try{  
    return  this.httpClient.post(`${this.baseUrl}/api/attestation/setEducationDetails`,{data : data}); 
  }catch(error){
    this.handleError("setEducationDetails: "+JSON.stringify(error));
  }
}

getEducationDetails(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/getEducationDetails`);
  }catch(error) {
      this.handleError("getEducationDetails : "+JSON.stringify(error));
  }
}

getDocumentDetails(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/getDocumentDetails`);
  }catch(error) {
      this.handleError("getDocumentDetails : "+JSON.stringify(error));
  }
}

getDocumentStatus(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/getDocumentStatus`);
  }catch(error) {
      this.handleError("getDocumentStatus : "+JSON.stringify(error));
  }
}

getInstituteStatus(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/getInstituteStatus`);
  }catch(error) {
      this.handleError("getDocumentStatus : "+JSON.stringify(error));
  }
}

getApplyingEducation(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/getApplyingEducation`);
  }catch(error) {
      this.handleError("getApplyingEducation : "+JSON.stringify(error));
  }
}

checkCart(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/checkCart`);
  }catch(error) {
      this.handleError("checkCart : "+JSON.stringify(error));
  }
}

getProfileValues(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/dashboard/profileValue`,);
}catch(error) {
    this.handleError("getProfileValues : "+JSON.stringify(error));
}

}

getApplicanValue(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/dashboard/getApplicanValue`,);
}catch(error) {
    this.handleError("getApplicanValue : "+JSON.stringify(error));
}

}

getSendUniversityMail(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/admin/getapptrackerdetails`,{});
  }catch(error) {
    this.handleError("login from api : "+error);
  }
}

getAllSendMail(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/admin/getapplicationMailsDetails`,{});
  }catch(error) {
    this.handleError("login from api : "+error);
  }
}

updateProfileValues(data){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/dashboard/updateProfile`,{
      data : data
    });
}catch(error) {
    this.handleError("updateProfileValues : "+JSON.stringify(error));
}

}

// admin-dashboard 

sendOtp(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/get_otp`);
  }catch(error) {
      this.handleError("sendOtp : "+JSON.stringify(error));
  }
}
updateOtp(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/update_otp`);
  }catch(error) {
      this.handleError("updateOtp : "+JSON.stringify(error));
  }
}

getTranscriptDetails(user_id){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/getTranscriptDetails?user_id=${user_id}`);
  }catch(error) {
      this.handleError("getTranscriptDetails : "+JSON.stringify(error));
  }
}

downloadFilesAdmin(file_name,user_id):Observable<Blob>{
  try{
  let headers = new HttpHeaders();
  headers.append('Content-Type', 'application/json');
  return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/download?file_name=${file_name}&user_id=${user_id}`, { headers: headers, responseType: 'blob'}).map(
      (res) => {
          return new Blob([res], { type: 'application/pdf' });
      });
  }catch(error) {
    this.handleError("unable to get Files : "+JSON.stringify(error));
  }
}

getTotalApplications(page, searchItem){
  try{
  const requestUrl = `${this.baseUrl}/api/admin/adminDashboard/total?page=${page}&searchItem=${searchItem}`;
    return this.httpClient.get(requestUrl);
  }catch(error){
    this.handleError("getTotalApplications : "+JSON.stringify(error));
  }
  }

  getTotalDashboardCount(){
    try{
    const requestUrl = `${this.baseUrl}/api/admin/adminDashboard/totalCount`;
      return this.httpClient.get(requestUrl);
    }catch(error){
      this.handleError("getTotalApplications : "+JSON.stringify(error));
    }
    }

  getPendingApplications(){
      try{
    const requestUrl = `${this.baseUrl}/api/admin/adminDashboard/unsigned`;
      return this.httpClient.get(requestUrl);
    }catch(error){
      this.handleError("getPendingApplications : "+JSON.stringify(error));
    }
    }
    
    getVerifiedApplications(){
      try{
      const requestUrl = `${this.baseUrl}/api/admin/adminDashboard/verified`;
      return this.httpClient.get(requestUrl);
    }catch(error){
      this.handleError("getVerifiedApplications : "+JSON.stringify(error));
    }
    }

    getSignedApplicationsCount(){
      try{
      const requestUrl = `${this.baseUrl}/api/admin/adminDashboard/signedCount`;
      return this.httpClient.get(requestUrl);
    }catch(error){
      this.handleError("getVerifiedApplications : "+JSON.stringify(error));
    }
    }

    getSignedApplications(page, searchItem){
      try{
      const requestUrl = `${this.baseUrl}/api/admin/adminDashboard/signed?page=${page}&searchItem=${searchItem}`;
      return this.httpClient.get(requestUrl);
    }catch(error){
      this.handleError("getVerifiedApplications : "+JSON.stringify(error));
    }
    }

    getEmailedApplicationsCount() {
      try{
      const requestUrl = `${this.baseUrl}/api/admin/adminDashboard/emailedCount`;
      return this.httpClient.get(requestUrl);
    }catch(error){
      this.handleError("getVerifiedApplications : "+JSON.stringify(error));
    }
    }
    
    getEmailedApplications(page, searchItem) {
      try{
      const requestUrl = `${this.baseUrl}/api/admin/adminDashboard/emailed?page=${page}&searchItem=${searchItem}`;
      return this.httpClient.get(requestUrl);
    }catch(error){
      this.handleError("getVerifiedApplications : "+JSON.stringify(error));
    }
    }

    updateVerifiedBy(email, id){
      try{
        return this.httpClient.post(`${this.baseUrl}/api/admin/adminDashboard/pending/verifiedBy`,{"email":email,"id":id});
      }catch(error) {
        this.handleError("login from api : "+error);
      }
    }

    // sendEmail(id){
    //   try{
    //     return this.httpClient.post(`${this.baseUrl}/api/admin/adminDashboard/sendEmail`,{"id":id});
    //   }catch(error) {
    //     this.handleError("login from api : "+error);
    //   }
    // }
    
    getSigned_namrata_Applications(sort: string, order: string, page: number,per_page : number): Observable<applicationApi> {
      try{
      const requestUrl = `${this.baseUrl}/api/admin/adminDashboard/signed_namrata?sort=${sort}&order=${order}&page=${page + 1}&per_page=${per_page}`;
      return this.httpClient.get<applicationApi>(requestUrl);
    }catch(error){
      this.handleError("getVerifiedApplications : "+JSON.stringify(error));
    }
    }

    getEmailActivityTracker(){
      try{
        
        return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/getEmailActivityTracker`);
      }catch(error) {
        this.handleError("getEmailActivityTracker : "+JSON.stringify(error));
      }
    }

    getEmailActivityTrackerMonthWise(params){
      try{
        return this.httpClient.post(`${this.baseUrl}/api/admin/adminDashboard/getEmailActivityTrackerMonthWise`,{
          yearMonth : params,          
        });
      } catch(error) {
          this.handleError("getEmailActivityTrackerMonthWise : "+JSON.stringify(error));
      }
    }

    async getAllTranscriptData(userId,app_id){
      try{
        return await this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/transcript_lock?userId=`+userId+'&app_id='+app_id).toPromise();
      }catch(error) {
        this.handleError("Error in getAllTranscriptData : "+error);
      }
    }

    updateErrataTranscript(errataTranscript){
      try{
        return this.httpClient.post(`${this.baseUrl}/api/admin/adminDashboard/updateErrataTranscript`,{"errataTranscript":errataTranscript});
      }catch(error) {
        this.handleError("login from api : "+error);
      }
    }
    
downloadTranscript(file):Observable<Blob>{

  
  try {
    let headers = new HttpHeaders();
    headers.append('Content-Type','application/json');
      return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/downloadTranscript?file=${file}`, { headers: headers, responseType: 'blob'}).map(
        (res) => {
          return new Blob([res], { type: 'application/pdf' });
        });

  }
   catch (error) {
    this.handleError("unable to get the Files :"+JSON.stringify(error))
  }
  }

    
        //////////CREATED BY PRIYANKA
        getStatuswithid(Appc_id){
          try{ 
                return  this.httpClient.post(`${this.baseUrl}/api/dashboard/appTrackerwithid`,{"app_id": Appc_id});     
           }catch (error){ 
            this.handleError('getStatus: ' + JSON.stringify(error));
          }
          
         }

         getPurposewithid(User_id){
         
          try{ 
                return  this.httpClient.post(`${this.baseUrl}/api/dashboard/purposeTrackerwithid`,{"user_id": User_id});     
           }catch (error){ 
            this.handleError('getStatus: ' + JSON.stringify(error));
          }
          
         }
         
         getStatus(){
          try{
                return  this.httpClient.get(`${this.baseUrl}/api/dashboard/appTracker`);     
           }catch (error){ 
            this.handleError('getStatus: ' + JSON.stringify(error));
          }
          
         }
    
        getApplicationdetails(): any {
          try{
            return this.httpClient.get(`${this.baseUrl}/api/dashboard/getApp_details`);
          }catch(error){
            this.handleError("get application details: "+error);
          }
        }
    
        getpreAppldetails(): any {
          try{
            return this.httpClient.get(`${this.baseUrl}/api/dashboard/getpreAppldetails`);
          }catch(error){
            this.handleError("get application details: "+error);
          }
        }

        getErrataUpdate():any{
          try{
            return this.httpClient.get(`${this.baseUrl}/api/dashboard/getErrataUpdate`);
          }catch(error){
            this.handleError("get application details: "+error);
          }
        }


        
language(language:any,userId:any){
  try{
    return  this.httpClient.post(`${this.baseUrl}/api/attestation/language`,{"language":language,"userId":userId});     
  }catch(error){
    this.handleError('createInstitute: ' + JSON.stringify(error));
  } 
}


// signpdf(appl_id: any,type : any) {
//   try{

//     return this.httpClient.post(`${this.baseUrl}/api/signpdf/signpdf`,{"appl_id":appl_id,"type":type})
//   }catch(error) {
//     this.handleError("getEmailActivityTracker : "+JSON.stringify(error));
//   }
// }

signpdf(appl_id: any,type : any, data_Values :any) {
  try{
    
    return this.httpClient.post(`${this.baseUrl}/api/signpdf/signpdf`,{"appl_id":appl_id,"type":type,"data_Values":data_Values})
  }catch(error) {
    this.handleError("getEmailActivityTracker : "+JSON.stringify(error));
  }
}


        

         /**
   * Adds notifications to the Database
   * @author Rafique Shaikh
   */

  socketmessage = new Subject();
  socketNotificationNo = new Subject();
  private notification_no : any;
private messages = [];

  sendNotifications(appId,type){
    try {
      return this.httpClient.post(`${this.baseUrl}/api/admin/adminDashboard/sendNotification`, {"id":appId,"type":type});
    } catch (error) {
      this.handleError("sendNotifications: "+JSON.stringify(error));
    }
  }

  receiveNotifications(userId){
   return this.httpClient.post(`${this.baseUrl}/api/admin/adminDashboard/receiveNotification`, {"id":userId}).subscribe(notifications=>{
      this.messages = notifications['data'];
      this.notification_no = notifications['notification_no'];
      if(notifications['data'].length == 0){
        this.socketNotificationNo.next('');
        this.socketmessage.next('');
      }else if(notifications['data'].length > 0){
        this.socketNotificationNo.next(this.notification_no);
        this.socketmessage.next(this.messages);
      }
    },error => {
      this.handleError("receiveNotifications: "+JSON.stringify(error));
      });
  }

  reloadnotification(userId){
   try{
      return this.httpClient.post(`${this.baseUrl}/api/admin/adminDashboard/receiveNotification`,{"id":userId});
    }catch(error) {
      this.handleError("notification : "+error);
    }
  }

  makeReadNotification(userId){
    try{
      return this.httpClient.post(`${this.baseUrl}/api/admin/adminDashboard/makeReadNotification`,{"id":userId});
    }catch(error) {
      this.handleError("makeReadNotification : "+error);
    }
  }
  
  checkWesStatus(user_id){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/wes/checkStats?userId=`+user_id);
     }catch(error) {
       this.handleError("checkStats: "+JSON.stringify(error));
     } 
  }
  

  deleteNotification(userId,noti_id){
    try{
      return this.httpClient.post(`${this.baseUrl}/api/admin/adminDashboard/deleteNotification`,{"id":userId,"noti_id":noti_id});
    }catch(error) {
      this.handleError("deleteNotification : "+error);
    }
  }

  getTheme(){
    try{
      return  this.httpClient.get(`${this.baseUrl}/api/dashboard/getTheme`);
    }catch(error) {
      this.handleError("getTheme : "+error);
    }
  }

 /**
   * Adds notifications to the Database for errata
   * @author Priyanka khandagale
   */
  sendmessage(msg_from_admin_data){
    try{
      return this.httpClient.post(`${this.baseUrl}/api/admin/adminDashboard/trans_sendmessage`,{"msg":msg_from_admin_data});
    }catch(error) {
      this.handleError("ERROR sendmessage : "+error);
    }
  }

  checkapplications(){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/attestation/checkapplications`);
    }catch(error) {
      this.handleError("checkapplications : "+error);
    }
  }

  getAllPayments(){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/payment/getAllPayments`);     
    }catch(error){
      this.handleError('getAllPayments: ' + JSON.stringify(error));
    }
  }

  getApplWisePayments(appl_id){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/payment/getApplWisePayments?appl_id=${appl_id}`);     
    }catch(error){
      this.handleError('getAllPayments: ' + JSON.stringify(error));
    }
  }

  downloadPaymentExcel(pdf):Observable<Blob>{
    try{
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      return this.httpClient.get(`${this.baseUrl}/api/payment/downloadExcel?pdf=`+pdf, { headers: headers, responseType: 'blob'}).map(
        (res) => {
            return new Blob([res], { type: 'application/pdf' });
        });
    }catch(error) {
      this.handleError("downloadPaymentExcel unable to get Files : "+JSON.stringify(error));
    }
  }


  splitExcel(){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/payment/splitExcel`);
    }catch(error) {
      this.handleError("splitExcel : "+JSON.stringify(error));
    }
  }

  downloadReceipt(id):Observable<Blob>{
    try{
      let headers = new HttpHeaders();
      headers.append('Content-Type','application/json');
        return this.httpClient.get(`${this.baseUrl}/api/attestation/downloadPaymentReceipt?appl_id=`+id, { headers: headers, responseType: 'blob'}).map(
          (res) => {
            return new Blob([res], { type: 'application/pdf' });
          });
     }catch(error){
      this.handleError("Error : "+JSON.stringify(error));
    }
  }

  getSignedPdfDetails(appl_id,user_id) {
    try{
      return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/getSignedPdfDetails?user_id=${user_id}&appl_id=${appl_id}`);
    }catch(error) {
        this.handleError("getSignedPdfDetails : "+JSON.stringify(error));
    }
  }
  
  downloadFilesAdmin_signedpdf(file_name):Observable<Blob>{
    try{
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/downloadSignedpdf?file_name=${file_name}`, { headers: headers, responseType: 'blob'}).map(
        (res) => {
            return new Blob([res], { type: 'application/pdf' });
        });
    }catch(error) {
      this.handleError("unable to get Files : "+JSON.stringify(error));
    }
  }

  checkTransStatus(user_id) {
    try{
      return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/getAppTransStatus?user_id=${user_id}`)
    }catch(error){
      this.handleError("checkTransStatus: "+JSON.stringify(error));
    }
  }

  //NEW
getBoard(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/getBoard`);
  }catch(error) {
      this.handleError("getBoard : "+JSON.stringify(error));
  }
}

saveBoard(board_id,user_id){
  try{
    return  this.httpClient.post(`${this.baseUrl}/api/attestation/saveBoard`,{"board_id" : board_id, "user_id" : user_id});
  }catch(error) {
      this.handleError("saveBoard : "+JSON.stringify(error));
  }
}

getUserWiseBoard(){
  try{
    return  this.httpClient.get(`${this.baseUrl}/api/attestation/getUserWiseBoard`);
  }catch(error) {
      this.handleError("getUserWiseBoard : "+JSON.stringify(error));
  }
}

//Get IP Adress using http://freegeoip.net/json/?callback
getIpAddress() {
  return this.httpClient
    .get('http://ip-api.com/json')
    .map(response => response || {})
    .catch(this.handleError1);
}

getCollegeList(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/getCollegeList`); 
  }catch(error){
    this.handleError('getCollegeList: ' + JSON.stringify(error));
  }
}

getFacultyList(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/getFacultyList`); 
  }catch(error){
    this.handleError('getFacultyList: ' + JSON.stringify(error));
  }
}

getTranscript_name(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/dashboard/getTranscript_name`,);
  }catch(error) {
      this.handleError("getTranscript_name : "+JSON.stringify(error));
  }
}

getcheckstepper(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/dashboard/checkStepper`,);
  }catch(error) {
      this.handleError("getTranscript_name : "+JSON.stringify(error));
  }
}


getallstudents(page, searchItem){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/students?page=${page}&searchItem=${searchItem}`);
  }catch(error) {
      this.handleError("getallstudents : "+JSON.stringify(error));
    }
}

getCountryWiseApplication(year,country_name,applying_for){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/getCountryWiseApplication?year=`+year+'&country_name='+country_name+'&applying_for='+applying_for);
  }catch(error) {
      this.handleError("getallstudents : "+JSON.stringify(error));
    }
}

status(status,userId){
  try{
    return this.httpClient.put(`${this.baseUrl}/api/admin/adminDashboard/status`,{"status":status,"userId": userId});
  }catch(error) {
    this.handleError("status : "+JSON.stringify(error));
  }
}

getApplWisePaymentsAdmin(appl_id,user_id){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/getApplWisePayments?appl_id=`+appl_id+`&id=`+user_id);   
  }catch(error){
    this.handleError('getAllPayments: ' + JSON.stringify(error));
  }
}

downloadReceiptAdmin(id,user_id):Observable<Blob>{
  try{
    let headers = new HttpHeaders();
    headers.append('Content-Type','application/json');
      return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/downloadPaymentReceipt?appl_id=`+id+`&user_id=`+user_id, { headers: headers, responseType: 'blob'}).map(
        (res) => {
          return new Blob([res], { type: 'application/pdf' });
        });
   }catch(error){
    this.handleError("Error downloadpaymentReceipt: "+JSON.stringify(error));
  }
}

view_Application(id){
  try{
    return  this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/my_applications?id=`+id);
  }catch (error){
    this.handleError('get my applications Details : ' + JSON.stringify(error));
  }
}

getdatachoice(id){
  try{
    
    
  const requestUrl = `${this.baseUrl}/api/admin/adminDashboard/getchoice?id=`+id;
    return this.httpClient.get(requestUrl);
  }catch(error){
    this.handleError("getdatachoice : "+JSON.stringify(error));
  }
  }

getPaymentDetails(tab_type){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/payment/getPaymentDetails?tab_type=`+tab_type);
  }catch(error) {
    this.handleError("getPaymentDetails : "+JSON.stringify(error));
  }
}

proceedSplit(order_id,edu_share,uni_share,ccavenue_share,reference_no){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/payment/proceedSplit`,{"order_id":order_id,"edu_share":edu_share,"uni_share":uni_share,"ccavenue_share":ccavenue_share,"reference_no":reference_no});
  }catch(error) {
    this.handleError("proceed2ndSplit : "+JSON.stringify(error));
  }
}

proceedRefund(order_no,order_id,edu_refund,uni_refund,cc_refund,reference_no){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/payment/proceedRefund`,{"order_no":order_no,"order_id":order_id,"edu_refund":edu_refund,"uni_refund":uni_refund,"cc_refund":cc_refund,"reference_no":reference_no});
  }catch(error) {
    this.handleError("proceedRefund : "+JSON.stringify(error));
  }
}

feedBack(satisfy, recommend, staff, experience, exp_prob, suggestion){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/attestation/feedBack`,{"satisfy":satisfy, "recommend":recommend, "staff":staff, "experience":experience, "exp_prob":exp_prob, "suggestion":suggestion});
  }catch(error) {
    this.handleError("feedBack : "+error);
  }
}

activitytracker(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/activitytracker`);
  }catch(error) {
    this.handleError("activitytracker : "+JSON.stringify(error));
  }
}

downloadExcel(type){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/downloadExcel?type=`+type)
  }catch(error){
    this.handleError(" downloadExcel : "+JSON.stringify(error))
  }
}

downloadFilesExcel(file_path):Observable<Blob>{
  try{
  let headers = new HttpHeaders();
  headers.append('Content-Type', 'application/json');
  return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/downloadfile?file_path=`+file_path, { headers: headers, responseType: 'blob'}).map(
      (res) => {
          return new Blob([res], { type: 'application/pdf' });
      });
  }catch(error) {
    this.handleError("unable to get Files : "+JSON.stringify(error));
  }
}

getMenuRole(userID){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/admin/role_management/getMenuRole?userID=`+userID);
  }catch(error){
    this.handleError(" getMenuRole : "+JSON.stringify(error));
  }
}

getallstudentfeedback(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/admin/student_feeback`);
  }catch(error) {
    this.handleError("getallstudentfeedback : "+JSON.stringify(error));
  }
}

getallsubadmin(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/admin/role_management/main`);
  }catch(error) {
      this.handleError("getallsubadmin : "+JSON.stringify(error));
    }
}

addupdateRole(roles,user_id){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/admin/role_management/setUpdateRole`,{"roles":roles,"user_id":user_id});
  }catch(error) {
      this.handleError("addupdateRole : "+JSON.stringify(error));
    }
}

getRolesData(userId){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/admin/role_management/getRolesData?userId=`+userId);
  }catch(error) {
      this.handleError("getRolesData : "+JSON.stringify(error));
    }
}

getSubAdminData(userId){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/admin/role_management/getSubAdminData?userId=`+userId);
  }catch(error) {
    this.handleError("getSubAdminData : "+JSON.stringify(error));
  }
}

addUpdatesubAdmin(userId, subAdminData){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/admin/role_management/addUpdatesubAdmin`,{"userId" : userId, "subAdminData" : subAdminData});
  }catch(error) {
    this.handleError("addUpdatesubAdmin : "+JSON.stringify(error));
  }
}

changeSubAdminStatus(userId){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/admin/role_management/changeSubAdminStatus`,{"userId" : userId});
  }catch(error) {
    this.handleError("changeSubAdminStatus : "+JSON.stringify(error));
  }
}


getPassword(app_id, docID , location,email,fileName){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/admin/getclickDetails`,{"app_id" :app_id , "docID": docID , "location" : location, "email": email, "fileName" : fileName});
  }catch(error) {
    this.handleError("getRolesData : "+JSON.stringify(error));
  }
}

getapplWiseTracker(Appc_id,user_id,email){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/admin/getapplWiseTracker`,{"app_id" :Appc_id , "user_id" : user_id, "email": email});
  }catch(error) {
    this.handleError("getRolesData : "+JSON.stringify(error));
  }
}

downloadStructureFile(filePath,app_id):Observable<Blob>{
  try{
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
  return this.httpClient.get(`${this.baseUrl}/api/auth/downloadStructureFile?file_name=`+filePath+`&app_id=`+app_id, {headers: headers, responseType: 'blob'}).map(
      (res) => {
          return new Blob([res], { type: 'application/pdf' });
      });
  }catch(error) {
    this.handleError("unable to get Files : "+JSON.stringify(error));
  }
}


addUserDegree(degree){ ///api/attestation/createInstitute
  try{
    return this.httpClient.post(`${this.baseUrl}/api/attestation/addUserDegree`,{"degree" :degree});
  }catch(error) {
    this.handleError("addUserDegree: "+JSON.stringify(error));
  }
}

getUploadUserMarklist(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/getUploadUserMarklist`);
  }catch(error) {
    this.handleError("getUploadUserMarklist: "+JSON.stringify(error));
  }
}

viewFileYearWise(year,pattern,education){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/viewFileYearWise?year=`+year+`&pattern=`+pattern+'&education='+education);
  }catch(error) {
    this.handleError("viewFileYearWise: "+JSON.stringify(error));
  }
}

getMarklistLetter(id){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/getMarklistLetter?id=`+id)
  }catch(error) {
    this.handleError("getMarklistLetter  : "+JSON.stringify(error));
  }
}


getUserEducation(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/getUserEducation`);
  }catch(error) {
    this.handleError("getUserEducation: "+JSON.stringify(error));
  }
}

deleteTranscript(userData){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/dashboard/deleteTranscripts`,{'data':userData});
  }catch(error) {
    this.handleError("getUploadTranscript: "+JSON.stringify(error));
  }
}


deleteUploadFile(id){
  try{      
      return this.httpClient.delete(`${this.baseUrl}/api/attestation/deleteUploadFile?id=`+id);
          
  }catch(error) {
    this.handleError("deleteUploadFile: "+JSON.stringify(error));
  }
}

async getPurposeDetails(userId){
  try{
    return await this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/getPurposeDetails?userId=`+userId).toPromise();
  }catch(error) {
    this.handleError("Error in getPurposeDetails : "+error);
  }
}

getUploadedTranscript(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/dashboard/getUploadedTranscripts`,);
  }catch(error) {
      this.handleError("getUploadedTranscripts : "+JSON.stringify(error));
  }
}
getuserEducationDetails(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/getuserEducationDetails`);
  }catch(error) {
    this.handleError("getuserEducationDetails: "+JSON.stringify(error));
  }
}

getUserMasterDetails(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/getUserMasterDetails`);
  }catch(error) {
    this.handleError("getUserMasterDetails: "+JSON.stringify(error));
  }
}
getUserPhdDetails(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/getUserPhdDetails`);
  }catch(error) {
    this.handleError("getUserPhdDetails: "+JSON.stringify(error));
  }
}
getUploadedMarklist(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/getUploadedMarklist`,);
  }catch(error) {
      this.handleError("getUploadedMarklist : "+JSON.stringify(error));
  }
}

getUploadedMasterMarklist(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/getUploadedMasterMarklist`,);
  }catch(error) {
      this.handleError("getUploadedMasterMarklist : "+JSON.stringify(error));
  }
}
getUploadedPhdMarklist(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/getUploadedPhdMarklist`,);
  }catch(error) {
      this.handleError("getUploadedPhdMarklist : "+JSON.stringify(error));
  }
}
deleteMarklist(userData){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/attestation/deleteMarklist`,{'data':userData});
  }catch(error) {
    this.handleError("getUploadUserMarklist: "+JSON.stringify(error));
  }
}

deleteErrataMarklist(userData){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/attestation/deleteErrataMarklist`,{'data':userData});
  }catch(error) {
    this.handleError("deleteErrataMarklist: "+JSON.stringify(error));
  }
}

deleteErrataTranscript(userData){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/dashboard/deleteErrataTranscript`,{'data':userData});
  }catch(error) {
    this.handleError("deleteErrataTranscript: "+JSON.stringify(error));
  }
}


deleteErrataCurriculum(userData){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/attestation/deleteErrataCurriculum`,{'data':userData});
  }catch(error) {
    this.handleError("deleteErrataCurriculum: "+JSON.stringify(error));
  }
}



changeCollegeWithDeleteMarklist(year,pattern,education){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/changeCollegeWithDeleteMarklist?year=`+year+`&pattern=`+pattern +`&education=`+education);
  }catch(error) {
    this.handleError("getUserEducation: "+JSON.stringify(error));
  }
}

checksignedpdf(appl_id,user_id) {
  try{
    return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/checksignedpdf?user_id=${user_id}&appl_id=${appl_id}`);
  }catch(error) {
      this.handleError("getchecksignedpdf : "+JSON.stringify(error));
  }
}

mergedpdf(appl_id) {
  try{
   return this.httpClient.post(`${this.baseUrl}/api/signpdf/mergedpdf`,{"appl_id":appl_id})
  }catch(error) {
    this.handleError("getEmailActivityTracker : "+JSON.stringify(error));
  }
}



sendemail(appl_id) {
  try{
   return this.httpClient.post(`${this.baseUrl}/api/signpdf/email_signpdf`,{"appl_id":appl_id})
  }catch(error) {
    this.handleError("getEmailActivityTracker : "+JSON.stringify(error));
  }
}

uploadWes(user_id,dateOfWes){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/wes/uploadWes`,{"user_id":user_id,"date":dateOfWes})
   }catch(error) {
     this.handleError("uploadWes : "+JSON.stringify(error));
   } 
}


private handleError1(error: HttpErrorResponse):
  Observable<any> {
    //Log error in the browser console
    console.error('observable error: ', error);

    return Observable.throw(error);
  }

//NEW END

  private handleError(error){
    console.error(error);
  }

  updateLoginCount(user_id){
    try{
      return this.httpClient.put(`${this.baseUrl}/api/attestation/updateLoginCount`,{"user_id": user_id});
    }catch(error) {
      this.handleError("updateLoginCount : "+JSON.stringify(error));
    }
  }

  refreshCollegeEmailStatus(user_id,college_id){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/admin/collegeEmailStatusUpdate?user_id=${user_id}&college_id=${college_id}`);
    }catch(error) {
        this.handleError("refreshCollegeEmailStatus : "+JSON.stringify(error));
    }
  }

  sendEmailToCollege(collegeId,user_id){
    try{
      return this.httpClient.post(`${this.baseUrl}/api/admin/sendEmailToCollege`,{"collegeId":collegeId,"user_id":user_id});
    }catch(error) {
      this.handleError("deleteNotification : "+error);
    }
  }

  getInstructionalEducation(){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/attestation/getInstructionalDetails`);
    }catch(error) {
      this.handleError("getUserEducation: "+JSON.stringify(error));
    }
  }
  
  addUserInstructionalDetails(instructionalDetails:boolean){ ///api/attestation/createInstitute
    try{
      return this.httpClient.post(`${this.baseUrl}/api/attestation/instructionalDetails`,{"instructionalDetails" :instructionalDetails});
    }catch(error) {
      this.handleError("addUserInstutional: "+JSON.stringify(error));
    }
  }
  
  addUserCurriculumDetails(curriculumDetails:boolean){ ///api/attestation/createInstitute
    try{
      return this.httpClient.post(`${this.baseUrl}/api/attestation/curriculumDetails`,{"curriculumDetails" :curriculumDetails});
    }catch(error) {
      this.handleError("addUserInstutional: "+JSON.stringify(error));
    }
  }
  
  addUserEducationalDetails(educationalDetails:boolean){ ///api/attestation/createInstitute
    try{
      return this.httpClient.post(`${this.baseUrl}/api/attestation/educationalDetails`,{"educationalDetails" :educationalDetails});
    }catch(error) {
      this.handleError("addUserInstutional: "+JSON.stringify(error));
    }
  }
  
  
  
  addinstructionalDetails(object:Object){ 
    try{
      return this.httpClient.post(`${this.baseUrl}/api/attestation/instructionalUserDetails`,object);
    }catch(error) {
      this.handleError("addUserInstutional: "+JSON.stringify(error));
    }
  }
  
  
  
  downloadCurriculum(file_name):Observable<Blob>{
    try{
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.httpClient.get(`${this.baseUrl}/api/dashboard/downloadCurriculum?file_name=`+file_name, { headers: headers, responseType: 'blob'}).map(
        (res) => {
            return new Blob([res], { type: 'application/pdf' });
        });
    }catch(error) {
      this.handleError("unable to get Files : "+JSON.stringify(error));
    }
  }

  downloadMarklist(file_name):Observable<Blob>{
    try{
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.httpClient.get(`${this.baseUrl}/api/attestation/downloadMarklist?file_name=`+file_name, { headers: headers, responseType: 'blob'}).map(
        (res) => {
            return new Blob([res], { type: 'application/pdf' });
        });
    }catch(error) {
      this.handleError("unable to get Files : "+JSON.stringify(error));
    }
  }
  
  
  deleteUploadpdf(id){
    try{      
        return this.httpClient.delete(`${this.baseUrl}/api/attestation/deleteUploadpdf?id=`+id);
  
    }catch(error) {
      this.handleError("deleteUploadpdf: "+JSON.stringify(error));
    }
  }
  
  getAdminInstructionalEducation(userId){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/attestation/getAdminInstructionalDetails?userId=`+userId);
    }catch(error) {
      this.handleError("getUserEducation: "+JSON.stringify(error));
    }
  }

  // wesDate(date,user_id){
  //   try{
  //     return this.httpClient.post(`${this.baseUrl}/api/wes/wesDate`,{"date":date,"user_id":user_id})
  //    }catch(error) {
  //      this.handleError("wesDate : "+JSON.stringify(error));
  //    } 
  // }
  
  
  wesStudent(){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/wes/getAllWes`)
     }catch(error) {
       this.handleError("wesStudent: "+JSON.stringify(error));
     } 
  }
  


  
  getuploadedCurriculum(){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/dashboard/getuploadedCurriculum`,);
    }catch(error) {
        this.handleError("getuploadedCurriculum : "+JSON.stringify(error));
    }
  }


  viewPdf(appId,userId){
    try{
    const requestUrl = `${this.baseUrl}/api/wes/viewPdf?app_id=${appId}&userid=${userId}`;
    return this.httpClient.get(requestUrl);
  }catch(error){
    this.handleError("getWesApplications : "+JSON.stringify(error));
  }
  }

  generateInstrucionalLetter(app_id){ 
    try{
      return this.httpClient.post(`${this.baseUrl}/api/admin/generateInstrucionalLetter`,{"app_id" : app_id});
    }catch(error) {
      this.handleError("generateInstrucionalLetter: "+JSON.stringify(error));
    }
  }

  getMarkListDetails(degreeName){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/attestation/getMarkListDetails?degreeName=`+degreeName);
    }catch(error) {
      this.handleError("getUserEducation: "+JSON.stringify(error));
    }
  }
  saveMarkListDetails(data){
    try{
      return this.httpClient.post(`${this.baseUrl}/api/attestation/saveMarkListDetails`,{"data":data})
     }catch(error) {
       this.handleError("getUserEducation : "+JSON.stringify(error));
     }
  }

  deleteDetails(id){
    try{
      return this.httpClient.post(`${this.baseUrl}/api/attestation/deleteDetails`,{'id':id});
    }catch(error) {
      this.handleError("getUploadUserMarklist: "+JSON.stringify(error));
    }
  }
  

  getCollegeData(){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/admin/collegeManagement/getCollegeData`);
    }catch(error) {
        this.handleError("getCollegeData : "+JSON.stringify(error));
      }
  }

  rejectApplication(user_id,app_id){
    try{
      return this.httpClient.post(`${this.baseUrl}/api/admin/adminDashboard/rejectApplication`,{"user_id":user_id,"app_id":app_id});;
        //return this.httpClient.get(requestUrl);
      }catch(error){
        this.handleError("rejectApplication : "+JSON.stringify(error));
      }
  }

  getRejectedApplication(){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/getRejectedApplication`);
        //return this.httpClient.get(requestUrl);
      }catch(error){
        this.handleError("getRejectedApplication : "+JSON.stringify(error));
      }
  }

  

  addOrUpdateCollegeData(data){
    try{
      return this.httpClient.post(`${this.baseUrl}/api/admin/collegeManagement/addOrUpdateCollegeData`,{"collegeData":data})
     }catch(error) {
       this.handleError("addOrUpdateCollegeData : "+JSON.stringify(error));
     }
  }
  
  removeCollege(college_id){
    try{
      return this.httpClient.post(`${this.baseUrl}/api/admin/collegeManagement/removeCollege`,{"college_id":college_id})
     }catch(error) {
       this.handleError("addOrUpdateCollegeData : "+JSON.stringify(error));
     }
  }

  refreshPurposeEmailStatus(){
    try{
      return  this.httpClient.get(`${this.baseUrl}/api/cron/updateEmailStatus`);     
    }catch(error){
      this.handleError('refreshPurposeEmailStatus: ' + JSON.stringify(error));
    }  
  }
  
  getErrataDocs(){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/errataDocuments`);
    }catch(error) {
        this.handleError("sendOtp : "+JSON.stringify(error));
    }
  }

  lockDocument(doc_id,user_id,set,app_id){
    try{
      return this.httpClient.post(`${this.baseUrl}/api/admin/adminDashboard/lockDocuments`,{doc_name:name,doc_id:doc_id,user_id:user_id,set:set, app_id : app_id});
    }catch(error) {
        this.handleError("sendOtp : "+JSON.stringify(error));
    }
  } 

  downloadDocument(file_name):Observable<Blob>{
    try{
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.httpClient.get(`${this.baseUrl}/api/admin/downloadDocument?file_name=`+file_name, { headers: headers, responseType: 'blob'}).map(
        (res) => {
            return new Blob([res], { type: 'application/pdf' });
        });
    }catch(error) {
      this.handleError("unable to get Files : "+JSON.stringify(error));
    }
  }

  preview(app_id){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/attestation/preview?app_id=`+app_id);
    }catch(error) {
        this.handleError("sendOtp : "+JSON.stringify(error));
    }
  }

  sendToken(token){
    //`${this.baseUrl}/api/admin/collegeManagement/removeCollege`,{"college_id":college_id}
    return this.httpClient.post<any>(`${this.baseUrl}/api/etranscript`, {"recaptcha": token})
  }

  addFirstFormData(item: any): Observable<any> {
    try{
      return  this.httpClient.post(`${this.baseUrl}/api/etranscript/addFirstFormData`,item);
    }catch (error){
      this.handleError('addFirstFormData : ' + JSON.stringify(error));
    }
  }

  updateFirstformData(item:any,user_id):Observable<any>{
    try{
      return  this.httpClient.post(`${this.baseUrl}/api/etranscript/updateFirstformData`,{'item':item,'user_id':user_id});
    }catch (error){
      this.handleError('updateFirstformData : ' + JSON.stringify(error));
    }
  }

  getApplicantData(applicant_id) {
    try{
      return  this.httpClient.post(`${this.baseUrl}/api/etranscript/getApplicantData`,{'applicant_id':applicant_id});
    }catch (error){
      this.handleError('getApplicantData : ' + JSON.stringify(error));
    }
  }

  deleteApplicantFile(id){
    try{
      //return  this.httpClient.delete(`${this.baseUrl}/api/etranscript/deleteTranscripts?data=`+id);  
      return  this.httpClient.delete(`${this.baseUrl}/api/etranscript/deleteTranscripts?id=`+id['id']+'&userId='+id['userId']+'&file_name='+id['file_name']+'&file_path='+id['file_path']);
    }catch(error){
      this.handleError('deleteInstituteData: ' + JSON.stringify(error));
    }
  }

  paymentrequest(user_id){
    try{
      return  this.httpClient.post(`${this.baseUrl}/api/payment/paymentrequest`,{"applicant_id":user_id});     
    }catch(error){
      this.handleError('paymentrequest: ' + JSON.stringify(error));
    }
  }

  downloadFiles(file_name,user_id):Observable<Blob>{
    try{
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.httpClient.get(`${this.baseUrl}/api/payment/download?file_name=`+file_name+'&user_id='+user_id, { headers: headers, responseType: 'blob'}).map(
        (res) => {
            return new Blob([res], { type: 'application/pdf' });
        });
    }catch(error) {
      this.handleError("unable to get Files : "+JSON.stringify(error));
    }
  }

  getApplicantFiles(user_id){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/etranscript/getApplicantFiles?user_id=`+user_id);
    }catch(error) {
        this.handleError("sendOtp : "+JSON.stringify(error));
    }
  }

  downloadTemplate():Observable<Blob>{
    try{
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.httpClient.get(`${this.baseUrl}/api/etranscript/downloadTemplate`, { headers: headers, responseType: 'blob'}).map(
        (res) => {
            return new Blob([res], { type: 'application/pdf' });
        });
    }catch(error) {
      this.handleError("unable to get Files : "+JSON.stringify(error));
    }
  }

  setEducationalValues(name){
    try{
        return  this.httpClient.post(`${this.baseUrl}/api/attestation/set_Educational_Values`,{data : name});
    }catch(error){
      this.handleError("setEducationalValues: "+JSON.stringify(error));
    }
  }

  getApplicantEducationalValues(user_id){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/attestation/getEducationalValues?user_id=`+user_id);
    }catch(error) {
        this.handleError("getApplicantEducationalValues : "+JSON.stringify(error));
    }
  }

  getSemYearValue(user_id){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/attestation/getSemYearValue?user_id=`+user_id);
    }catch(error) {
        this.handleError("getSemYearValue : "+JSON.stringify(error));
    }
  }

  sendPickupMail(email,name){
    try{
      return this.httpClient.post(`${this.baseUrl}/api/admin/adminDashboard/sendPickupMail`,{"email":email,"name":name});  
    }catch(error){
      this.handleError(" sendPickupMail : "+JSON.stringify(error))
    }
  }

 getCouseCode(courseName,semester){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/attestation/getCouseCode?courseName=`+courseName + `&semester=`+semester);
    }catch(error) {
        this.handleError("getCouseCode : "+JSON.stringify(error));
    }
  }

  getCouseCodeYearly(courseName,year){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/attestation/getCourseCodeyearly?courseName=`+courseName + `&year=`+year);
    }catch(error) {
        this.handleError("getCouseCode : "+JSON.stringify(error));
    }
  }

  getCourseName() {
    try{
    const requestUrl = `${this.baseUrl}/api/admin/adminDashboard/getCourseName`;
    return this.httpClient.get(requestUrl);
    }catch(error){
      this.handleError("getCourseName : "+JSON.stringify(error));
    }
  }


  getEducation(edu_id,user_id){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/attestation/getEducation?user_id=`+user_id+`&edu_id=`+edu_id);
    }catch(error) {
        this.handleError("sendOtp : "+JSON.stringify(error));
    }
  }

  getSgpa(user_id){
    
    try{
      return this.httpClient.get(`${this.baseUrl}/api/attestation/getSgpas?user_id=`+user_id);
    }catch(error) {
        this.handleError("sendOtp : "+JSON.stringify(error));
    }
  }


  deleteEducation(id){
    try{
      return  this.httpClient.post(`${this.baseUrl}/api/attestation/deleteEducation`,{"data":id});     
    }catch(error){
      this.handleError('deleteInstituteData: ' + JSON.stringify(error));
    }
  }

  getCouses(){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/attestation/getCouses`);
    }catch(error) {
        this.handleError("getCouses : "+JSON.stringify(error));
    }
  }

  getEduLevelCouses(level){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/attestation/getEduLevelCouses?level=`+level);
    }catch(error) {
        this.handleError("getEduLevelCouses : "+JSON.stringify(error));
    }
  }

  async getAllEducationalData(userId){
    try{
      return await this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/getAllEducationalData?userId=`+userId).toPromise();
    }catch(error) {
      this.handleError("Error in getAllEducationalData : "+error);
    }
  }

  async getApplicationLength(){
    try{
       return await this.httpClient.get(`${this.baseUrl}/api/dashboard/getApplicationLength`);     
    }catch(error) {
      this.handleError("getApplicationLength : "+error);
    }
  }

  trackerChecktab(id){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/dashboard/trackerChecktab?id=`+id);
    }catch(error){
      this.handleError("trackerChecktab :"+error)
    }
  }

  trackerprofileStep(id){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/dashboard/trackerprofileStep?id=`+id);
    }catch(error){
      this.handleError("trackerprofileStep :"+error)
    }
  }

  get_applicant_data(appli_id){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/etranscript/get_applicant_data?appli_id=`+appli_id);
    }catch(error) {
        this.handleError("get_applicant_data : "+JSON.stringify(error));
    }
  }

  regeneratePdf(appl_id,user_id) {
    try{
      return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/regeneratePdf?user_id=${user_id}&appl_id=${appl_id}`);
    }catch(error) {
        this.handleError("regeneratePdf : "+JSON.stringify(error));
    }
  }

  courierSend(appl_id,user_id,date) {
    try{
      return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/courierSend?user_id=${user_id}&appl_id=${appl_id}&date=${date}`);
    }catch(error) {
        this.handleError("courierSend : "+JSON.stringify(error));
    }
  }

  pickupdate(appl_id,user_id,date) {
    try{
      return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/pickupdate?user_id=${user_id}&appl_id=${appl_id}&date=${date}`);
    }catch(error) {
        this.handleError("pickupdate : "+JSON.stringify(error));
    }
  }

  coursewisestats(){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/coursewisestats`);
    }catch(error) {
      this.handleError("coursewisestats : "+JSON.stringify(error));
    }
  }

  lockUnlockEdu(app_id,lock){
    try{
      return this.httpClient.post(`${this.baseUrl}/api/admin/adminDashboard/lockUnlockEdu`,{"app_id":app_id,"lock":lock});  
    }catch(error){
      this.handleError(" lockUnlockEdu : "+JSON.stringify(error))
    }
  }

  saveNotes(id,notes){
    try{
      return this.httpClient.post(`${this.baseUrl}/api/admin/saveNotes`,{id : id, notes : notes});
    }catch(error) {
        this.handleError("saveNotes : "+JSON.stringify(error));
    }
  }

  setCollegeConfirmation(app_id,event){
    try{
      return this.httpClient.post(`${this.baseUrl}/api/admin/setCollegeConfirmation`,{app_id : app_id, eventChecked : event});
    }catch(error) {
        this.handleError("setCollegeConfirmation : "+JSON.stringify(error));
    }
  }

  addAdditional_credits(item: any): Observable<any> {
    try{
      return  this.httpClient.post(`${this.baseUrl}/api/etranscript/addAdditional_credits`,item);
    }catch (error){
      this.handleError('addAdditional_credits : ' + JSON.stringify(error));
    }
  }

  getCourseData() {
    try{
    const requestUrl = `${this.baseUrl}/api/admin/adminDashboard/getCourseData`;
    return this.httpClient.get(requestUrl);
    }catch(error){
      this.handleError("getCourseData : "+JSON.stringify(error));
    }
  }

  setCourseValues(name,courseInfo){
    try{
        return  this.httpClient.post(`${this.baseUrl}/api/attestation/setCourseValues`,{data : name,courseInfo : courseInfo});
    }catch(error){
      this.handleError("setCourseValues: "+JSON.stringify(error));
    }
  }

  getCourseYearData() {
    try{
    const requestUrl = `${this.baseUrl}/api/admin/adminDashboard/getCourseYearData`;
    return this.httpClient.get(requestUrl);
    }catch(error){
      this.handleError("getCourseYearData : "+JSON.stringify(error));
    }
  }

  changeCreditstatus(name,semVal,semValue){
    try{
        return  this.httpClient.post(`${this.baseUrl}/api/attestation/set_credits_Educational_Values`,{data : name,semVal : semVal,semValue : semValue});
    }catch(error){
      this.handleError("changeCreditstatus: "+JSON.stringify(error));
    }
  }


  
  admin_pass_reset(user_mail){
    
    try{
      
      return this.httpClient.post(`${this.baseUrl}/api/admin/adminResetPassword`,{data: user_mail});
    }catch (error) {
      this.handleError("adminResetPassword: "+JSON.stringify(error));
    }
  }

  
  endYear(user_id,year,passingYear){
    try{
      return this.httpClient.post(`${this.baseUrl}/api/attestation/end_Year_Values`,{"user_id" : user_id, "year" : year, "passingYear" : passingYear});
    }catch(error){
      this.handleError("end_Year_Values: "+JSON.stringify(error));
    }
  }

  name_changes(FirstName, Fathername, LastName, userID){
    
    try {
      return this.httpClient.post(`${this.baseUrl}/api/admin/nameChange`,{
        data: userID, 
        fname: FirstName,
        fathername: Fathername,
        lname: LastName
      });
    } catch (error) {
      this.handleError("nameChange: "+JSON.stringify(error));
    }
  }

  deleteApplicantFileConsolidate(data){
    try{ 
      return  this.httpClient.delete(`${this.baseUrl}/api/etranscript/deleteConsolidateTranscripts?id=`+data['id']+'&userId='+data['userId']+'&file_name='+data['file_name']+'&file_path='+data['file_path']);
    }catch(error){
      this.handleError('deleteInstituteData: ' + JSON.stringify(error));
    }
  }

  getApplicantFilesOne(user_id){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/etranscript/getApplicantFilesConsolidate?user_id=`+user_id);
    }catch(error) {
        this.handleError("sendOtp : "+JSON.stringify(error));
    }
  }
  getRequestedApplications(){
    try{
    const requestUrl = `${this.baseUrl}/api/admin/adminDashboard/requested`;
      return this.httpClient.get(requestUrl);
    }catch(error){
      this.handleError("getRequestedApplications : "+JSON.stringify(error));
    }
  }

  getChangedApplications(){
    try{
    const requestUrl = `${this.baseUrl}/api/admin/adminDashboard/changed`;
      return this.httpClient.get(requestUrl);
    }catch(error){
      this.handleError("getChangedApplications : "+JSON.stringify(error));
    }
  }
  getPayIddetails(payid){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/payment/getPayoutId_Details?payid=${payid}`);
    }catch(error) {
      this.handleError("getPayIddetails : "+JSON.stringify(error));
    }
  }
  
  downloadPayout(payid){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/payment/downloadpayout?payid=${payid}`);
    }catch(error) {
      this.handleError("downloadpayout : "+JSON.stringify(error));
    }
  }
  getPayoutdetails(date){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/payment/getPayoutdetails?date=${date}`);
    }catch(error) {
      this.handleError("getPayoutdetails : "+JSON.stringify(error));
    }
  }

  downloadpaymentFiles(file_name,user_id):Observable<Blob>{
    try{
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.httpClient.get(`${this.baseUrl}/api/payment/downloadpayreceipt?file_name=`+file_name+'&user_id='+user_id, { headers: headers, responseType: 'blob'}).map(
        (res) => {
            return new Blob([res], { type: 'application/pdf' });
        });
    }catch(error) {
      this.handleError("unable to get Files : "+JSON.stringify(error));
    }
  }

}
