import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ApiService } from "../shared/api.service";
import {
  NbToastrService,
  NbGlobalPhysicalPosition,
  NbStepperComponent,
  NbDialogService,
  NbDateService,
} from "@nebular/theme";
import { IfStmt } from "@angular/compiler";
import { ConfirmationService } from "primeng/api";
import { MatStepper, MatSelectChange } from "@angular/material";
import { config } from "../../../config";
import { previewImg } from "./dialog/transcriptpreview.component";
import { EducationalFormComponent } from "./dialog/educationalform.component";
import { saveAs } from "file-saver";
import { DatePipe } from "@angular/common";
import { EducationalFormPGDComponent } from "./dialog/educationalformPGDiploma.component";
import { EducationalFormYearComponent } from "./dialog/educationalformyearly.component";
import { addEducationalDetailsSemesterGPA } from "./dialog/educationalformsemgpa.component";
import { addEducationalDetailsSemesterSGPI } from "./dialog/educationalformsemsgpi.component";
import { addMasterEducationalDetailsSemesterSGPI } from "./dialog/mastereducationalformsemsgpi.component";
import { EducationalFormBafComponent } from "./dialog/educationalformbaf.component";
import { MatRadioChange } from "@angular/material";
import { EducationalFormWithoutCgpaComponent } from "./dialog/educationalformWithoutCgpa.component";
import { PaymentModelComponent } from "./../payment-success/payment.component";
@Component({
  selector: "app-apply",
  templateUrl: "./apply.component.html",
  styleUrls: ["./apply.component.scss"],
  providers: [ConfirmationService],
})
export class ApplyComponent implements OnInit {
  checked: boolean = true;
  checked1: any;
  checked2: any;

  firstForm: FormGroup;
  captchaForm: FormGroup;
  //downloadTCForm: FormGroup;
  downloadTCpreferredoptionForm: FormGroup;
  emailForm: FormGroup;
  courierForm: FormGroup;
  //JuniorCollegeTCForm: FormGroup;
  // DegreeCollegeTCForm: FormGroup;
  // AidedDegreeCollegeTCForm: FormGroup;
  // UnAidedDegreeCollegeTCForm: FormGroup;
  // MastersCollegeTCForm: FormGroup;
  // MATCCForm: FormGroup;
  // MScByPapersTCForm: FormGroup;
  // MScByResearchTCForm: FormGroup;
  // PhDTCForm: FormGroup;
  // ChemistryTCForm: FormGroup;
  // CertificateCoursesTCForm: FormGroup;
  // NewCoursesTCForm: FormGroup;
  secondForm: FormGroup;
  thirdForm: FormGroup;
  firstFormData: {};
  isHidden: boolean = true;
  userId = "";
  firstname: any;
  middlename: any;
  lastname: any;
  fathername: any;
  mothername: any;
  preferredoptionfortranscriptdownload: any;
  newaddress: any;
  isNewAddressSame: any;
  couremail: any;
  courmobile: any;
  etelno: any;
  emobileno: any;
  newemail: any;

  tcyearenrollment: any;
  enrollmentyearstandard: any;
  tcyearleaving: any;
  leavingyearstandard: any;
  notes: any;
  tcyearrequiredfrom: any;
  tcyearrequiredto: any;
  numofcouriers: any;

  tcname: any;
  gender: any;
  joiningyear: any;
  standard: any;
  academicyear: any;
  academicclass: any;
  academicmark: any;
  tcrequiredyear: any;
  tcrequirecfrom: any;
  tcrequiredto: any;
  reason: any;
  university: any;
  address: any;
  telno: any;
  mobile: any;
  email: any;
  score1: any;
  score2: any;
  //2nd step //
  user_id: any;
  transUploadUrl = config.docUploadUrl;
  index: number;
  position: string;
  status1: string;
  status: string;
  semestervalue: any;
  showNextButton = false;
  applicantFile = [];
  loadingdoc = false;
  degreeValue: any;
  yearValue: any;
  //end of 2nd step//

  //Payment tab start//
  applicant_id: any;
  user_data: any;
  accessCode: any;
  secureUrl: any;
  encRequest: any;
  //Payment tab end//

  @ViewChild("stepper") stepper: NbStepperComponent;
  setdate: string;
  grescore1: any;
  grescore2: any;
  satscore1: any;
  satscore2: any;
  gmatscore1: any;
  gmatscore2: any;
  tofelscore1: any;
  tofelscore2: any;
  anyotherscorename: any;
  anyotherscore1: any;
  anyotherscore2: any;
  grevalue: any;
  satvalue: any;
  gmatvalue: any;
  tofelvalue: any;
  anyothervalue: any;
  greExamName: string;
  satExamName: string;
  gmatExamName: string;
  tofelExamName: string;
  dob: any;
  academicremark: any;
  whatappNo: any;

  showNextButtonEdu = false;
  educatonalData = [];

  pattern_show = false;
  course_system_show = false;
  Show1 = false;
  Show2 = false;
  Show3 = false;
  Show4 = false;
  Show5 = false;
  Show6 = false;
  Show7 = false;
  startYear: any;
  endYear: any;
  years_array: number[];
  tranReqYear = [];
  mobileCountryCode: any;
  stillStudy = "";
  stillStudingErr = false;

  payment_before_error = false;
  showSpecializationIn = "noshow";
  hide_input = false;

  user_name: any;
  emailPayment: any;
  mobilePayment: any;
  amountPayment: any;
  userDetails: any;
  marksheets: any;
  educations: any;

  show_patteren: any;
  firstcheck = false;
  secondcheck = false;
  thirdcheck = false;
  fourthcheck = false;
  fifthcheck = false;
  sixthcheck = false;
  seventhcheck = false;
  eighttcheck = false;
  ninthcheck = false;
  get_all_data: any;
  tcyearpattern;
  tran_for = [];
  max: Date;
  siteKey = config.siteKey;

  course_data;
  show_year_sem = [];
  consolidate: [];

  isChecked : boolean = true
  showHide = 'show'
  indexVal = 9;
  CourseShortForm: any;
  semYearValue : any;
  constructor(
    private fb: FormBuilder,
    protected api: ApiService,
    private toasterService: NbToastrService,
    private confirmationService: ConfirmationService,
    private dialogService: NbDialogService,
    private dateService: NbDateService<Date>
  ) {
    this.max = this.dateService.addMonth(this.dateService.today(), -120);
  }

  ngOnInit() {
    this.firstForm = this.fb.group({
      stud_surnameCtrl: ["", Validators.required],
      stud_fnameCtrl: ["", Validators.required],
      stud_mnameCtrl: [""],
      stud_fathernameCtrl: [""],
      stud_mothernameCtrl: [""],
      //nameOnTCCtrl: ['', Validators.required],
      studDOBCtrl: ["", Validators.required],
      genderCtrl: ["", Validators.required],
      yojAcademicYear1nCtrl: [""],
      standardCtrl: [""],
      yojAcademicYear2nCtrl: [""],
      classCtrl: [""],
      remarkCtrl: [""],
      TCyearCtrl: [""],
      class1Ctrl: [""],
      class2Ctrl: [""],
      grescore1Ctrl: [""],
      grescore2Ctrl: [""],
      satscore1Ctrl: [""],
      satscore2Ctrl: [""],
      gmatscore1Ctrl: [""],
      gmatscore2Ctrl: [""],
      tofelscore1ctrl: [""],
      tofelscore2ctrl: [""],
      anyotherscorenamectrl: [""],
      anyotherscore1ctrl: [""],
      anyotherscore2ctrl: [""],
      reasonCtrl: ["", [Validators.required, Validators.pattern]],
      universitiesCtrl: [""],
      addressCtrl: [""],
      telnumberCtrl: [""],
      mobileCtrl: ["", [Validators.required]],
      emailidCtrl: ["", Validators.required],
      phonecodeCtrl: ["", Validators.required],
      enrollmentYearStandardCtrl: ["", Validators.required],
      leavingYearStandardCtrl: ["", Validators.required],
      //notesCtrl: [''],
      TCyearEnrollmentCtrl: ["", Validators.required],
      TCyearLeavingCtrl: ["", Validators.required],
      // TCyearrequiredfromCtrl: ['', Validators.required],
      // TCyearrequiredtoCtrl: ['', Validators.required],
      stillStudyingCtrl: ["", Validators.required],
      TCpatternCtrl: ["", Validators.required],
      //tran_req_ctrl : [],
      //captchaCtrl:['', Validators.required],
      educationlevelCtrl: ["", Validators.required],
      educationCourseCtrl: [""],
    });

    this.captchaForm = this.fb.group({
      captchaCtrl: ["", Validators.required],
    });

    this.secondForm = this.fb.group({
      secondCtrl: ["", Validators.required],
      course_type: [""],
      pattern_course: [""],
      course_system: [""],
      additional_credits: [""],
    });

    this.thirdForm = this.fb.group({
      fNPaymentCtrl: [""],
      mobilePaymentCtrl: [""],
      emailPaymentCtrl: [""],
      amountPaymentCtrl: [""],
    });

    this.downloadTCpreferredoptionForm = this.fb.group({
      downloadTCpreferredoptionCtrl: ["", Validators.required],
    });

    this.emailForm = this.fb.group({
      alternateemailcheckCtrl: [""],
      emailFormCtrl: [""],
      courierFormCtrl: ["", Validators.required],
      // mobile1Ctrl: ['', Validators.required],
      // telnoCtrl: [''],
      // phonecodeCtrl: ['', Validators.required],
    });

    this.courierForm = this.fb.group({
      courierFormCtrl: ["", Validators.required],
      addressForCourierCtrl: ["", Validators.required],
      courEmailCtrl: [""],
      sameCheckCtrl: ["", Validators.required],
      courMobileCtrl: ["", Validators.required],
      alternateemailcheckcourierCtrl: [""],
    });

    const now = new Date().getUTCFullYear();
    this.years_array = Array(now - (now - 50))
      .fill("")
      .map((v, idx) => now - idx);
  }

  toggle(checked: boolean) {
    this.checked = !checked;
  }

  togglealternateemailcheck(checked1) {
    this.checked1 = checked1.target.checked;
  }

  togglealternateemailcheckcourier(checked2) {
    this.checked2 = checked2.target.checked;
  }

  selectPreferedOption($event) {
    
    if ($event == "Email") {
      this.emailForm.controls["courierFormCtrl"].setValidators([]);
      this.emailForm.controls["courierFormCtrl"].updateValueAndValidity();
    } else {
      this.emailForm.controls["courierFormCtrl"].setValidators([
        Validators.required,
      ]);
      this.emailForm.controls["courierFormCtrl"].updateValueAndValidity();
    }
  }

  firstFormSubmit(user_id) {
    //var error=this.findInvalidControls(this.firstForm.controls)

    const config = {
      position: NbGlobalPhysicalPosition.BOTTOM_RIGHT,
    };
    if (
      this.firstForm.controls.stillStudyingCtrl.value != null &&
      this.firstForm.controls.stillStudyingCtrl.value != "" &&
      this.firstForm.controls.stillStudyingCtrl.value != undefined
    ) {
      this.stillStudingErr = false;
    } else {
      this.stillStudingErr = true;
    }
    this.firstForm.controls.stud_surnameCtrl.markAsDirty();
    this.firstForm.controls.stud_fnameCtrl.markAsDirty();
    this.firstForm.controls.stud_mnameCtrl.markAsDirty();
    this.firstForm.controls.stud_fathernameCtrl.markAsDirty();
    this.firstForm.controls.stud_mothernameCtrl.markAsDirty();
    this.firstForm.controls.educationlevelCtrl.markAsDirty();
    //this.firstForm.controls.educationCourseCtrl.markAsDirty();
    //this.firstForm.controls.nameOnTCCtrl.markAsDirty();
    this.firstForm.controls.studDOBCtrl.markAsDirty();
    this.firstForm.controls.genderCtrl.markAsDirty();
    this.firstForm.controls.yojAcademicYear1nCtrl.markAsDirty(),
      this.firstForm.controls.standardCtrl.markAsDirty(),
      this.firstForm.controls.yojAcademicYear2nCtrl.markAsDirty(),
      this.firstForm.controls.classCtrl.markAsDirty(),
      this.firstForm.controls.remarkCtrl.markAsDirty();
    this.firstForm.controls.TCyearCtrl.markAsDirty(),
      this.firstForm.controls.reasonCtrl.markAsDirty();
    //this.firstForm.controls.universitiesCtrl.markAsDirty();
    this.firstForm.controls.addressCtrl.markAsDirty();
    this.firstForm.controls.telnumberCtrl.markAsDirty();
    this.firstForm.controls.mobileCtrl.markAsDirty();
    this.firstForm.controls.emailidCtrl.markAsDirty();
    this.firstForm.controls.enrollmentYearStandardCtrl.markAsDirty();
    this.firstForm.controls.leavingYearStandardCtrl.markAsDirty();
    //this.firstForm.controls.notesCtrl.markAsDirty();
    this.firstForm.controls.TCyearEnrollmentCtrl.markAsDirty();
    this.firstForm.controls.TCyearLeavingCtrl.markAsDirty();
    //this.firstForm.controls.TCyearrequiredfromCtrl.markAsDirty();
    //this.firstForm.controls.TCyearrequiredtoCtrl.markAsDirty();
    this.downloadTCpreferredoptionForm.controls.downloadTCpreferredoptionCtrl.markAsDirty();
    //this.emailForm.controls.phonecodeCtrl.markAsDirty();
    // this.firstForm.controls.captchaCtrl.markAsDirty();

    //if (this.firstForm.valid && this.downloadTCForm.valid && this.JuniorCollegeTCForm.valid)
    if (this.firstForm.valid) {
      var date = this.firstForm.controls.studDOBCtrl.value;
      var datePipe = new DatePipe("en-IN");
      this.setdate = datePipe.transform(date, "yyyy-MM-dd");
      this.firstFormData = {
        stud_surnameCtrl:
          this.firstForm.controls.stud_surnameCtrl.value.toUpperCase(),
        stud_fnameCtrl: this.firstForm.controls.stud_fnameCtrl.value
          ? this.firstForm.controls.stud_fnameCtrl.value.toUpperCase()
          : "",
        stud_mnameCtrl: this.firstForm.controls.stud_mnameCtrl.value
          ? this.firstForm.controls.stud_mnameCtrl.value.toUpperCase()
          : "",
        stud_fathernameCtrl: this.firstForm.controls.stud_fathernameCtrl.value
          ? this.firstForm.controls.stud_fathernameCtrl.value.toUpperCase()
          : "",
        stud_mothernameCtrl: this.firstForm.controls.stud_mothernameCtrl.value
          ? this.firstForm.controls.stud_mothernameCtrl.value.toUpperCase()
          : "",
        //nameOnTCCtr:this.firstForm.controls.nameOnTCCtrl.value,
        studDOBCtrl: this.setdate,
        genderCtrl: this.firstForm.controls.genderCtrl.value,
        yojAcademicYear1nCtrl:
          this.firstForm.controls.yojAcademicYear1nCtrl.value,
        standardCtrl: this.firstForm.controls.standardCtrl.value,
        yojAcademicYear2nCtrl:
          this.firstForm.controls.yojAcademicYear2nCtrl.value,
        classCtrl: this.firstForm.controls.classCtrl.value,
        remarkCtrl: this.firstForm.controls.remarkCtrl.value,
        TCyearCtrl: this.firstForm.controls.TCyearCtrl.value,
        reasonCtrl: this.firstForm.controls.reasonCtrl.value,
        universitiesCtrl: this.firstForm.controls.universitiesCtrl.value,
        addressCtrl: this.firstForm.controls.addressCtrl.value,
        telnumberCtrl: this.firstForm.controls.telnumberCtrl.value,
        mobileCtrl: this.firstForm.controls.mobileCtrl.value,
        emailidCtrl: this.firstForm.controls.emailidCtrl.value,
        educationlevelCtrl:this.firstForm.controls.educationlevelCtrl.value,
        educationCourseCtrl : this.firstForm.controls.educationCourseCtrl.value,
        enrollmentYearStandardCtrl:
          this.firstForm.controls.enrollmentYearStandardCtrl.value,
        leavingYearStandardCtrl:
          this.firstForm.controls.leavingYearStandardCtrl.value,
        //notesCtrl:this.firstForm.controls.notesCtrl.value,
        TCyearEnrollmentCtrl:
          this.firstForm.controls.TCyearEnrollmentCtrl.value,
        TCyearLeavingCtrl: this.firstForm.controls.TCyearLeavingCtrl.value,
        //TCyearrequiredfromCtrl:this.firstForm.controls.TCyearrequiredfromCtrl.value,
        //TCyearrequiredtoCtrl:this.firstForm.controls.TCyearrequiredtoCtrl.value,
        //mobile1Ctrl:this.emailForm.controls.mobile1Ctrl.value,
        emailFormCtrl: this.emailForm.controls.emailFormCtrl.value,
        //telnoCtrl:this.emailForm.controls.telnoCtrl.value,
        courierFormCtrl: this.emailForm.controls.courierFormCtrl.value,
        downloadTCpreferredoptionCtrl:
          this.downloadTCpreferredoptionForm.controls
            .downloadTCpreferredoptionCtrl.value,
        addressForCourierCtrl:
          this.courierForm.controls.addressForCourierCtrl.value,
        sameCheckCtrl: this.courierForm.controls.sameCheckCtrl.value,
        courEmailCtrl: this.courierForm.controls.courEmailCtrl.value,
        courMobileCtrl: this.courierForm.controls.courMobileCtrl.value,
        mob_country_code: this.firstForm.controls.phonecodeCtrl.value,
        whatapp_no: this.whatappNo,
        stillStudying: this.firstForm.controls.stillStudyingCtrl.value,
        firstcheck: this.firstcheck,
        secondcheck: this.secondcheck,
        thirdcheck: this.thirdcheck,
        fourthcheck: this.fourthcheck,
        fifthcheck: this.fifthcheck,
        sixthcheck: this.sixthcheck,
        seventhcheck: this.seventhcheck,
        eighttcheck: this.eighttcheck,
        ninthcheck: this.ninthcheck,
        // JuniorCollegeTCCtrl:this.JuniorCollegeTCForm.controls.JuniorCollegeTCCtrl.value,
        //captchaCtrl:this.firstForm.controls.captchaCtrl.value,
      };
      if (user_id == undefined || user_id == "") {
        this.api.addFirstFormData(this.firstFormData).subscribe((data) => {
          if (data["status"] == 200) {
            this.hide_input = true;
            this.toasterService.show(
              "Successfully saved!!!...",
              "Success",
              config
            );
            this.user_id = data["data"]["id"];
            this.setThirdFormValue(this.user_id);
            this.preview();
            this.userId = data["data"]["id"];
            this.firstcheck = data["data"]["yearfirstcheck"];
            this.secondcheck = data["data"]["yearsecondcheck"];
            this.thirdcheck = data["data"]["yearthirdcheck"];
            this.fourthcheck = data["data"]["semfirstcheck"];
            this.fifthcheck = data["data"]["semsecondcheck"];
            this.sixthcheck = data["data"]["semthirdcheck"];
            this.seventhcheck = data["data"]["semforthcheck"];
            this.eighttcheck = data["data"]["semfifthcheck"];
            this.ninthcheck = data["data"]["semsixcheck"];
            this.firstname = data["data"]["firstname"];
            this.middlename = data["data"]["middlename"];
            this.lastname = data["data"]["lastname"];
            this.fathername = data["data"]["fathername"];
            this.mothername = data["data"]["mothername"];
            this.tcname = data["data"]["tcname"];
            this.gender = data["data"]["gender"];
            this.joiningyear = data["data"]["joiningyear"];
            this.standard = data["data"]["standard"];
            this.academicyear = data["data"]["academicyear"];
            this.academicclass = data["data"]["academicclass"];
            this.academicmark = data["data"]["firstname"];
            this.tcrequiredyear = data["data"]["tcrequiredyear"];
            this.tcrequirecfrom = data["data"]["tcrequirecfrom"];
            this.tcrequiredto = data["data"]["tcrequiredto"];
            this.reason = data["data"]["reason"];
            this.university = data["data"]["university"];
            this.address = data["data"]["address"];
            this.telno = data["data"]["telno"];
            this.mobile = data["data"]["mobile"];
            this.email = data["data"]["email"];
            this.etelno = data["data"]["etelno"];
            this.emobileno = data["data"]["emobileno"];
            this.mobileCountryCode = data["data"]["mob_country_code"];
            this.newemail = data["data"]["newemail"];
            this.tcyearenrollment = data["data"]["enrollmentYear"];
            this.enrollmentyearstandard =
              data["data"]["enrollmentYearStandard"];
            this.tcyearleaving = data["data"]["leavingYear"];
            this.leavingyearstandard = data["data"]["leavingYearStandard"];
            this.notes = data["data"]["notes"];
            this.tcyearrequiredfrom = data["data"]["tcyearfrom"];
            this.tcyearrequiredto = data["data"]["tcyearto"];
            this.numofcouriers = data["data"]["numofcouriers"];
            this.preferredoptionfortranscriptdownload =
              data["data"]["preferredoptionfortranscriptdownload"];
            this.newaddress = data["data"]["newaddress"];
            this.isNewAddressSame = data["data"]["isNewAddressSame"];
            this.couremail = data["data"]["couremail"];
            this.courmobile = data["data"]["courmobile"];
            this.stepper.next();
          }
        });
      } else if (user_id != undefined || user_id != "") {
        // this.user_id = user_id;
        this.api
          .updateFirstformData(this.firstFormData, this.user_id)
          .subscribe((data) => {
            if (data["status"] == 200) {
              this.preview();
              this.setThirdFormValue(this.user_id);
              this.toasterService.show(
                "Successfully updated!!!...",
                "Success",
                config
              );
              this.stepper.next();
            }
          });
      }
    } else {
      this.toasterService.show(
        "Please fill mandatory details!!!...",
        "Warning",
        config
      );
    }
  }

  onFirstSubmit() {
    this.firstForm.markAsDirty();
  }

  onSecondSubmit() {
    this.secondForm.markAsDirty();
  }

  getData(event: MatSelectChange) {
    this.semestervalue = event.source.value;
  }

  getDegree(event: MatSelectChange) {
    this.degreeValue = event.source.value;
   
  }

  getYear(event: MatSelectChange) {
    this.yearValue = event.source.value;
  }

  onUpload(event: any, edu_year) {
    
    const reader = new FileReader();
    var duration = 10000;
    this.index += 1;
    this.position = "top-right";
    this.status1 = "success";
    const config = {
      position: NbGlobalPhysicalPosition.BOTTOM_RIGHT,
    };
    //this.ngOnInit();
    if (event.files && event.files.length) {
      const [file] = event.files;
      reader.readAsDataURL(file);
      var json = JSON.parse(event.xhr.response);
      var yourData = json.data; // or json["Data"]
      var yourStatus = json.status; // or json["Data"]
      var yourMessage = json.message; // or json["Data"]

      if (yourStatus == 200) {
        var update_array = this.show_year_sem.findIndex(obj => obj.name === edu_year);
        this.show_year_sem[update_array].file = yourData;
        this.preview();
        this.loadingdoc = false;
        this.toasterService.show(yourMessage, "Success", config);
        this.api.getApplicantFiles(this.user_id).subscribe((data) => {
          this.applicantFile = data["data"];
          
          
          if (data["data"].length == this.show_year_sem.length) {
            this.showNextButton = true;
          } else {
            this.showNextButton = false;
          }
        });
      } else if (yourStatus == 401) {
        // this.loading = false;
        this.loadingdoc = false;
        this.toasterService.show(yourMessage, "Success", config);
      } else if (yourStatus == 400) {
        // this.loading = false;
        this.loadingdoc = false;
        this.toasterService.show(yourMessage, "Success", config);
      }
    }
  }

  onSelect($event: any, value): void {
    var maxFileSize = 5000000;
    var imgArr = $event.files[0].name.split(".");
    var extension = imgArr[imgArr.length - 1].trim();
    this.loadingdoc = true;
    if ($event.files[0].size > maxFileSize) {
      this.confirmationService.confirm({
        message: "Maximum file size should be 5 MB.",
        header: "Invalid File Size",
        icon: "pi pi-info-circle",
        rejectVisible: false,
        acceptLabel: "Ok",
      });
      this.loadingdoc = false;
    }

    if (
      value != undefined &&
      extension != "jpg" &&
      extension != "jpeg" &&
      extension != "png" &&
      extension != "JPG" &&
      extension != "JPEG" &&
      extension != "PNG" &&
      extension != "pdf" &&
      extension != "Pdf" &&
      extension != "docx" &&
      extension != "DOCX"
    ) {
      this.confirmationService.confirm({
        message:
          "Please upload your transcript in .jpeg or .jpg or .png or .docx formats",
        header: "Invalid File Type",
        icon: "pi pi-info-circle",
        rejectVisible: false,
        acceptLabel: "Ok",
      });
      this.loadingdoc = false;
    }

    if (
      value == undefined &&
      extension != "jpg" &&
      extension != "jpeg" &&
      extension != "png" &&
      extension != "pdf" &&
      extension != "JPG" &&
      extension != "JPEG" &&
      extension != "PNG" &&
      extension != "PDF" &&
      extension != "docx" &&
      extension != "DOCX"
    ) {
      this.confirmationService.confirm({
        message:
          "Please upload your transcript in .jpeg or .jpg or .png or .pdf or .docx formats",
        header: "Invalid File Type",
        icon: "pi pi-info-circle",
        rejectVisible: false,
        acceptLabel: "Ok",
      });
      this.loadingdoc = false;
    }
  }

  onView(imagename, imgType) {
    
    this.dialogService.open(previewImg, {
      context: {
        arr: imagename,
        //arr : 'https://mu.admissiondesk.org/uiserver/upload/transcript/20086/HywOaKlgI.pdf',
        imgType: imgType,
      },
    });
  }

  onDeleteMarklist(info, edu_year) {
    var duration = 10000;
    this.position = "top-right";
    this.status = "success";
    this.api.deleteApplicantFile(info).subscribe((data) => {
      var yourMessage = data["message"];
      if (data["status"] == 200) {
        this.preview();
        this.status = "success";
        const config = {
          position: NbGlobalPhysicalPosition.TOP_RIGHT,
        };
        this.toasterService.show(" " + yourMessage, `Success`, config);
        var update_array = this.show_year_sem.findIndex(obj => obj.name === edu_year);
        this.show_year_sem[update_array].file = null;
        this.api.getApplicantFiles(this.user_id).subscribe((data) => {
          this.applicantFile = data["data"];
          if (data["data"].length  == this.show_year_sem.length) {
            this.showNextButton = true;
          } else {
            this.showNextButton = false;
          }
        });
      }
    });
  }

  downldTemplate() {
    this.api.downloadTemplate().subscribe((data) => {
      saveAs(data);
    });
  }

  onThirdSubmit() {
    this.thirdForm.markAsDirty();
  }
  async resolved(captchaResponse: string) {
    
    await this.sendTokenToBackend(captchaResponse);
  }
  //function to send the token to the node server
  sendTokenToBackend(tok) {
    //calling the service and passing the token to the service
    this.api.sendToken(tok).subscribe(
      (data) => {
        if (data.success) {
          this.isHidden = false;
        } else {
          this.isHidden = true;
        }
      },
      (err) => {
        this.isHidden = true;
        console.error(err);
      },
      () => {}
    );
  }
  getCaptchactrl() {
    
    return this.captchaForm.get("captchaCtrl").hasError("required")
      ? "Captcha is required "
      : "";
  }

  validate() {
    // if(this.firstForm.invalid){
    //   alert('Please Fill In All The Required Details!');
    // }
  }

  setThirdFormValue(applicant_id) {
   
    this.api.getApplicantData(applicant_id).subscribe((data) => {
     
      this.preview();
      if (data["status"] == 200) {
        this.user_name = data["data"]["user_name"];
        this.emailPayment = data["data"]["user_email"];
        this.mobilePayment = data["data"]["user_mobile"];
        this.amountPayment = data["data"]["amount"];
      }
    });
  }

  // async firstpayment(){
  //   this.payment_before_error = false;
  //   if(this.showNextButtonEdu == true && this.showNextButton == true){
  //     var user_id = this.user_id;
  //     var payment = await this.api.paymentrequest(user_id);
  //     payment.subscribe(
  //         data => {
  //           this.accessCode = data['data']['accessCode'];
  //           this.secureUrl = data['data']['secureUrl'];
  //           this.encRequest = data['data']['encRequest'];
  //           setTimeout(function(){
  //           this.loading = false;
  //             var myForm = <HTMLFormElement>document.getElementById('nonseamless');
  //             myForm.submit();
  //           }, 1000);
  //         },
  //         error => {
  //             console.error("Error", error);
  //         }
  //     );
  //   }else{
  //     this.payment_before_error = true;
  //   }
  // }

  next() {
    this.stepper.next();
  }

  semYearValues(){
    this.api.getSemYearValue(this.userId).subscribe(data => {
      this.semYearValue = data['data'];
      
    });
  }

  addEducationalDetails(id, user_id) {
    var paid_amount = "false";
    var admin_show = "false";
    this.dialogService
      .open(EducationalFormComponent, {
        closeOnBackdropClick: false,
        context: {
          user_id: user_id,
          edu_id: id,
          amount_paid: paid_amount,
          admin_show: admin_show,
        },
      })
      .onClose.subscribe((data: any) => {
        if (data) {
          this.position = "top-right";
          this.status1 = "success";
          const config = {
            position: NbGlobalPhysicalPosition.BOTTOM_RIGHT,
          };
          var yourMessage = "Saved";
          this.toasterService.show(yourMessage, "Success", config);
        }
        this.api.getApplicantEducationalValues(user_id).subscribe((data) => {
          this.educatonalData = data["data"];
          if (data["data"].length  == this.show_year_sem.length) {
            this.setThirdFormValue(this.user_id);
            this.showNextButtonEdu = true;
          } else {
            this.showNextButtonEdu = false;
          }
        });
      });
  }

  onDeleteEducation(info) {
    var duration = 10000;
    this.position = "top-right";
    this.status = "success";
    this.api.deleteEducation(info).subscribe((data) => {
      var yourMessage = data["message"];
      if (data["status"] == 200) {
        this.preview();
        this.status = "success";
        const config = {
          position: NbGlobalPhysicalPosition.TOP_RIGHT,
        };
        this.toasterService.show(" " + yourMessage, `Success`, config);
        this.api
          .getApplicantEducationalValues(this.user_id)
          .subscribe((data) => {
            this.educatonalData = data["data"];
            if (data["data"].length == this.show_year_sem.length) {
              this.setThirdFormValue(this.user_id);
              this.showNextButtonEdu = true;
            } else {
              this.showNextButtonEdu = false;
            }
          });
      }
    });
  }

  courseradioChange(event: MatRadioChange) {
    this.Show1 = false;
    this.Show2 = false;
    this.Show3 = false;
    this.Show4 = false;
    this.Show5 = false;
    this.Show6 = false;
    
    if (event.value == "Normal") {
      this.pattern_show = true;
      this.Show1 = false;
      this.Show6 = false;
      this.showSpecializationIn = "noshow";
    } else if (event.value == "Special") {
      this.Show2 = true;
      this.showSpecializationIn = "show";
      // if(this.firstForm.controls.TCyearEnrollmentCtrl.value > 2010){
      //   this.Show1 = true;
      // }else{
      //   this.Show6 = true;
      // }
      this.pattern_show = false;
      this.course_system_show = false;
    } else if (event.value == "Master") {
      this.pattern_show = true;
      //this.course_system_show = false;
      //this.Show5 = true;
      this.showSpecializationIn = "show";
    }
  }

  patternradioChange(event: MatRadioChange) {
    this.Show1 = false;
    this.Show2 = false;
    this.Show3 = false;
    this.Show4 = false;
    this.Show5 = false;
    this.Show6 = false;
    
    if (event.value == "semester") {
      this.course_system_show = true;
      this.Show2 = false;
    } else {
      this.course_system_show = false;
      this.Show2 = true;
      this.showSpecializationIn = "noshow";
    }
  }

  systemradioChange(event: MatRadioChange) {
    this.Show1 = false;
    this.Show2 = false;
    this.Show3 = false;
    this.Show4 = false;
    this.Show5 = false;
    this.Show6 = false;
    this.Show7 = false;
    
    if (event.value == "GPA") {
      this.Show3 = true;
    } else if (event.value == "SGPI") {
      this.Show4 = true;
    } else if (event.value == "withoutCgpa") {
      this.Show7 = true;
    }
  }

  additional_creditsChange(event: MatRadioChange) {
    if (event.value) {
      var info = { val: event.value, user_id: this.user_id };
      this.api.addAdditional_credits(info).subscribe((data) => {});
    }
  }

  stillStudyingChange(event: MatRadioChange) {
    if (event.value == "yes") {
      
      this.stillStudy = "yes";
    } else {
      
      this.stillStudy = "no";
    }
  }

  addEducationalDetailsYearly(id, user_id, edu_year) {
    var paid_amount = "false";
    var admin_show = "false";
    var autoName = "Non Autonomous";
    var course_name_auto = this.firstForm.controls['educationCourseCtrl'].value
    if(this.firstForm.controls['educationCourseCtrl'].value == 'Higher Secondary Certificate'){
      this.showSpecializationIn = "show"
    }else{
      this.showSpecializationIn = "noshow"
    }
    this.dialogService
      .open(EducationalFormYearComponent, {
        closeOnBackdropClick: false,
        context: {
          user_id: user_id,
          edu_id: id,
          amount_paid: paid_amount,
          admin_show: admin_show,
          autoName: autoName,
          yearValue: this.tranReqYear,
          showSpecialization: this.showSpecializationIn,
          course_name_auto : this.firstForm.controls['educationCourseCtrl'].value,
          college_year_auto : edu_year,
          // courseShortName :  this.CourseShortForm
        },
      })
      .onClose.subscribe((data: any) => {
        if (data) {
          this.position = "top-right";
          this.status1 = "success";
          const config = {
            position: NbGlobalPhysicalPosition.BOTTOM_RIGHT,
          };
          var yourMessage = "Saved";
          this.toasterService.show(yourMessage, "Success", config);
          var update_array = this.show_year_sem.findIndex(obj => obj.name === edu_year);
          this.show_year_sem[update_array].id = data['data'].id;
        }
        this.api.getApplicantEducationalValues(user_id).subscribe((data) => {
          this.educatonalData = data["data"];
          if (data["data"].length  == this.show_year_sem.length) {
            this.setThirdFormValue(this.user_id);
            this.showNextButtonEdu = true;
          } else {
            this.showNextButtonEdu = false;
          }
        });
      });
  }
  addEducationalDetailsPgDiploma(id, user_id, edu_year) {
    
    var paid_amount = "false";
    var admin_show = "false";
    var autoName = " Autonomous";
    this.dialogService
      .open(EducationalFormPGDComponent, {
        closeOnBackdropClick: false,
        context: {
          user_id: user_id,
          edu_id: id,
          amount_paid: paid_amount,
          admin_show: admin_show,
          autoName: autoName,
          yearValue: this.tranReqYear,
          showSpecialization: this.showSpecializationIn,
          course_name_auto : this.firstForm.controls['educationCourseCtrl'].value,
          college_year_auto : edu_year,
          // courseShortName :  this.CourseShortForm
        },
      })
      .onClose.subscribe((data: any) => {
        if (data) {
          this.position = "top-right";
          this.status1 = "success";
          const config = {
            position: NbGlobalPhysicalPosition.BOTTOM_RIGHT,
          };
          var yourMessage = "Saved";
          this.toasterService.show(yourMessage, "Success", config);
          var update_array = this.show_year_sem.findIndex(obj => obj.name === edu_year);
          this.show_year_sem[update_array].id = data['data'].id;
        }
        this.api.getApplicantEducationalValues(user_id).subscribe((data) => {
          this.educatonalData = data["data"];
          if (data["data"].length  == this.show_year_sem.length) {
            this.setThirdFormValue(this.user_id);
            this.showNextButtonEdu = true;
          } else {
            this.showNextButtonEdu = false;
          }
        });
      });
  }
  addEducationalDetailsSemesterGPA(id, user_id, edu_year) {
    var paid_amount = "false";
    var admin_show = "false";
    var autoName = " Autonomous";
    this.dialogService
      .open(addEducationalDetailsSemesterGPA, {
        closeOnBackdropClick: false,
        context: {
          user_id: user_id,
          edu_id: id,
          amount_paid: paid_amount,
          admin_show: admin_show,
          autoName: autoName,
          yearValue: this.tranReqYear,
          showSpecialization: this.showSpecializationIn,
          course_name_auto : this.firstForm.controls['educationCourseCtrl'].value,
          college_year_auto : edu_year,
          // courseShortName :  this.CourseShortForm
        },
      })
      .onClose.subscribe((data: any) => {
        if (data) {
          this.position = "top-right";
          this.status1 = "success";
          const config = {
            position: NbGlobalPhysicalPosition.BOTTOM_RIGHT,
          };
          var yourMessage = "Saved";
          this.toasterService.show(yourMessage, "Success", config);
          var update_array = this.show_year_sem.findIndex(obj => obj.name === edu_year);
          this.show_year_sem[update_array].id = data['data'].id;
        }
        this.api.getApplicantEducationalValues(user_id).subscribe((data) => {
          this.educatonalData = data["data"];
          if (data["data"].length  == this.show_year_sem.length) {
            this.setThirdFormValue(this.user_id);
            this.showNextButtonEdu = true;
          } else {
            this.showNextButtonEdu = false;
          }
        });
      });
  }
  addEducationalDetailswithoutCgpa(id, user_id, edu_year) {
    var paid_amount = "false";
    var admin_show = "false";
    var autoName = "Non Autonomous";
    var yearValue = [];
    yearValue = this.tranReqYear;
    this.dialogService
      .open(EducationalFormWithoutCgpaComponent, {
        closeOnBackdropClick: false,
        context: {
          user_id: user_id,
          edu_id: id,
          amount_paid: paid_amount,
          admin_show: admin_show,
          autoName: autoName,
          yearValue: this.tranReqYear,
          course_name_auto : this.firstForm.controls['educationCourseCtrl'].value,
          college_year_auto : edu_year,
          // courseShortName :  this.CourseShortForm
        },
      })
      .onClose.subscribe((data: any) => {
        if (data) {
          this.position = "top-right";
          this.status1 = "success";
          const config = {
            position: NbGlobalPhysicalPosition.BOTTOM_RIGHT,
          };
          var yourMessage = "Saved";
          this.toasterService.show(yourMessage, "Success", config);
          var update_array = this.show_year_sem.findIndex(obj => obj.name === edu_year);
          this.show_year_sem[update_array].id = data['data'].id;
        }
        this.api.getApplicantEducationalValues(user_id).subscribe((data) => {
          this.educatonalData = data["data"];
          if (data["data"].length == this.show_year_sem.length) {
            this.setThirdFormValue(this.user_id);
            this.showNextButtonEdu = true;
          } else {
            this.showNextButtonEdu = false;
          }
        });
      });
  }

  addEducationalDetailsSemesterSGPI(id, user_id) {
    var paid_amount = "false";
    var admin_show = "false";
    this.dialogService
      .open(addEducationalDetailsSemesterSGPI, {
        closeOnBackdropClick: false,
        context: {
          user_id: user_id,
          edu_id: id,
          amount_paid: paid_amount,
          admin_show: admin_show,
        },
      })
      .onClose.subscribe((data: any) => {
        if (data) {
          this.position = "top-right";
          this.status1 = "success";
          const config = {
            position: NbGlobalPhysicalPosition.BOTTOM_RIGHT,
          };
          var yourMessage = "Saved";
          this.toasterService.show(yourMessage, "Success", config);
        }
        this.api.getApplicantEducationalValues(user_id).subscribe((data) => {
          this.educatonalData = data["data"];
          if (data["data"].length == this.show_year_sem.length) {
            this.setThirdFormValue(this.user_id);
            this.showNextButtonEdu = true;
          } else {
            this.showNextButtonEdu = false;
          }
        });
      });
  }

  addMasterEducationalDetailsSemesterSGPI(id, user_id) {
    var paid_amount = "false";
    var admin_show = "false";
    this.dialogService
      .open(addMasterEducationalDetailsSemesterSGPI, {
        closeOnBackdropClick: false,
        context: {
          user_id: user_id,
          edu_id: id,
          amount_paid: paid_amount,
          admin_show: admin_show,
        },
      })
      .onClose.subscribe((data: any) => {
        if (data) {
          this.position = "top-right";
          this.status1 = "success";
          const config = {
            position: NbGlobalPhysicalPosition.BOTTOM_RIGHT,
          };
          var yourMessage = "Saved";
          this.toasterService.show(yourMessage, "Success", config);
        }
        this.api.getApplicantEducationalValues(user_id).subscribe((data) => {
          this.educatonalData = data["data"];
          if (data["data"].length == this.show_year_sem.length) {
            this.setThirdFormValue(this.user_id);
            this.showNextButtonEdu = true;
          } else {
            this.showNextButtonEdu = false;
          }
        });
      });
  }

  addEducationalDetailsBaf(id, user_id) {
    var paid_amount = "false";
    var admin_show = "false";
    this.dialogService
      .open(EducationalFormBafComponent, {
        closeOnBackdropClick: false,
        context: {
          user_id: user_id,
          edu_id: id,
          amount_paid: paid_amount,
          admin_show: admin_show,
        },
      })
      .onClose.subscribe((data: any) => {
        if (data) {
          this.position = "top-right";
          this.status1 = "success";
          const config = {
            position: NbGlobalPhysicalPosition.BOTTOM_RIGHT,
          };
          var yourMessage = "Saved";
          this.toasterService.show(yourMessage, "Success", config);
        }
        this.api.getApplicantEducationalValues(user_id).subscribe((data) => {
          this.educatonalData = data["data"];
          if (data["data"].length == this.show_year_sem.length) {
            this.setThirdFormValue(this.user_id);
            this.showNextButtonEdu = true;
          } else {
            this.showNextButtonEdu = false;
          }
        });
      });
  }

  getSelectedYear() {
    this.tranReqYear = [];
    var d = new Date();
    //var n = getFullYear();
    const now = new Date().getUTCFullYear();
    this.years_array = Array(now - (now - 55))
      .fill("")
      .map((v, idx) => now - idx);
    

    //let dateOption = document.createElement('option');
   
    if (
      this.firstForm.controls.TCyearEnrollmentCtrl.value &&
      this.firstForm.controls.TCyearLeavingCtrl.value
    ) {
      this.startYear = this.firstForm.controls.TCyearEnrollmentCtrl.value;
      this.startYear = Number(this.startYear);
      this.endYear = this.firstForm.controls.TCyearLeavingCtrl.value;
      this.endYear = Number(this.endYear);
    }

    for (let info of this.years_array) {
      

      if (info >= this.startYear && info <= this.endYear) {
        
        this.tranReqYear.push(info);
      }
    }
  }

  onNoSelect(event: any) {
    if (event.target.checked == true) {
      if (this.firstForm.controls.mobileCtrl.value) {
        
        this.whatappNo = this.firstForm.controls.mobileCtrl.value;
        
      }
    } else {
      this.whatappNo = "";
    }
  }

  onKeyPress(event: any) {
   
    this.api
      .emailValues(this.firstForm.controls.emailidCtrl.value)
      .subscribe((data: any) => {
        if (data["status"] == 400) {
          this.confirmationService.confirm({
            message:
              "Your email id is already exists in our database!!! Check your Email for login credentials.",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => {},
            reject: () => {},
          });
        } else {
        }
        (err) => console.error(err);
      });
  }

  preview() {
    this.api.preview(this.user_id).subscribe((data) => {
      this.userDetails = data["userDetails"];
      this.tran_for = data["tran_for"];
      this.marksheets = data["marksheets"];
      this.educations = data["educations"];
    });
  }

  async firstpayment() {
    this.payment_before_error = false;
    if (this.showNextButtonEdu == true && this.showNextButton == true|| this.consolidate.length > 0) {
      this.dialogService
        .open(PaymentModelComponent, {
          closeOnBackdropClick: false,
          context: {
            user_id: this.user_id,
            user_name: this.user_name,
            emailPayment: this.emailPayment,
            mobilePayment: this.mobilePayment,
            amountPayment: this.amountPayment,
          },
        })
        .onClose.subscribe((data: any) => {});
    } else {
      this.payment_before_error = true;
    }
  }

  onMenuItemSelected(event) {
    this.show_patteren = event;
    if (event == "Yearly") {
      this.fourthcheck = false;
      this.fifthcheck = false;
      this.sixthcheck = false;
      this.seventhcheck = false;
      this.eighttcheck = false;
      this.ninthcheck = false;
    } else if (event == "Semester") {
      this.firstcheck = false;
      this.secondcheck = false;
      this.thirdcheck = false;
    }
  }

  showOptionsOne(event, no) {
    if (no == "1") {
      this.firstcheck = event.checked;
      if(this.firstcheck == true){
        this.show_year_sem.push({
          no : '1',
          name : 'firstYear',
          id : null,
          file : null
        })
      }else{
        this.show_year_sem = this.show_year_sem.filter(array => array.name != 'firstYear');
      }
    } else if (no == "2") {
      this.secondcheck = event.checked;
      if(this.secondcheck == true){
        this.show_year_sem.push({
          no : '2',
          name : 'secondYear',
          id : null,
          file : null
        })
      }else{
        this.show_year_sem = this.show_year_sem.filter(array => array.name != 'secondYear');
      }
    } else if (no == "3") {
      this.thirdcheck = event.checked;
      if(this.thirdcheck == true){
        this.show_year_sem.push({
          no : '3',
          name : 'thirdYear',
          id : null,
          file : null
        })
      }else{
        this.show_year_sem = this.show_year_sem.filter(array => array.name != 'thirdYear');
      }
    } else if (no == "4") {
      this.fourthcheck = event.checked;
      if(this.fourthcheck == true){
        this.show_year_sem.push({
          no : '4',
          name : 'SemesterOne',
          id : null,
          file : null
        })
      }else{
        this.show_year_sem = this.show_year_sem.filter(array => array.name != 'SemesterOne');
      }
    } else if (no == "5") {
      this.fifthcheck = event.checked;
      if(this.fifthcheck == true){
        this.show_year_sem.push({
          no : '5',
          name : 'SemesterTwo',
          id : null,
          file : null
        })
      }else{
        this.show_year_sem = this.show_year_sem.filter(array => array.name != 'SemesterTwo');
      }
    } else if (no == "6") {
      this.sixthcheck = event.checked;
      if(this.sixthcheck == true){
        this.show_year_sem.push({
          no : '6',
          name : 'SemesterThree',
          id : null,
          file : null
        })
      }else{
        this.show_year_sem = this.show_year_sem.filter(array => array.name != 'SemesterThree');
      }
    } else if (no == "7") {
      this.seventhcheck = event.checked;
      if(this.seventhcheck == true){
        this.show_year_sem.push({
          no : '7',
          name : 'SemesterFour',
          id : null,
          file : null
        })
      }else{
        this.show_year_sem = this.show_year_sem.filter(array => array.name != 'SemesterFour');
      }
    } else if (no == "8") {
      this.eighttcheck = event.checked;
      if(this.eighttcheck == true){
        this.show_year_sem.push({
          no : '8',
          name : 'SemesterFive',
          id : null,
          file : null
        })
      }else{
        this.show_year_sem = this.show_year_sem.filter(array => array.name != 'SemesterFive');
      }
    } else if (no == "9") {
      this.ninthcheck = event.checked;
      if(this.ninthcheck == true){
        this.show_year_sem.push({
          no : '9',
          name : 'SemesterSix',
          id : null,
          file : null
        })
      }else{
        this.show_year_sem = this.show_year_sem.filter(array => array.name != 'SemesterSix');
      }
    }
  }

  // Ibad Script Start
  OnlyNoAllowed(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      
      return false;
    }
    return true;
  }
  //Ibad Script Ends
  // public findInvalidControls(controls) {
  // 	const invalid = [];

  // 	for (const name in controls) {
  // 		if (controls[name].invalid) {
  // 			invalid.push(name);
  // 		}
  // 	}
  // 	return invalid;
  // }

  onLevelSelected(event){
    this.firstForm.controls["educationCourseCtrl"].setValidators([]);
    this.firstForm.controls["educationCourseCtrl"].updateValueAndValidity();
    if(event != 'JC'){
      this.firstForm.controls["educationCourseCtrl"].setValidators([Validators.required,]);
      this.firstForm.controls["educationCourseCtrl"].updateValueAndValidity();
      this.api.getEduLevelCouses(event)
      .subscribe(
        (data: any) => { 
          if(data['status'] == 200){
            this.course_data = data['data'];
           
          }else{

          }
      });
    }else{
      this.firstForm.patchValue({
        educationCourseCtrl : 'Higher Secondary Certificate'
      })
    }
  }


  creditChange(values:any,semValue,index) {
    
    
    
    this.checked = values.currentTarget.checked;
    
    var semVal
    if(semValue == 'SemesterOne'){
      semVal = 'I'
    }else if(semValue == 'SemesterTwo'){
      semVal = 'II'
    }else if(semValue == 'SemesterThree'){
      semVal = "III"
    }else if(semValue == 'SemesterFour'){
      semVal = "IV"
    }else if(semValue == 'SemesterFive'){
      semVal = "V"
    }else if(semValue == 'SemesterSix'){
      semVal = "VI"
    }
    if(values.currentTarget.checked == true){
      //this.showHide = 'hide'
      this.indexVal = index
      this.confirmationService.confirm({
        message: 'Are You Sure?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {			
          this.api.changeCreditstatus(this.user_id,semVal,semValue).subscribe((data: any) => {
            // if(data['status'] == 200){

              this.api.getApplicantEducationalValues(this.user_id).subscribe((data) => {
                this.educatonalData = data["data"];
                if (data["data"].length == this.show_year_sem.length) {
                  this.setThirdFormValue(this.user_id);
                  this.showNextButtonEdu = true;
                } else {
                  this.showNextButtonEdu = false;
                }
              });
              this.api.getApplicantFiles(this.user_id).subscribe((data) => {
                this.applicantFile = data["data"];
                
                
                if (data["data"].length == this.show_year_sem.length ) {
                  this.showNextButton = true;
                } else {
                  this.showNextButton = false;
                }
              });
            // 
            

          })
          
              
            err => console.error(err);
          },
          
        
        reject: () => {
          
        }
      });
    }else{
      this.showHide = 'show'
      this.indexVal = 90
    }
    
  }
  
  // onCorseSelect(event){
  //   this.CourseShortForm = event;
  
  // }
  onUploadConsolidateMarksheet(event: any, consolidateMaksheet){
    const reader = new FileReader();
    var duration = 10000;
    this.index += 1;
    this.position = "top-right";
    this.status1 = "success";
    const config = {
      position: NbGlobalPhysicalPosition.BOTTOM_RIGHT,
    };
    //this.ngOnInit();
    if (event.files && event.files.length) {
      const [file] = event.files;
      reader.readAsDataURL(file);
      var json = JSON.parse(event.xhr.response);
      var yourData = json.data; // or json["Data"]
      var yourStatus = json.status; // or json["Data"]
      var yourMessage = json.message; // or json["Data"]

      if (yourStatus == 200) {
         this.preview();
        this.loadingdoc = false;
        // this.toasterService.show(yourMessage, "Success", config);
        this.api.getApplicantFilesOne(this.user_id).subscribe((data) => {
          this.consolidate = data['data'];
          // this.preview();
          
          
          
          
          // if (data["data"].length == this.show_year_sem.length) {
          //   this.showNextButton = true;
          // } else {
          //   this.showNextButton = false;
          // }
        });
      } else if (yourStatus == 401) {
        // this.loading = false;
        this.loadingdoc = false;
        this.toasterService.show(yourMessage, "Success", config);
      } else if (yourStatus == 400) {
        // this.loading = false;
        this.loadingdoc = false;
        this.toasterService.show(yourMessage, "Success", config);
      }
    }
}

  onSelectConsolidateMarksheet($event: any, value): void{
      var maxFileSize = 5000000;
      var imgArr = $event.files[0].name.split(".");
      var extension = imgArr[imgArr.length - 1].trim();
      this.loadingdoc = true;
      if ($event.files[0].size > maxFileSize) {
        this.confirmationService.confirm({
          message: "Maximum file size should be 5 MB.",
          header: "Invalid File Size",
          icon: "pi pi-info-circle",
          rejectVisible: false,
          acceptLabel: "Ok",
        });
        this.loadingdoc = false;
      }
  
      if (value != undefined && extension != "jpg" && extension != "jpeg" && extension != "png" && extension != "JPG" && extension != "JPEG" && extension != "PNG" && extension != "pdf" && extension != "Pdf" && extension != "docx" && extension != "DOCX" ) {
        this.confirmationService.confirm({
          message:
            "Please upload your transcript in .jpeg or .jpg or .png or .docx formats",
          header: "Invalid File Type",
          icon: "pi pi-info-circle",
          rejectVisible: false,
          acceptLabel: "Ok",
        });
        this.loadingdoc = false;
      }
  
      if (value == undefined && extension != "jpg" && extension != "jpeg" && extension != "png" && extension != "pdf" && extension != "JPG" && extension != "JPEG" && extension != "PNG" && extension != "PDF" && extension != "docx" && extension != "DOCX" ) {
        this.confirmationService.confirm({
          message:
            "Please upload your transcript in .jpeg or .jpg or .png or .pdf or .docx formats",
          header: "Invalid File Type",
          icon: "pi pi-info-circle",
          rejectVisible: false,
          acceptLabel: "Ok",
        });
        this.loadingdoc = false;
      }

  }

    onViewConsolidateMarksheet(imagename, imgType) {
      
      
      this.dialogService.open(previewImg, {
        context: {
          arr: imagename,
          //arr : 'https://mu.admissiondesk.org/uiserver/upload/transcript/20086/HywOaKlgI.pdf',
          imgType: imgType,
        },
      });
    }
  
    onDeleteConsolidateMarksheet(info){
      
      var duration = 10000;
      this.position = 'top-right';
      this.status = 'success';
      this.api.deleteApplicantFileConsolidate(info).subscribe(data => {
        
        var yourMessage = data['message']
        if(data['status'] == 200){
          // this.preview();
          // this.getApplicantEducationalValues();
          this.status = 'success';
          const config = {
            position: NbGlobalPhysicalPosition.TOP_RIGHT
          };
          this.toasterService.show(" "+yourMessage,`Success`,config)
          this.api.getApplicantFilesOne(this.user_id).subscribe(data => {
            this.consolidate = data['data']
            // this.lock_transcript = data['lock_transcript'];
            if (data["data"].length == this.show_year_sem.length){
              this.showNextButton = true
            }else{
              this.showNextButton = false
            }
          })
        }
      })
    }
}
