import { Component, OnInit } from '@angular/core';
import { ApiService } from './../shared/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { saveAs } from 'file-saver';
import { NbDialogService } from '@nebular/theme';
//import { FeedbackComponent } from './feedback.component';
import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';
import { FeedbackNewComponent } from './feedback.component';


@Component({
  selector: 'ngx-payment-success',
  template: `
  <div style="text-align:center;" class="col-xxxl-8 col-xxl-8 col-lg-12 col-md-8">
    <nb-card status="success">
      <nb-card-header style="text-align:center;">
        <h1>PAYMENT SUCCESSFUL</h1>
      </nb-card-header>
      <nb-card-body>
        <div  style="text-align:center;">
          <h2>
            Thank you! Your transaction is successful
          </h2>
        </div>
        <div  style="text-align:center;">
          <h2>
            Your transaction id is {{ transaction_id }}
          </h2>  
        </div>
        <div  style="text-align:center;">
          <h3>
            <a href="" ng-click="redirect()">Click here</a>
            To proceed further
          </h3>
        </div>
        <div  style="text-align:center;">
          <h3> 
            Payment Details :-
          </h3>
        </div>
        <div  style="text-align:center;">
          <h4>
            Application Id: {{ application_id }}
          </h4>
        </div>
        <div  style="text-align:center;">
          <h4>
            Transaction ID: {{ transaction_id }}
          </h4> 
        </div>
        <div  style="text-align:center;">
          <h4>
            Amount        : {{ payment_amount }}
          </h4>
        </div>
        <div  style="text-align:center;">
          <h4>
            Payment Status: {{ payment_status }}
          </h4>
        </div>
        <div  style="text-align:center;">
          <button nbButton status="primary" (click)="pdfChallan()">Download Online Payment Receipt</button>
        </div> 
      </nb-card-body>
    </nb-card>
  </div>
  `
})
export class PaymentSuccessComponent implements OnInit {
  transaction_id: any;
  application_id: any;
  payment_amount: any;
  payment_status: any;
  payment_date_time: any;
  user_id: any;
  feedback: any;

  constructor(protected api : ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: NbDialogService,
    private authService : NbAuthService,) {
      // this.authService.onTokenChange()
      //   .subscribe((token: NbAuthJWTToken) => {
      //     this.user_id = token.getPayload()['id'];
      // });
     }

  async ngOnInit() {
    var PaymentDetails = await this.api.PaymentDetails(this.route.snapshot.queryParamMap.get('order_id'))
    PaymentDetails.subscribe(
      data => {
        this.transaction_id = data['data']['transaction_id'];
        this.application_id = data['data']['application_id'];
        this.payment_amount = data['data']['payment_amount'];
        this.payment_status = data['data']['payment_status'];
        this.payment_date_time = data['data']['payment_date_time'];
        this.feedback = data['data']['feedback'];
        this.user_id = data['data']['user_id'];
        
        this.pdfChallan();
        this.feedbackfun();
      }
    )
  }

  feedbackfun(){
    //if(value == false){
      this.dialogService.open(FeedbackNewComponent, {
        closeOnBackdropClick : false,
        context: {
          user_id : this.user_id
        },
      }).onClose
      .subscribe(
        (data: any) => {
        }
      )
    //}
  }

  redirect(){
    
  }

  async pdfChallan(){
    var generatereceipt = await this.api.OnlinePaymentChallan(this.transaction_id,this.payment_amount,this.payment_status,this.application_id,this.payment_date_time,this.user_id);
    generatereceipt.subscribe(
      data => {
        var value = data['data'].split('/').pop();
        this.api.downloadFiles(value,this.user_id)
          .subscribe(data => {
            saveAs(data, value);
          });
      },
      error => {
          console.error("Error", error);
      }
    ); 
  }

  saveFeedBack(feedBack : string){
    this.api.saveFeedBack(feedBack,this.user_id).subscribe(feedbackres => {
      
      if(feedbackres['status'] == 200){
       // this.toasterService.show('FeedBack save successfully','success');\
       alert('FeedBack save successfully')
      }else{
        alert('FeedBack not save')
        //this.toasterService.show('FeedBack not save','success');
      }
    })
  }

}
