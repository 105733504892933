import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentSuccessComponent } from './payment-success.component';
import { NbCardModule , NbButtonModule } from '@nebular/theme';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [PaymentSuccessComponent],
  imports: [
    CommonModule,
    NbCardModule,
    NbButtonModule,
    FormsModule
  ]
})
export class PaymentSuccessModule { }
