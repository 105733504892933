import { Component, OnInit, Input } from "@angular/core";
import { ApiService } from "./../../shared/api.service";
import { Router, ActivatedRoute } from "@angular/router";
import { saveAs } from "file-saver";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { NbDialogRef } from "@nebular/theme";
import { UserService } from "./../../@core/data/users.service";
import { ConfirmationService } from "primeng/api";

@Component({
  selector: "educational",
  template: `
    <style>
      .example-full-width {
        width: 106% !important;
      }

      .mat-form-field--inline {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .mat-form-field--inline .mat-form-field {
        display: inline-block;
        width: 100%;
        vertical-align: middle;
      }

      .mat-form-field--inline .mat-form-field:nth-child(1) {
        margin-right: 10px;
      }

      @media (max-width: 767px) {
        #selectLine {
          width: 65%;
        }
      }

      @media (min-width: 1200px) {
        #selectLine {
          width: 145%;
        }

        #subCss {
          width: 45%;
        }
      }

      @media (min-width: 768px) {
        #selectLine {
          width: 65%;
        }
      }

      @media (min-width: 992px) {
        #selectLine {
          width: 145%;
        }
        #subCss {
          width: 50%;
        }
      }
    </style>
    <nb-card status="success" [style.height.px]="630" [style.overflow]="'auto'">
      <nb-card-header>Add Educational Details</nb-card-header>
      <nb-card-body>
        <span *ngIf="instructionValidation === false" style="color:red;"
          >Please add medium of instruction which is below subjects.</span
        >
        <form [formGroup]="edu_form" class="step-container">
          <div class="row">
            <h5>{{ autoName }}</h5>
          </div>
          <div class="row">
            <div class="col-md-2">Course Name :</div>
            <div class="col-md-2">
              <!--<input  ngModel="{{edu_data?.CourseName}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.courseNameCtrl.invalid && (edu_form.controls.courseNameCtrl.dirty || edu_form.controls.courseNameCtrl.touched)}" formControlName="courseNameCtrl" placeholder="" id="" class="form-control">-->
              <mat-form-field id="selectLine">
                <mat-select
                  ngModel="{{ course_name }}"
                  name="coursename"
                  formControlName="courseNameCtrl"
                  disabled
                >
                  <mat-option
                    value="{{ course.CourseShortForm }}"
                    *ngFor="let course of course_data"
                    >{{ course.CourseName }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <span *ngIf="courseValidation === false" style="color:red;"
                >Please select College Year.</span
              >
            </div>
            <div class="col-md-2" style="padding-left: 9%;">
              Class Obtained :
            </div>
            <div class="col-md-2" style="padding-left: 5%;">
              <mat-form-field id="selectLine">
                <mat-select
                  ngModel="{{ edu_data?.overall_class }}"
                  name="overall_class"
                  formControlName="overallclassCtrl"
                >
                  <mat-option value="First Class">First</mat-option>
                  <mat-option value="Second Class">Second</mat-option>
                  <mat-option value="Third Class">Third</mat-option>
                  <mat-option value="Pass">Pass</mat-option>
                  <mat-option value="Passes">Passes</mat-option>
                  <mat-option value="Distinction">Distinction</mat-option>
                  <mat-option value="First Class with Distinction"
                    >First Class with Distinction</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <span *ngIf="classValidation === false" style="color:red;"
                >Please select class</span
              >
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">College Year :</div>
            <div class="col-md-2">
              <mat-form-field id="selectLine">
                <mat-select
                  ngModel="{{ college_year }}"
                  name="collegeYear"
                  formControlName="collegeYearCtrl"
                  disabled
                >
                  <mat-option
                    *ngIf="
                      edu_level == 'Degree' ||
                      edu_level == 'Master' ||
                      edu_level == 'Bachelor'
                    "
                    value="First"
                    >First</mat-option
                  >
                  <mat-option
                    *ngIf="
                      edu_level == 'Degree' ||
                      edu_level == 'Master' ||
                      edu_level == 'Bachelor'
                    "
                    value="Second"
                    >Second</mat-option
                  >
                  <mat-option
                    *ngIf="edu_level == 'Degree' || edu_level == 'Bachelor'"
                    value="Third"
                    >Third</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <span *ngIf="collegeYearValidation === false" style="color:red;"
                >Please select College Year.</span
              >
            </div>
            <div class="col-md-2" style="padding-left: 9%;">Semester :</div>
            <div class="col-md-2" style="padding-left: 5%;">
              <mat-form-field id="selectLine">
                <mat-select
                  ngModel="{{ semester_auto }}"
                  name="semester"
                  formControlName="semesterCtrl"
                >
                  <mat-option
                    *ngIf="
                      edu_form.controls['collegeYearCtrl'].value === 'First'
                    "
                    value="I"
                    >I</mat-option
                  >
                  <mat-option
                    *ngIf="
                      edu_form.controls['collegeYearCtrl'].value === 'First'
                    "
                    value="II"
                    >II</mat-option
                  >
                  <mat-option
                    *ngIf="
                      edu_form.controls['collegeYearCtrl'].value === 'Second'
                    "
                    value="III"
                    >III</mat-option
                  >
                  <mat-option
                    *ngIf="
                      edu_form.controls['collegeYearCtrl'].value === 'Second'
                    "
                    value="IV"
                    >IV</mat-option
                  >
                  <mat-option
                    *ngIf="
                      edu_form.controls['collegeYearCtrl'].value === 'Third'
                    "
                    value="V"
                    >V</mat-option
                  >
                  <mat-option
                    *ngIf="
                      edu_form.controls['collegeYearCtrl'].value === 'Third'
                    "
                    value="VI"
                    >VI</mat-option
                  >
                  <mat-option value="Consolidated">Consolidated</mat-option>
                </mat-select>
              </mat-form-field>
              <span *ngIf="semesterValidation === false" style="color:red;"
                >Please select semester</span
              >
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">Month :</div>
            <div class="col-md-2">
              <mat-form-field id="selectLine">
                <mat-select
                  ngModel="{{ edu_data?.Month }}"
                  name="month"
                  formControlName="monthCtrl"
                >
                  <mat-option value="January">January</mat-option>
                  <mat-option value="February">February</mat-option>
                  <mat-option value="March">March</mat-option>
                  <mat-option value="April">April</mat-option>
                  <mat-option value="May">May</mat-option>
                  <mat-option value="June">June</mat-option>
                  <mat-option value="July">July</mat-option>
                  <mat-option value="August">August</mat-option>
                  <mat-option value="September">September</mat-option>
                  <mat-option value="October">October</mat-option>
                  <mat-option value="November">November</mat-option>
                  <mat-option value="December">December</mat-option>
                </mat-select>
              </mat-form-field>
              <span *ngIf="monthValidation === false" style="color:red;"
                >Please select month</span
              >
            </div>
            <div class="col-md-2" style="padding-left: 9%;">Year :</div>
            <div class="col-md-2" style="padding-left: 5%;">
              <mat-form-field id="selectLine">
                <mat-select
                  ngModel="{{ edu_data?.Year }}"
                  name="year"
                  formControlName="yearCtrl"
                >
                  <mat-option
                    value="{{ years }}"
                    *ngFor="let years of years_array"
                    >{{ years }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <span *ngIf="yearValidation === false" style="color:red;"
                >Please select year.</span
              >
            </div>
          </div>
          <div class="row" *ngIf="showSpecialization == 'show'">
            <div class="col-md-3">Specialization in :</div>
            <div class="col-md-2">
              <input
                ngModel="{{ edu_data?.specialization }}"
                nbInput
                type="text"
                formControlName="specializationCtrl"
                placeholder="eg. Geology"
                id=""
                class="form-control"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-1">Course Code</div>
            <div class="col-md-2" style="padding-left: 150px;">
              <label>Subject Name</label>
            </div>
            <div class="col-md-1" style="padding-left: 98px;">
              <label>Credits Earned</label>
            </div>
            <div class="col-md-1" style="padding-left: 105px;">
              <label>Grade Point</label>
            </div>
            <div
              class="col-md-1"
              style="padding-left: 149px;"
              *ngIf="admin_show != 'true'"
            >
              <label>Grade</label>
            </div>
            <div
              class="col-md-1"
              style="margin-left:80px;"
              *ngIf="admin_show == 'true'"
            >
              <label>Grade</label>
            </div>
            <div class="col-md-1" style="margin-left:5px;">
              <label>C * G</label>
            </div>
            <div class="col-md-1" style="padding-left: 0px;">
              <label>No. of lec per week</label>
            </div>
            <div
              class="col-md-1"
              style="padding-left: 0px;"
              *ngIf="
                edu_form.controls['courseNameCtrl'].value == 'B.Sc.' ||
                edu_form.controls['courseNameCtrl'].value == 'M.Sc.' ||
                edu_form.controls['courseNameCtrl'].value ==  'BVOC'
              "
            >
              <label>No. of hrs/week in practs</label>
            </div>       </div>

          <div class="row">
            <div class="col-md-1">Subject 1:</div>
            <!-- <div class="col-md-1"> -->
            <!-- <textarea  ngModel="{{edu_data?.Subject_First}}" [value]="filterText" [matAutocomplete]="phdauto" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.subjectFirstCtrl.invalid && (edu_form.controls.subjectFirstCtrl.dirty || edu_form.controls.subjectFirstCtrl.touched)}" formControlName="subjectFirstCtrl" placeholder="name" id=""  class="form-control">
            <mat-autocomplete #phdauto="matAutocomplete">
                <mat-option *ngFor="let college of course_code | filter: {Course_Subject_name : filterText}" [value]="college.Course_Subject_name" (click)="getCourse(college.Course_Subject_name)">
                  {{ college.Course_Subject_name }}
                </mat-option>
              </mat-autocomplete>
            </textarea> -->

            <!-- <mat-form-field class="example-full-width" appearance="outline">
                              <textarea matInput  type="text" [value]="filterText"
                              [formControl]="filterInput"   [matAutocomplete]="phdauto" [matAutocompleteDisabled]="autocheck" (change)='getSubject($event)' ></textarea>
              </mat-form-field>  -->

            <!-- <mat-form-field class="example-full-width" appearance="outline">
                <textarea matInput  type="text" [value]="filterText2 ? filterText2 : edu_data?.course_code_one" 
                [formControl]="filterInput2" 
                [matAutocomplete]="phdauto2" [matAutocompleteDisabled]="autocheck" (change)='getSubject($event)' ></textarea>
              </mat-form-field> 

            <mat-autocomplete #phdauto2="matAutocomplete"  style="width:200% !important;  ">
                <mat-option style="width:800% !important;  " *ngFor="let college2 of course_code2 | filter: {Course_Code : filterText2}" [value]="college2.Course_Code" (click)="getCourse(college2)">
                    {{ college2.Course_Code }}
                </mat-option>
            </mat-autocomplete>
           
            </div> -->
            <div class="col-md-1">
              <div
                class="row"
                *ngIf="
                  edu_data?.course_code_one != null &&
                  edu_data?.course_code_one != '' &&
                  edu_data?.course_code_one != undefined &&
                  showcodeDropdown == false
                "
              >
                <input
                  disabled
                  style="width: 150px;"
                  [value]="edu_data?.course_code_one"
                  nbInput
                  type="text"
                  id=""
                />
                <i
                  class="fas fa-pen-alt fa-2x"
                  (click)="showcoursecodeDropdown()"
                ></i>
              </div>

              <mat-form-field
                [formGroup]="formData"
                *ngIf="showcodeDropdown == true"
                style="width:100px;"
              >
              <!-- <mat-select
              placeholder="Course Code"
               formControlName="valueSelect"
              #singleSelect
            >-->
            <mat-select
              placeholder="Course Code"
              #singleSelect
            >
                  <mat-option>
                    <ngx-mat-select-search
                      [formControl]="filterInput2"
                      [placeholderLabel]="'Course Code...'"
                    >
                      <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="
                      let college2 of course_code2
                        | filter: { Course_Code: filterText2 }
                    "
                    [value]="college2"
                    (click)="getCourse(college2)"
                  >
                    {{ college2.Course_Code }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <!-- <div class="col-md-1"> -->
              <!-- <div class="row">
                 Can't Find ? &nbsp;
                 <i
                   class="fas fa-phone-square fa-lg"
                   title="Contact"
                   style="color: green;"
                   (click)="showContactDetails()"
                 ></i>
               </div>-->

              <!-- <i class="fas fa-pen-alt fa-2x" (click)="showcoursecodeDropdown()"></i> -->
              <!-- <i class="fas fa-phone-square-alt"></i> -->
              <!-- </div> -->

              <!-- <textarea  ngModel="{{edu_data?.Subject_First}}" [value]="filterText" [matAutocomplete]="phdauto" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.subjectFirstCtrl.invalid && (edu_form.controls.subjectFirstCtrl.dirty || edu_form.controls.subjectFirstCtrl.touched)}" formControlName="subjectFirstCtrl" placeholder="name" id=""  class="form-control">
          <mat-autocomplete #phdauto="matAutocomplete">
              <mat-option *ngFor="let college of course_code | filter: {Course_Subject_name : filterText}" [value]="college.Course_Subject_name" (click)="getCourse(college.Course_Subject_name)">
                {{ college.Course_Subject_name }}
              </mat-option>
            </mat-autocomplete>
          </textarea> -->

              <!-- <mat-form-field class="example-full-width" appearance="outline">
                            <textarea matInput  type="text" [value]="filterText"
                            [formControl]="filterInput"   [matAutocomplete]="phdauto" [matAutocompleteDisabled]="autocheck" (change)='getSubject($event)' ></textarea>
            </mat-form-field>  -->

              <!-- <mat-form-field class="example-full-width" appearance="outline">
              <textarea matInput  type="text" [value]="filterText2 ? filterText2 : edu_data?.course_code_one" 
              [formControl]="filterInput2" 
              [matAutocomplete]="phdauto2" [matAutocompleteDisabled]="autocheck" (change)='getSubject($event)' ></textarea>
            </mat-form-field> 
  
          <mat-autocomplete #phdauto2="matAutocomplete"  style="width:200% !important;  ">
              <mat-option style="width:800% !important;  " *ngFor="let college2 of course_code2 | filter: {Course_Code : filterText2}" [value]="college2.Course_Code" (click)="getCourse(college2)">
                  {{ college2.Course_Code }}
              </mat-option>
          </mat-autocomplete> -->
            </div>
            <div class="col-md-2">
            <mat-form-field style="width: 210px; margin-left: 50px;">
                <mat-select
                  ngModel="{{ edu_data?.Subject_First }}"
                  name="semester"
                  formControlName="subjectFirstCtrl"
                  [ngClass]="{
                    'form-control-danger':
                      edu_form.controls.subjectFirstCtrl.invalid &&
                      (edu_form.controls.subjectFirstCtrl.dirty ||
                        edu_form.controls.subjectFirstCtrl.touched)
                  }"
                >
                  <mat-option
                    *ngFor="let course of subjectArray"
                    value="{{ course.Course_Subject_name }}"
                    >{{ course.Course_Subject_name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <!-- <textarea  ngModel=" {{subjectName ? subjectName.Course_Subject_name : (edu_data ? edu_data.Subject_First : '')}} " nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.subjectFirstCtrl.invalid && (edu_form.controls.subjectFirstCtrl.dirty || edu_form.controls.subjectFirstCtrl.touched)}" formControlName="subjectFirstCtrl" placeholder="name" id="" class="form-control" disabled></textarea> -->
            </div>
            <div class="col-md-1">
              <input
                ngModel="{{ edu_data?.marks_obt_First }}"
                nbInput
                style="margin-left: 68px;"
                type="text"
                [ngClass]="{
                  'form-control-danger':
                    edu_form.controls.markFirstCtrl.invalid &&
                    (edu_form.controls.markFirstCtrl.dirty ||
                      edu_form.controls.markFirstCtrl.touched)
                }"
                formControlName="markFirstCtrl"
                style="margin-left: 68px;"
                placeholder="Credits Earned"
                (change)="getTotalCredits()"
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1">
              <input
                ngModel="{{ edu_data?.marks_out_First }}"
                nbInput
                style="margin-left: 70px;"
                type="text"
                [ngClass]="{
                  'form-control-danger':
                    edu_form.controls.markFirstOutCtrl.invalid &&
                    (edu_form.controls.markFirstOutCtrl.dirty ||
                      edu_form.controls.markFirstOutCtrl.touched)
                }"
                formControlName="markFirstOutCtrl"
                placeholder="Grade Point"
                (change)="getcXg()"
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1" *ngIf="admin_show != 'true'">
              <input
                 ngModel="{{ edu_data?.grade_First }}"
                nbInput
                type="text"
                [ngClass]="{
                  'form-control-danger':
                    edu_form.controls.gradeFirstCtrl.invalid &&
                    (edu_form.controls.gradeFirstCtrl.dirty ||
                      edu_form.controls.gradeFirstCtrl.touched)
                }"
                formControlName="gradeFirstCtrl"
                style="text-transform: uppercase; margin-left: 70px;"
                placeholder="Grade"
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1" *ngIf="admin_show == 'true'">
              <input
                ngModel="{{ edu_data?.grade_First }}"
                nbInput
                type="text"
                [ngClass]="{
                  'form-control-danger':
                    edu_form.controls.gradeFirstCtrl.invalid &&
                    (edu_form.controls.gradeFirstCtrl.dirty ||
                      edu_form.controls.gradeFirstCtrl.touched)
                }"
                formControlName="gradeFirstCtrl"
                style="text-transform: uppercase; margin-left: 70px;"
                placeholder="Grade"
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1">
              <input
                ngModel="{{
                  getcxg1 ? getcxg1 : edu_data?.grade_credits_First
                }}"
                nbInput
                type="text"
                [ngClass]="{
                  'form-control-danger':
                    edu_form.controls.gradecreditsFirstCtrl.invalid &&
                    (edu_form.controls.gradecreditsFirstCtrl.dirty ||
                      edu_form.controls.gradecreditsFirstCtrl.touched)
                }"
                formControlName="gradecreditsFirstCtrl"
                placeholder="C * G"
                (change)="getTotalCnG()"
                style="margin-left: 70px; "
                id=""
                class="form-control"
              />
            </div>

            <div class="col-md-1">
              <input
                [attr.disabled]="isDisabled ? '' : null"
                ngModel=" {{
                  lec_per_week1
                    ? lec_per_week1
                    : edu_data
                    ? edu_data.lecture_hour_First
                    : ''
                }}"
                nbInput
                type="text"
                formControlName="lecturehourFirstCtrl"
                placeholder="No. of lec per week"
                style="margin-left: 70px; "
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1" *ngIf="admin_show == 'true'">
              <i
                title="Copy"
                class="fa fa-clone"
                style="text-align: right; padding-left: 72% !important;"
                aria-hidden="true"
                fa-lg
                (click)="copyLecValue()"
              ></i>
            </div>

            <div class="col-md-1">
              <input
                [attr.disabled]="isDisabled ? '' : null"
                *ngIf="
                  edu_form.controls['courseNameCtrl'].value == 'B.Sc.' ||
                  edu_form.controls['courseNameCtrl'].value == 'M.Sc.' ||
                  edu_form.controls['courseNameCtrl'].value ==  'BVOC'
                "
                style="margin-left: 70px; "
                [ngModel]="
                  prac_hour1 ? prac_hour1 : edu_data?.practical_hour_First
                "
                nbInput
                type="text"
                formControlName="practicalhourFirstCtrl"
                placeholder="No. of hrs/week in practs"
                id=""
                class="form-control"
              />
            </div>
            <div
              class="col-md-1"
              *ngIf="
                (admin_show == 'true' &&
                  edu_form.controls['courseNameCtrl'].value == 'B.Sc.') ||
                edu_form.controls['courseNameCtrl'].value == 'M.Sc.' ||
                edu_form.controls['courseNameCtrl'].value ==  'BVOC'
              "
            >
              <i
                title="Copy"
                class="fa fa-clone"
                style="text-align: right; margin-left: 70px;"
                aria-hidden="true"
                fa-lg
                (click)="copyHoursValue()"
              ></i>
            </div>
          </div>
          <div class="row">
            <div class="col-md-1">Subject 2:</div>
            <!-- <div class="col-md-1"> -->
            <!-- <textarea     ngModel="{{edu_data?.Subject_Second}}" nbInput type="text" formControlName="subjectSecondCtrl" placeholder="name" id="" class="form-control">
                 
            </textarea> -->
            <!-- [value]="filterText2" -->
            <!-- [formControl]="filterInput2"   -->

            <!-- <mat-form-field class="example-full-width" appearance="outline">
                <textarea matInput  type="text"   [value]="filterText ? filterText : edu_data?.course_code_two"
                  [matAutocomplete]="phdauto" [formControl]="filterInput" [matAutocompleteDisabled]="autocheck" (change)='getSubject2($event)' ></textarea>
            </mat-form-field> 
              
              <mat-autocomplete #phdauto="matAutocomplete"  style="width:200% !important;  ">
                <mat-option style="width:800% !important;  " *ngFor="let college of course_code | filter: {Course_Code : filterText}" [value]="college.Course_Code" (click)="getCourse2(college)">
                  {{ college.Course_Code }}
                </mat-option>
              </mat-autocomplete>
          </div> -->
            <div class="col-md-1">
              <div class="row" *ngIf="showcodeDropdown2 == false">
                <input
                  disabled
                  [value]="edu_data?.course_code_two"
                  nbInput
                  style="width: 150px;"
                  type="text"
                  id=""
                />
                <i
                  class="fas fa-pen-alt fa-2x"
                  (click)="showcoursecodeDropdown2()"
                ></i>
              </div>

              <mat-form-field
                [formGroup]="formData"
                *ngIf="showcodeDropdown2 == true"
                style="width:100px;"
              >
                <mat-select
                  placeholder="Course Code"
                  #singleSelect2
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [formControl]="filterInput"
                      [placeholderLabel]="'Course Code...'"
                    >
                      <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="
                      let college of course_code
                        | filter: { Course_Code: filterText }
                    "
                    [value]="college"
                    (click)="getCourse2(college)"
                  >
                    {{ college.Course_Code }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <!--<div class="row">
                Can't Find ? &nbsp;
                <i
                  class="fas fa-phone-square fa-lg"
                  title="Contact"
                  style="color: green;"
                  (click)="showContactDetails()"
                ></i>
              </div>-->
            </div>
            <div class="col-md-2">
              <mat-form-field style="width: 210px; margin-left: 50px;">
                <mat-select
                  ngModel="{{ edu_data?.Subject_Second }}"
                  name="semester"
                  formControlName="subjectSecondCtrl"
                  [ngClass]="{
                    'form-control-danger':
                      edu_form.controls.subjectSecondCtrl.invalid &&
                      (edu_form.controls.subjectSecondCtrl.dirty ||
                        edu_form.controls.subjectSecondCtrl.touched)
                  }"
                >
                  <mat-option
                    *ngFor="let course of subjectArray2"
                    value="{{ course.Course_Subject_name }}"
                    >{{ course.Course_Subject_name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <!-- <textarea  ngModel="{{subjectName2 ? subjectName2.Course_Subject_name : (edu_data ? edu_data?.Subject_Second : '')}}" nbInput type="text"  formControlName="subjectSecondCtrl" placeholder="name" id="" class="form-control" disabled></textarea> -->
            </div>
            <div class="col-md-1">
              <input
                ngModel="{{ edu_data?.marks_obt_Second }}"
                nbInput
                type="text"
                style="margin-left: 68px;"
                formControlName="markSecondCtrl"
                placeholder="Credits Earned"
                (change)="getTotalCredits()"
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1">
              <input
                ngModel="{{ edu_data?.marks_out_Second }}"
                nbInput
                type="text"
                formControlName="markSecondOutCtrl"
                style="text-transform: uppercase;margin-left: 70px; "
                placeholder="Grade Point"
                (change)="getcXg()"
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1" *ngIf="admin_show != 'true'">
              <input
               ngModel="{{ edu_data?.grade_Second }}"
                style="text-transform: uppercase;margin-left: 70px; "
                nbInput
                type="text"
                formControlName="gradeSecondCtrl"
                placeholder="Grade"
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1" *ngIf="admin_show == 'true'">
              <input
              style="text-transform: uppercase;margin-left: 70px; "
                ngModel="{{ edu_data?.grade_Second }}"
                nbInput
                type="text"
                formControlName="gradeSecondCtrl"
                placeholder="Grade"
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1">
              <input
                ngModel="{{
                  getcxg2 ? getcxg2 : edu_data?.grade_credits_Second
                }}"
                nbInput
                type="text"
                formControlName="gradecreditsSecondCtrl"
                (change)="getTotalCnG()"
                placeholder="C * G"
                style="margin-left: 70px; " 
                id=""
                class="form-control"
              />
            </div>

            <div class="col-md-1">
              <input
                [attr.disabled]="isDisabled ? '' : null"
                ngModel="{{
                  lec_per_week2
                    ? lec_per_week2
                    : edu_data
                    ? edu_data.lecture_hour_Second
                    : ''
                }}"
                nbInput
                type="text"
                formControlName="lecturehourSecondCtrl"
                placeholder="No. of lec per week"
                style="margin-left: 70px; "
                id=""
                class="form-control"
              />
            </div>
            
            <div class="col-md-1">
              <input
                [attr.disabled]="isDisabled ? '' : null"
                *ngIf="
                  edu_form.controls['courseNameCtrl'].value == 'B.Sc.' ||
                  edu_form.controls['courseNameCtrl'].value == 'M.Sc.' ||
                  edu_form.controls['courseNameCtrl'].value ==  'BVOC'
                "
                ngModel="{{
                  prac_hour2
                    ? prac_hour2
                    : edu_data
                    ? edu_data.practical_hour_Second
                    : ''
                }} "
                nbInput
                type="text"
                formControlName="practicalhourSecondCtrl"
                placeholder="No. of hrs/week in practs"
                style="margin-left: 70px; "
                id=""
                class="form-control"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-1">Subject 3 :</div>
            <!-- <div class="col-md-1">
            <mat-form-field class="example-full-width" appearance="outline">
                <textarea matInput  type="text" [value]="filterText3 ? filterText3 : edu_data?.course_code_third"
                [formControl]="filterInput3"   [matAutocomplete]="phdauto3" [matAutocompleteDisabled]="autocheck" (change)='getSubject3($event)' ></textarea>
            </mat-form-field> 

            <mat-autocomplete #phdauto3="matAutocomplete"  style="width:200% !important;  ">
                <mat-option style="width:800% !important;  " *ngFor="let college of course_code3 | filter: {Course_Code : filterText3}" [value]="college.Course_Code" (click)="getCourse3(college)">
                    {{ college.Course_Code }}
                </mat-option>
            </mat-autocomplete>
              <textarea  ngModel="{{edu_data?.Subject_Third}}" nbInput type="text"  formControlName="subjectThirdCtrl" placeholder="name" id="" class="form-control"></textarea> 
          </div> -->
            <div class="col-md-1">
              <div
                class="row"
                *ngIf="
                  edu_data?.course_code_third != null &&
                  edu_data?.course_code_third != '' &&
                  edu_data?.course_code_third != undefined &&
                  showcodeDropdown3 == false
                "
              >
                <input
                  disabled
                  
                  [value]="edu_data?.course_code_third"
                  style="width: 150px;"
                  nbInput
                  type="text"
                  id=""
                />
                <i
                  class="fas fa-pen-alt fa-2x"
                  (click)="showcoursecodeDropdown3()"
                ></i>
              </div>

              <mat-form-field
                [formGroup]="formData"
                *ngIf="showcodeDropdown3 == true"
                style="width:100px;"
              >
                <mat-select
                  placeholder="Course Code"
                  #singleSelect2
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [formControl]="filterInput3"
                      [placeholderLabel]="'Course Code...'"
                    >
                      <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="
                      let college of course_code3
                        | filter: { Course_Code: filterText3 }
                    "
                    [value]="college"
                    (click)="getCourse3(college)"
                  >
                    {{ college.Course_Code }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <!-- <div class="row">
                Can't Find ? &nbsp;
                <i
                  class="fas fa-phone-square fa-lg"
                  title="Contact"
                  style="color: green;"
                  (click)="showContactDetails()"
                ></i>
              </div>-->
            </div>
            <div class="col-md-2">
            <mat-form-field style="width: 210px; margin-left: 50px;">
                <mat-select
                  ngModel="{{ edu_data?.Subject_Third }}"
                  name="semester"
                  formControlName="subjectThirdCtrl"
                  [ngClass]="{
                    'form-control-danger':
                      edu_form.controls.subjectThirdCtrl.invalid &&
                      (edu_form.controls.subjectThirdCtrl.dirty ||
                        edu_form.controls.subjectThirdCtrl.touched)
                  }"
                >
                  <mat-option
                    *ngFor="let course of subjectArray3"
                    value="{{ course.Course_Subject_name }}"
                    >{{ course.Course_Subject_name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <!-- <textarea  ngModel="{{subjectName3 ? subjectName3.Course_Subject_name : (edu_data ? edu_data?.Subject_Third : '')}}" nbInput type="text"  formControlName="subjectThirdCtrl" placeholder="name" id="" class="form-control" disabled></textarea> -->
            </div>
            <div class="col-md-1">
              <input
                ngModel="{{ edu_data?.marks_obt_Third }}"
                nbInput
                type="text"
                style="margin-left: 68px;"
                formControlName="markThirdCtrl"
                placeholder="Credits Earned"
                (change)="getTotalCredits()"
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1">
              <input
                ngModel="{{ edu_data?.marks_out_Third }}"
                nbInput
                type="text"
                style="margin-left: 70px;"
                formControlName="markThirdOutCtrl"
                placeholder="Grade Point"
                id=""
                (change)="getcXg()"
                class="form-control"
              />
            </div>
            <div class="col-md-1" *ngIf="admin_show != 'true'">
              <input
                ngModel="{{ edu_data?.grade_Third }}"
                nbInput
                type="text"
                formControlName="gradeThirdCtrl"
                style="text-transform: uppercase;margin-left: 70px; "
                placeholder="Grade"
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1" *ngIf="admin_show == 'true'">
              <input
                ngModel="{{ edu_data?.grade_Third }}"
                nbInput
                type="text"
                formControlName="gradeThirdCtrl"
                style="text-transform: uppercase;margin-left: 70px; "
                placeholder="Grade"
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1">
              <input
                ngModel="{{
                  getcxg3 ? getcxg3 : edu_data?.grade_credits_Third
                }}"
                nbInput
                type="text"
                formControlName="gradecreditsThirdCtrl"
                placeholder="C * G"
                style="margin-left: 70px; "
                (change)="getTotalCnG()"
                id=""
                class="form-control"
              />
            </div>

            <div class="col-md-1">
              <input
                [attr.disabled]="isDisabled ? '' : null"
                ngModel="{{
                  lec_per_week3
                    ? lec_per_week3
                    : edu_data
                    ? edu_data.lecture_hour_Third
                    : ''
                }}"
                nbInput
                type="text"
                formControlName="lecturehourThirdCtrl"
                placeholder="No. of lec per week"
                style="margin-left: 70px; "
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1">
              <input
                *ngIf="
                  edu_form.controls['courseNameCtrl'].value == 'B.Sc.' ||
                  edu_form.controls['courseNameCtrl'].value == 'M.Sc.' ||
                  edu_form.controls['courseNameCtrl'].value ==  'BVOC'
                "
                [attr.disabled]="isDisabled ? '' : null"
                ngModel="{{
                  prac_hour3
                    ? prac_hour3
                    : edu_data
                    ? edu_data.practical_hour_Third
                    : ''
                }} "
                nbInput
                type="text"
                formControlName="practicalhourThirdCtrl"
                placeholder="No. of hrs/week in practs"
                style="margin-left: 70px; "
                id=""
                class="form-control"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-1">Subject 4 :</div>

            <!-- <div class="col-md-1">
            <mat-form-field class="example-full-width" appearance="outline">
              <textarea matInput  type="text" [value]="filterText4 ? filterText4 : edu_data.course_code_Fourth"
              [formControl]="filterInput4"   [matAutocomplete]="phdauto4" [matAutocompleteDisabled]="autocheck" (change)='getSubject4($event)' ></textarea>
            </mat-form-field> 

            <mat-autocomplete #phdauto4="matAutocomplete"  style="width:200% !important;  ">
                <mat-option style="width:800% !important;  " *ngFor="let college of course_code4 | filter: {Course_Code : filterText4}" [value]="college.Course_Code" (click)="getCourse4(college)">
                    {{ college.Course_Code }}
                </mat-option>
            </mat-autocomplete>
             <textarea  ngModel="{{edu_data?.Subject_Fourth}}" nbInput type="text"  formControlName="subjectFourthCtrl" placeholder="name" id="" class="form-control"></textarea> 
          </div> -->
            <div class="col-md-1">
              <div
                class="row"
                *ngIf="
                  edu_data?.course_code_Fourth != null &&
                  edu_data?.course_code_Fourth != '' &&
                  edu_data?.course_code_Fourth != undefined &&
                  showcodeDropdown4 == false
                "
              >
                <input
                  disabled
                  [value]="edu_data?.course_code_Fourth"
                  style="width: 150px;"
                  nbInput
                  type="text"
                  id=""
                />
                <i
                  class="fas fa-pen-alt fa-2x"
                  (click)="showcoursecodeDropdown4()"
                ></i>
              </div>

              <mat-form-field
                [formGroup]="formData"
                *ngIf="showcodeDropdown4 == true"
                style="width:100px;"
              >
                <mat-select
                  placeholder="Course Code"
                  #singleSelect2
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [formControl]="filterInput4"
                      [placeholderLabel]="'Course Code...'"
                    >
                      <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="
                      let college of course_code4
                        | filter: { Course_Code: filterText4 }
                    "
                    [value]="college"
                    (click)="getCourse4(college)"
                  >
                    {{ college.Course_Code }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <!-- <div class="row">
                Can't Find ? &nbsp;
                <i
                  class="fas fa-phone-square fa-lg"
                  title="Contact"
                  style="color: green;"
                  (click)="showContactDetails()"
                ></i>
              </div>-->
            </div>
            <div class="col-md-2">
            <mat-form-field style="width: 210px; margin-left: 50px;">
                <mat-select
                  ngModel="{{ edu_data?.Subject_Fourth }}"
                  name="semester"
                  formControlName="subjectFourthCtrl"
                  [ngClass]="{
                    'form-control-danger':
                      edu_form.controls.subjectFourthCtrl.invalid &&
                      (edu_form.controls.subjectFourthCtrl.dirty ||
                        edu_form.controls.subjectFourthCtrl.touched)
                  }"
                >
                  <mat-option
                    *ngFor="let course of subjectArray4"
                    value="{{ course.Course_Subject_name }}"
                    >{{ course.Course_Subject_name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <!-- <textarea  ngModel="{{subjectName4 ? subjectName4.Course_Subject_name : (edu_data ? edu_data?.Subject_Fourth : '')}}" nbInput type="text" formControlName="subjectFourthCtrl" placeholder="name" id="" class="form-control" disabled></textarea> -->
            </div>
            <div class="col-md-1">
              <input
                ngModel="{{ edu_data?.marks_obt_Fourth }}"
                nbInput
                style="margin-left: 68px;"
                type="text"
                formControlName="markFourthCtrl"
                placeholder="Credits Earned"
                (change)="getTotalCredits()"
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1">
              <input
                ngModel="{{ edu_data?.marks_out_Fourth }}"
                nbInput
                type="text"
                style="margin-left: 70px;"
                formControlName="markFourthOutCtrl"
                placeholder="Grade Point"
                (change)="getcXg()"
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1" *ngIf="admin_show != 'true'">
              <input
                ngModel="{{ edu_data?.grade_Fourth }}"
                nbInput
                type="text"
                formControlName="gradeFourthCtrl"
                style="text-transform: uppercase;margin-left: 70px; "
                placeholder="Grade"
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1" *ngIf="admin_show == 'true'">
              <input
                ngModel="{{ edu_data?.grade_Fourth }}"
                nbInput
                type="text"
                formControlName="gradeFourthCtrl"
                style="text-transform: uppercase;margin-left: 70px; "
                placeholder="Grade"
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1">
              <input
                ngModel="{{
                  getcxg4 ? getcxg4 : edu_data?.grade_credits_Fourth
                }}"
                nbInput
                type="text"
                formControlName="gradecreditsFourthCtrl"
                placeholder="C * G"
                style="margin-left: 70px; "
                (change)="getTotalCnG();"
                id=""
                class="form-control"
              />
            </div>

            <div class="col-md-1">
              <input
                [attr.disabled]="isDisabled ? '' : null"
                ngModel="{{
                  lec_per_week4
                    ? lec_per_week4
                    : edu_data
                    ? edu_data.lecture_hour_Fourth
                    : ''
                }}"
                nbInput
                type="text"
                formControlName="lecturehourFourthCtrl"
                placeholder="No. of lec per week"
                style="margin-left: 70px; "
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1">
              <input
                [attr.disabled]="isDisabled ? '' : null"
                *ngIf="
                  edu_form.controls['courseNameCtrl'].value == 'B.Sc.' ||
                  edu_form.controls['courseNameCtrl'].value == 'M.Sc.' ||
                  edu_form.controls['courseNameCtrl'].value ==  'BVOC'
                "
                ngModel="{{
                  prac_hour4
                    ? prac_hour4
                    : edu_data
                    ? edu_data.practical_hour_Fourth
                    : ''
                }} "
                nbInput
                type="text"
                formControlName="practicalhourFourthCtrl"
                placeholder="No. of hrs/week in practs"
                style="margin-left: 70px; "
                id=""
                class="form-control"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-1">Subject 5 :</div>
            <!-- <div class="col-md-1">
            <mat-form-field class="example-full-width" appearance="outline">
              <textarea matInput  type="text" [value]="filterText5 ? filterText5 : edu_data?.course_code_Fifth"
              [formControl]="filterInput5"   [matAutocomplete]="phdauto5" [matAutocompleteDisabled]="autocheck" (change)='getSubject5($event)' ></textarea>
            </mat-form-field> 

            <mat-autocomplete #phdauto5="matAutocomplete"  style="width:200% !important;  ">
                <mat-option style="width:800% !important;  " *ngFor="let college of course_code5 | filter: {Course_Code : filterText5}" [value]="college.Course_Code" (click)="getCourse5(college)">
                    {{ college.Course_Code }}
                </mat-option>
            </mat-autocomplete>
             <textarea  ngModel="{{edu_data?.Subject_Fifth}}" nbInput type="text"  formControlName="subjectFifthCtrl" placeholder="name" id="" class="form-control"></textarea> 
          </div> -->
            <div class="col-md-1">
              <div
                class="row"
                *ngIf="
                  edu_data?.course_code_Fifth != null &&
                  edu_data?.course_code_Fifth != '' &&
                  edu_data?.course_code_Fifth != undefined &&
                  showcodeDropdown5 == false
                "
              >
                <input
                  disabled
                  [value]="edu_data?.course_code_Fifth"
                  style="width: 150px;"
                  nbInput
                  type="text"
                  id=""
                />
                <i
                  class="fas fa-pen-alt fa-2x"
                  (click)="showcoursecodeDropdown5()"
                ></i>
              </div>

              <mat-form-field
                [formGroup]="formData"
                *ngIf="showcodeDropdown5 == true"
                style="width:100px;"
              >
                <mat-select
                  placeholder="Course Code"
                  #singleSelect2
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [formControl]="filterInput5"
                      [placeholderLabel]="'Course Code...'"
                    >
                      <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="
                      let college of course_code5
                        | filter: { Course_Code: filterText5 }
                    "
                    [value]="college"
                    (click)="getCourse5(college)"
                  >
                    {{ college.Course_Code }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <!-- <div class="row">
                Can't Find ? &nbsp;
                <i
                  class="fas fa-phone-square fa-lg"
                  title="Contact"
                  style="color: green;"
                  (click)="showContactDetails()"
                ></i>
              </div>-->
            </div>
            <div class="col-md-2">
              <mat-form-field style="width: 210px; margin-left: 50px;">
                <mat-select
                  ngModel="{{ edu_data?.Subject_Fifth }}"
                  name="semester"
                  formControlName="subjectFifthCtrl"
                  [ngClass]="{
                    'form-control-danger':
                      edu_form.controls.subjectFifthCtrl.invalid &&
                      (edu_form.controls.subjectFifthCtrl.dirty ||
                        edu_form.controls.subjectFifthCtrl.touched)
                  }"
                >
                  <mat-option
                    *ngFor="let course of subjectArray5"
                    value="{{ course.Course_Subject_name }}"
                    >{{ course.Course_Subject_name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <!-- <textarea  ngModel="{{subjectName5 ? subjectName5.Course_Subject_name : (edu_data ? edu_data?.Subject_Fifth : '')}}" nbInput type="text"  formControlName="subjectFifthCtrl" placeholder="name" id="" class="form-control" disabled></textarea> -->
            </div>
            <div class="col-md-1">
              <input
                ngModel="{{ edu_data?.marks_obt_Fifth }}"
                nbInput
                type="text"
                style="margin-left: 68px;"
                formControlName="markFifthCtrl"
                placeholder="Credits Earned"
                (change)="getTotalCredits()"
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1">
              <input
                ngModel="{{ edu_data?.marks_out_Fifth }}"
                nbInput
                type="text"
                formControlName="markFifthOutCtrl"
                placeholder="Grade Point"
                style="margin-left: 70px;"
                (change)="getcXg()"
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1" *ngIf="admin_show != 'true'">
              <input
                ngModel="{{ edu_data?.grade_Fifth }}"
                nbInput
                type="text"
                formControlName="gradeFifthCtrl"
                placeholder="Grade"
                style="text-transform: uppercase;margin-left: 70px; "
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1" *ngIf="admin_show == 'true'">
              <input
                ngModel="{{ edu_data?.grade_Fifth }}"
                nbInput
                type="text"
                formControlName="gradeFifthCtrl"
                placeholder="Grade"
                style="text-transform: uppercase;margin-left: 70px; "
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1">
              <input
                ngModel="{{
                  getcxg5 ? getcxg5 : edu_data?.grade_credits_Fifth
                }}"
                nbInput
                type="text"
                formControlName="gradecreditsFifthCtrl"
                placeholder="C * G"
                style="margin-left: 70px; "
                (change)="getTotalCnG()"
                id=""
                class="form-control"
              />
            </div>

            <div class="col-md-1">
              <input
                [attr.disabled]="isDisabled ? '' : null"
                ngModel="{{
                  lec_per_week5
                    ? lec_per_week5
                    : edu_data
                    ? edu_data.lecture_hour_Fifth
                    : ''
                }}"
                nbInput
                style="margin-left:70px;"
                type="text"
                formControlName="lecturehourFifthCtrl"
                placeholder="No. of lec per week"
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1">
              <input
                [attr.disabled]="isDisabled ? '' : null"
                *ngIf="
                  edu_form.controls['courseNameCtrl'].value == 'B.Sc.' ||
                  edu_form.controls['courseNameCtrl'].value == 'M.Sc.' ||
                  edu_form.controls['courseNameCtrl'].value ==  'BVOC'
                "
                ngModel="{{
                  prac_hour5
                    ? prac_hour5
                    : edu_data
                    ? edu_data.practical_hour_Fifth
                    : ''
                }} "
                nbInput
                type="text"
                formControlName="practicalhourFifthCtrl"
                placeholder="No. of hrs/week in practs"
                style="margin-left: 70px; "
                id=""
                class="form-control"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-1">Subject 6 :</div>
            <!-- <div class="col-md-1">
            <mat-form-field class="example-full-width" appearance="outline">
              <textarea matInput  type="text" [value]="filterText6 ? filterText6 : edu_data?.course_code_Six"
              [formControl]="filterInput6"   [matAutocomplete]="phdauto6" [matAutocompleteDisabled]="autocheck" (change)='getSubject6($event)' ></textarea>
            </mat-form-field> 

            <mat-autocomplete #phdauto6="matAutocomplete"  style="width:200% !important;  ">
                <mat-option style="width:800% !important;  " *ngFor="let college of course_code6 | filter: {Course_Code : filterText6}" [value]="college.Course_Code" (click)="getCourse6(college)">
                    {{ college.Course_Code }}
                </mat-option>
            </mat-autocomplete>
             <textarea  ngModel="{{edu_data?.Subject_Six}}" nbInput type="text"  formControlName="subjectSixthCtrl" placeholder="name" id="" class="form-control"></textarea> 
          </div> -->
            <div class="col-md-1">
              <div
                class="row"
                *ngIf="
                  edu_data?.course_code_Six != null &&
                  edu_data?.course_code_Six != '' &&
                  edu_data?.course_code_Six != undefined &&
                  showcodeDropdown6 == false
                "
              >
                <input
                  disabled
                  [value]="edu_data?.course_code_Six"
                  style="width: 150px;"
                  nbInput
                  type="text"
                  id=""
                />
                <i
                  class="fas fa-pen-alt fa-2x"
                  (click)="showcoursecodeDropdown6()"
                ></i>
              </div>

              <mat-form-field
                [formGroup]="formData"
                *ngIf="showcodeDropdown6 == true"
                style="width:100px;"
              >
                <mat-select
                  placeholder="Course Code"
                  #singleSelect2
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [formControl]="filterInput6"
                      [placeholderLabel]="'Course Code...'"
                    >
                      <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="
                      let college of course_code6
                        | filter: { Course_Code: filterText6 }
                    "
                    [value]="college"
                    (click)="getCourse6(college)"
                  >
                    {{ college.Course_Code }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <!-- <div class="row">
                Can't Find ? &nbsp;
                <i
                  class="fas fa-phone-square fa-lg"
                  title="Contact"
                  style="color: green;"
                  (click)="showContactDetails()"
                ></i>
              </div>-->
            </div>
            <div class="col-md-2">
              <mat-form-field style="width: 210px; margin-left: 50px;">
                <mat-select
                  ngModel="{{ edu_data?.Subject_Six }}"
                  name="semester"
                  formControlName="subjectSixthCtrl"
                  [ngClass]="{
                    'form-control-danger':
                      edu_form.controls.subjectSixthCtrl.invalid &&
                      (edu_form.controls.subjectSixthCtrl.dirty ||
                        edu_form.controls.subjectSixthCtrl.touched)
                  }"
                >
                  <mat-option
                    *ngFor="let course of subjectArray6"
                    value="{{ course.Course_Subject_name }}"
                    >{{ course.Course_Subject_name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <!-- <textarea  ngModel="{{subjectName6 ? subjectName6.Course_Subject_name : (edu_data ? edu_data?.Subject_Six : '')}}" nbInput type="text"  formControlName="subjectSixthCtrl" placeholder="name" id="" class="form-control" disabled></textarea> -->
            </div>
            <div class="col-md-1">
              <input
                ngModel="{{ edu_data?.marks_obt_Six }}"
                nbInput
                type="text"
                formControlName="markSixthCtrl"
                style="margin-left: 68px;"
                placeholder="Credits Earned"
                (change)="getTotalCredits()"
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1">
              <input
                ngModel="{{ edu_data?.marks_out_Six }}"
                nbInput
                type="text"
                formControlName="markSixthOutCtrl"
                placeholder="Grade Point"
                style="margin-left: 70px;"
                (change)="getcXg()"
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1" *ngIf="admin_show != 'true'">
              <input
                ngModel="{{ edu_data?.grade_Six }}"
                nbInput
                type="text"
                formControlName="gradeSixthCtrl"
                placeholder="Grade"
                id=""
                style="text-transform: uppercase;margin-left: 70px; "
                class="form-control"
              />
            </div>
            <div class="col-md-1" *ngIf="admin_show == 'true'">
              <input
                ngModel="{{ edu_data?.grade_Six }}"
                nbInput
                type="text"
                formControlName="gradeSixthCtrl"
                placeholder="Grade"
                id=""
                style="text-transform: uppercase;margin-left: 70px; "
                class="form-control"
              />
            </div>
            <div class="col-md-1">
              <input
                ngModel="{{ getcxg6 ? getcxg6 : edu_data?.grade_credits_Six }}"
                nbInput
                type="text"
                formControlName="gradecreditsSixthCtrl"
                placeholder="C * G"
                style="margin-left: 70px; "
                (change)="getTotalCnG()"
                id=""
                class="form-control"
              />
            </div>

            <div class="col-md-1">
              <input
                [attr.disabled]="isDisabled ? '' : null"
                ngModel="{{
                  lec_per_week6
                    ? lec_per_week6
                    : edu_data
                    ? edu_data.lecture_hour_Six
                    : ''
                }}"
                nbInput
                type="text"
                formControlName="lecturehourSixCtrl"
                placeholder="No. of lec per week"
                style="margin-left: 70px; "
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1">
              <input
                [attr.disabled]="isDisabled ? '' : null"
                *ngIf="
                  edu_form.controls['courseNameCtrl'].value == 'B.Sc.' ||
                  edu_form.controls['courseNameCtrl'].value == 'M.Sc.' ||
                  edu_form.controls['courseNameCtrl'].value ==  'BVOC'
                "
                ngModel="{{
                  prac_hour6
                    ? prac_hour6
                    : edu_data
                    ? edu_data.practical_hour_Six
                    : ''
                }} "
                nbInput
                type="text"
                formControlName="practicalhourSixCtrl"
                placeholder="No. of hrs/week in practs"
                style="margin-left: 70px; "
                id=""
                class="form-control"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-1">Subject 7 :</div>
            <!-- <div class="col-md-1">
            <mat-form-field class="example-full-width" appearance="outline">
              <textarea matInput  type="text" [value]="filterText7 ? filterText7 : edu_data?.course_code_Seventh"
              [formControl]="filterInput7"   [matAutocomplete]="phdauto7" [matAutocompleteDisabled]="autocheck" (change)='getSubject7($event)' ></textarea>
            </mat-form-field> 

            <mat-autocomplete #phdauto7="matAutocomplete"  style="width:200% !important;  ">
                <mat-option style="width:800% !important;  " *ngFor="let college of course_code7 | filter: {Course_Code : filterText7}" [value]="college.Course_Code" (click)="getCourse7(college)">
                    {{ college.Course_Code }}
                </mat-option>
            </mat-autocomplete>
             <textarea  ngModel="{{edu_data?.Subject_Seventh}}" nbInput type="text"  formControlName="subjectSeventhCtrl" placeholder="name" id="" class="form-control"></textarea> 
          </div> -->
            <div class="col-md-1">
              <div
                class="row"
                *ngIf="
                  edu_data?.course_code_Seventh != null &&
                  edu_data?.course_code_Seventh != '' &&
                  edu_data?.course_code_Seventh != undefined &&
                  showcodeDropdown7 == false
                "
              >
                <input
                  disabled
                  [value]="edu_data?.course_code_Seventh"
                  style="width: 150px;"
                  nbInput
                  type="text"
                  id=""
                />
                <i
                  class="fas fa-pen-alt fa-2x"
                  (click)="showcoursecodeDropdown7()"
                ></i>
              </div>

              <mat-form-field
                [formGroup]="formData"
                *ngIf="showcodeDropdown7 == true"
                style="width:100px;"
              >
                <mat-select
                  placeholder="Course Code"
                  #singleSelect2
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [formControl]="filterInput7"
                      [placeholderLabel]="'Course Code...'"
                    >
                      <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="
                      let college of course_code7
                        | filter: { Course_Code: filterText7 }
                    "
                    [value]="college"
                    (click)="getCourse7(college)"
                  >
                    {{ college.Course_Code }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <!-- <div class="row">
                Can't Find ? &nbsp;
                <i
                  class="fas fa-phone-square fa-lg"
                  title="Contact"
                  style="color: green;"
                  (click)="showContactDetails()"
                ></i>
              </div>-->
            </div>
            <div class="col-md-2">
              <mat-form-field style="width: 210px; margin-left: 50px;">
                <mat-select
                  ngModel="{{ edu_data?.Subject_Seventh }}"
                  name="semester"
                  formControlName="subjectSeventhCtrl"
                  [ngClass]="{
                    'form-control-danger':
                      edu_form.controls.subjectSeventhCtrl.invalid &&
                      (edu_form.controls.subjectSeventhCtrl.dirty ||
                        edu_form.controls.subjectSeventhCtrl.touched)
                  }"
                >
                  <mat-option
                    *ngFor="let course of subjectArray7"
                    value="{{ course.Course_Subject_name }}"
                    >{{ course.Course_Subject_name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <!-- <textarea  ngModel="{{subjectName7 ? subjectName7.Course_Subject_name : (edu_data ? edu_data?.Subject_Seventh : '')}}" nbInput type="text"  formControlName="subjectSeventhCtrl" placeholder="name" id="" class="form-control" disabled></textarea> -->
            </div>
            <div class="col-md-1">
              <input
                ngModel="{{ edu_data?.marks_obt_Seventh }}"
                nbInput
                type="text"
                formControlName="markSeventhCtrl"
                style="margin-left: 68px;"
                placeholder="Credits Earned"
                (change)="getTotalCredits()"
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1">
              <input
                ngModel="{{ edu_data?.marks_out_Seventh }}"
                nbInput
                type="text"
                formControlName="markSeventhOutCtrl"
                placeholder="Grade Point"
                style="margin-left: 70px;"
                (change)="getcXg()"
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1" *ngIf="admin_show != 'true'">
              <input
                ngModel="{{ edu_data?.grade_Seventh }}"
                nbInput
                type="text"
                formControlName="gradeSeventhCtrl"
                placeholder="Grade"
                id=""
                style="text-transform: uppercase;margin-left: 70px; "
                class="form-control"
              />
            </div>
            <div class="col-md-1" *ngIf="admin_show == 'true'">
              <input
                ngModel="{{ edu_data?.grade_Seventh }}"
                nbInput
                type="text"
                formControlName="gradeSeventhCtrl"
                placeholder="Grade"
                id=""
                style="text-transform: uppercase;margin-left: 70px; "
                class="form-control"
              />
            </div>
            <div class="col-md-1">
              <input
                ngModel="{{
                  getcxg7 ? getcxg7 : edu_data?.grade_credits_Seventh
                }}"
                nbInput
                type="text"
                formControlName="gradecreditsSeventhCtrl"
                placeholder="C * G"
                (change)="getTotalCnG()"
                style="margin-left: 70px; "
                id=""
                class="form-control"
              />
            </div>

            <div class="col-md-1">
              <input
                [attr.disabled]="isDisabled ? '' : null"
                ngModel="{{
                  lec_per_week7
                    ? lec_per_week7
                    : edu_data
                    ? edu_data.lecture_hour_Seventh
                    : ''
                }}"
                nbInput
                type="text"
                formControlName="lecturehourSeventhCtrl"
                placeholder="No. of lec per week"
                style="margin-left: 70px; "
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1">
              <input
                [attr.disabled]="isDisabled ? '' : null"
                *ngIf="
                  edu_form.controls['courseNameCtrl'].value == 'B.Sc.' ||
                  edu_form.controls['courseNameCtrl'].value == 'M.Sc.' ||
                  edu_form.controls['courseNameCtrl'].value ==  'BVOC'
                "
                ngModel="{{
                  prac_hour7
                    ? prac_hour7
                    : edu_data
                    ? edu_data.practical_hour_Seventh
                    : ''
                }}"
                nbInput
                type="text"
                formControlName="practicalhourSeventhCtrl"
                placeholder="No. of hrs/week in practs"
                style="margin-left: 70px; "
                id=""
                class="form-control"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-1">Subject 8 :</div>
            <!-- <div class="col-md-1">
            <mat-form-field class="example-full-width" appearance="outline">
              <textarea matInput  type="text" [value]="filterText8 ? filterText8 : edu_data?.course_code_Eighth"
              [formControl]="filterInput8"   [matAutocomplete]="phdauto8" [matAutocompleteDisabled]="autocheck" (change)='getSubject8($event)' ></textarea>
            </mat-form-field> 

            <mat-autocomplete #phdauto8="matAutocomplete"  style="width:200% !important;  ">
                <mat-option style="width:800% !important;  " *ngFor="let college of course_code8 | filter: {Course_Code : filterText8}" [value]="college.Course_Code" (click)="getCourse8(college)">
                    {{ college.Course_Code }}
                </mat-option>
            </mat-autocomplete>
            <textarea  ngModel="{{edu_data?.Subject_Eighth}}" nbInput type="text"  formControlName="subjectEighthCtrl" placeholder="name" id="" class="form-control"></textarea> 
          </div> -->
            <div class="col-md-1">
              <div
                class="row"
                *ngIf="
                  edu_data?.course_code_Eighth != null &&
                  edu_data?.course_code_Eighth != '' &&
                  edu_data?.course_code_Eighth != undefined &&
                  showcodeDropdown8 == false
                "
              >
                <input
                  disabled
                  [value]="edu_data?.course_code_Eighth"
                  style="width: 150px;"
                  nbInput
                  type="text"
                  id=""
                />
                <i
                  class="fas fa-pen-alt fa-2x"
                  (click)="showcoursecodeDropdown8()"
                ></i>
              </div>

              <mat-form-field
                [formGroup]="formData"
                *ngIf="showcodeDropdown8 == true"
                style="width:100px;"
              >
                <mat-select
                  placeholder="Course Code"
                  #singleSelect2
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [formControl]="filterInput8"
                      [placeholderLabel]="'Course Code...'"
                    >
                      <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="
                      let college of course_code8
                        | filter: { Course_Code: filterText8 }
                    "
                    [value]="college"
                    (click)="getCourse8(college)"
                  >
                    {{ college.Course_Code }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <!-- <div class="row">
                Can't Find ? &nbsp;
                <i
                  class="fas fa-phone-square fa-lg"
                  title="Contact"
                  style="color: green;"
                  (click)="showContactDetails()"
                ></i>
              </div>-->
            </div>
            <div class="col-md-2">
              <mat-form-field style="width: 210px; margin-left: 50px;">
                <mat-select
                  ngModel="{{ edu_data?.Subject_Eighth }}"
                  name="semester"
                  formControlName="subjectEighthCtrl"
                  [ngClass]="{
                    'form-control-danger':
                      edu_form.controls.subjectEighthCtrl.invalid &&
                      (edu_form.controls.subjectEighthCtrl.dirty ||
                        edu_form.controls.subjectEighthCtrl.touched)
                  }"
                >
                  <mat-option
                    *ngFor="let course of subjectArray8"
                    value="{{ course.Course_Subject_name }}"
                    >{{ course.Course_Subject_name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <!-- <textarea  ngModel="{{subjectName8 ? subjectName8.Course_Subject_name : (edu_data ? edu_data?.Subject_Eighth : '')}}" nbInput type="text"  formControlName="subjectEighthCtrl" placeholder="name" id="" class="form-control" disabled></textarea> -->
            </div>
            <div class="col-md-1">
              <input
                ngModel="{{ edu_data?.marks_obt_Eighth }}"
                nbInput
                type="text"
                formControlName="markEighthCtrl"
                placeholder="Credits Earned"
                style="margin-left: 68px;"
                (change)="getTotalCredits()"
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1">
              <input
                ngModel="{{ edu_data?.marks_out_Eighth }}"
                nbInput
                type="text"
                formControlName="markEighthOutCtrl"
                placeholder="Grade Point"
                style="margin-left: 70px;"
                (change)="getcXg()"
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1" *ngIf="admin_show != 'true'">
              <input
                ngModel="{{ edu_data?.grade_Eighth }}"
                nbInput
                type="text"
                formControlName="gradeEighthCtrl"
                placeholder="Grade"
                style="text-transform: uppercase;margin-left: 70px; "
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1" *ngIf="admin_show == 'true'">
              <input
                
                ngModel="{{ edu_data?.grade_Eighth }}"
                nbInput
                type="text"
                formControlName="gradeEighthCtrl"
                placeholder="Grade"
                style="text-transform: uppercase;margin-left: 70px; "
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1">
              <input
                ngModel="{{
                  getcxg8 ? getcxg8 : edu_data?.grade_credits_Eighth
                }}"
                nbInput
                type="text"
                formControlName="gradecreditsEighthCtrl"
                placeholder="C * G"
                (change)="getTotalCnG()"
                style="margin-left: 70px; "
                id=""
                class="form-control"
              />
            </div>

            <div class="col-md-1">
              <input
                [attr.disabled]="isDisabled ? '' : null"
                style="margin-left: 70px; "
                 ngModel="{{
                  lec_per_week8
                    ? lec_per_week8
                    : edu_data
                    ? edu_data.lecture_hour_Eighth
                    : ''
                }}"
                nbInput
                type="text"
                formControlName="lecturehourEighthCtrl"
                placeholder="No. of lec per week"

                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1">
              <input
                [attr.disabled]="isDisabled ? '' : null"
                *ngIf="
                  edu_form.controls['courseNameCtrl'].value == 'B.Sc.' ||
                  edu_form.controls['courseNameCtrl'].value == 'M.Sc.' ||
                  edu_form.controls['courseNameCtrl'].value ==  'BVOC'
                "
                ngModel="{{
                  prac_hour8
                    ? prac_hour8
                    : edu_data
                    ? edu_data.practical_hour_Eighth
                    : ''
                }}"
                nbInput
                type="text"
                formControlName="practicalhourEighthCtrl"
                placeholder="No. of hrs/week in practs"
                style="margin-left: 70px; "
                id=""
                class="form-control"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-md-1">Subject 9 :</div>
            <!-- <div class="col-md-1">
            <mat-form-field class="example-full-width" appearance="outline">
              <textarea matInput  type="text" [value]="filterText9 ? filterText9 : edu_data?.course_code_Ninth"
              [formControl]="filterInput9"   [matAutocomplete]="phdauto9" [matAutocompleteDisabled]="autocheck" (change)='getSubject9($event)' ></textarea>
            </mat-form-field> 

            <mat-autocomplete #phdauto9="matAutocomplete"  style="width:200% !important;  ">
                <mat-option style="width:800% !important;  " *ngFor="let college of course_code9 | filter: {Course_Code : filterText9}" [value]="college.Course_Code" (click)="getCourse9(college)">
                    {{ college.Course_Code }}
                </mat-option>
            </mat-autocomplete>
             <textarea  ngModel="{{edu_data?.Subject_Ninth}}" nbInput type="text"  formControlName="subjectNinthCtrl" placeholder="name" id="" class="form-control"></textarea> 
          </div> -->
            <div class="col-md-1">
              <div
                class="row"
                *ngIf="
                  edu_data?.course_code_Ninth != null &&
                  edu_data?.course_code_Ninth != '' &&
                  edu_data?.course_code_Ninth != undefined &&
                  showcodeDropdown9 == false
                "
              >
                <input
                  disabled
                  [value]="edu_data?.course_code_Ninth"
                  style="width: 150px;"
                  nbInput
                  type="text"
                  id=""
                />
                <i
                  class="fas fa-pen-alt fa-2x"
                  (click)="showcoursecodeDropdown9()"
                ></i>
              </div>

              <mat-form-field
                [formGroup]="formData"
                *ngIf="showcodeDropdown9 == true"
                style="width:100px;"
              >
                <mat-select
                  placeholder="Course Code"
                  #singleSelect2
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [formControl]="filterInput9"
                      [placeholderLabel]="'Course Code...'"
                    >
                      <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="
                      let college of course_code9
                        | filter: { Course_Code: filterText9 }
                    "
                    [value]="college"
                    (click)="getCourse9(college)"
                  >
                    {{ college.Course_Code }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <!-- <div class="row">
                Can't Find ? &nbsp;
                <i
                  class="fas fa-phone-square fa-lg"
                  title="Contact"
                  style="color: green;"
                  (click)="showContactDetails()"
                ></i>
              </div>-->
            </div>
            <div class="col-md-2">
              <mat-form-field style="width: 210px; margin-left: 50px;">
                <mat-select
                  ngModel="{{ edu_data?.Subject_Ninth }}"
                  name="semester"
                  formControlName="subjectNinthCtrl"
                  [ngClass]="{
                    'form-control-danger':
                      edu_form.controls.subjectNinthCtrl.invalid &&
                      (edu_form.controls.subjectNinthCtrl.dirty ||
                        edu_form.controls.subjectNinthCtrl.touched)
                  }"
                >
                  <mat-option
                    *ngFor="let course of subjectArray9"
                    value="{{ course.Course_Subject_name }}"
                    >{{ course.Course_Subject_name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <!-- <textarea  ngModel="{{subjectName9 ? subjectName9.Course_Subject_name : (edu_data ? edu_data?.Subject_Ninth : '')}}" nbInput type="text"  formControlName="subjectNinthCtrl" placeholder="name" id="" class="form-control" disabled></textarea> -->
            </div>
            <div class="col-md-1">
              <input
                ngModel="{{ edu_data?.marks_obt_Ninth }}"
                nbInput
                type="text"
                formControlName="markNinthCtrl"
                placeholder="Credits Earned"
                style="margin-left: 68px;"
                (change)="getTotalCredits()"
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1">
              <input
                ngModel="{{ edu_data?.marks_out_Ninth }}"
                nbInput
                type="text"
                formControlName="markNinthOutCtrl"
                placeholder="Grade Point"
                style="margin-left: 70px;"
                (change)="getcXg()"
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1" *ngIf="admin_show != 'true'">
              <input
                ngModel="{{ edu_data?.grade_Ninth }}"
                nbInput
                type="text"
                formControlName="gradeNinthCtrl"
                style="text-transform: uppercase;margin-left: 70px; "
                placeholder="Grade"
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1" *ngIf="admin_show == 'true'">
              <input
                ngModel="{{ edu_data?.grade_Ninth }}"
                nbInput
                type="text"
                formControlName="gradeNinthCtrl"
                style="text-transform: uppercase;margin-left: 70px; "
                placeholder="Grade"
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1">
              <input
                ngModel="{{
                  getcxg9 ? getcxg9 : edu_data?.grade_credits_Ninth
                }}"
                nbInput
                type="text"
                formControlName="gradecreditsNinthCtrl"
                placeholder="C * G"
                (change)="getTotalCnG()"
                style="margin-left: 70px; "
                id=""
                class="form-control"
              />
            </div>

            <div class="col-md-1">
              <input
                [attr.disabled]="isDisabled ? '' : null"
                style="margin-left: 70px; "
                ngModel="{{
                  lec_per_week9
                    ? lec_per_week9
                    : edu_data
                    ? edu_data.lecture_hour_Ninth
                    : ''
                }}"
                nbInput
                type="text"
                formControlName="lecturehourNinthCtrl"
                placeholder="No. of lec per week"
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1">
              <input
                [attr.disabled]="isDisabled ? '' : null"
                *ngIf="
                  edu_form.controls['courseNameCtrl'].value == 'B.Sc.' ||
                  edu_form.controls['courseNameCtrl'].value == 'M.Sc.' ||
                  edu_form.controls['courseNameCtrl'].value ==  'BVOC'
                "
                ngModel="{{
                  prac_hour9
                    ? prac_hour9
                    : edu_data
                    ? edu_data.practical_hour_Ninth
                    : ''
                }}"
                nbInput
                type="text"
                formControlName="practicalhourNinthCtrl"
                placeholder="No. of hrs/week in practs"
                style="margin-left: 70px; "
                id=""
                class="form-control"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-md-1">Subject 10 :</div>
            <!-- <div class="col-md-1">
            <mat-form-field class="example-full-width" appearance="outline">
              <textarea matInput  type="text" [value]="filterText10 ? filterText10 : edu_data?.course_code_Tenth"
              [formControl]="filterInput10"   [matAutocomplete]="phdauto10" [matAutocompleteDisabled]="autocheck" (change)='getSubject10($event)' ></textarea>
            </mat-form-field> 

            <mat-autocomplete #phdauto10="matAutocomplete"  style="width:200% !important;  ">
                <mat-option style="width:800% !important;  " *ngFor="let college of course_code10 | filter: {Course_Code : filterText10}" [value]="college.Course_Code" (click)="getCourse10(college)">
                    {{ college.Course_Code }}
                </mat-option>
            </mat-autocomplete>
             <textarea  ngModel="{{edu_data?.Subject_Tenth}}" nbInput type="text"  formControlName="subjectTenthCtrl" placeholder="name" id="" class="form-control"></textarea> 
          </div> -->
            <div class="col-md-1">
              <div
                class="row"
                *ngIf="
                  edu_data?.course_code_Tenth != null &&
                  edu_data?.course_code_Tenth != '' &&
                  edu_data?.course_code_Tenth != undefined &&
                  showcodeDropdown10 == false
                "
              >
                <input
                  disabled
                  [value]="edu_data?.course_code_Tenth"
                  style="width: 150px;"
                  nbInput
                  type="text"
                  id=""
                />
                <i
                  class="fas fa-pen-alt fa-2x"
                  (click)="showcoursecodeDropdown10()"
                ></i>
              </div>

              <mat-form-field
                [formGroup]="formData"
                *ngIf="showcodeDropdown10 == true"
                style="width:100px;"
              >
                <mat-select
                  placeholder="Course Code"
                  #singleSelect2
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [formControl]="filterInput10"
                      [placeholderLabel]="'Course Code...'"
                    >
                      <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="
                      let college of course_code10
                        | filter: { Course_Code: filterText10 }
                    "
                    [value]="college"
                    (click)="getCourse10(college)"
                  >
                    {{ college.Course_Code }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <div class="row">
                Can't Find ? &nbsp;
                <i
                  class="fas fa-phone-square fa-lg"
                  title="Contact"
                  style="color: green;"
                  (click)="showContactDetails()"
                ></i>
              </div>
            </div>
            <div class="col-md-2">
              <mat-form-field style="width: 210px; margin-left: 50px;">
                <mat-select
                  ngModel="{{ edu_data?.Subject_Tenth }}"
                  name="semester"
                  formControlName="subjectTenthCtrl"
                  [ngClass]="{
                    'form-control-danger':
                      edu_form.controls.subjectTenthCtrl.invalid &&
                      (edu_form.controls.subjectTenthCtrl.dirty ||
                        edu_form.controls.subjectTenthCtrl.touched)
                  }"
                >
                  <mat-option
                    *ngFor="let course of subjectArray10"
                    value="{{ course.Course_Subject_name }}"
                    >{{ course.Course_Subject_name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <!-- <textarea  ngModel="{{subjectName10 ? subjectName10.Course_Subject_name : (edu_data ? edu_data?.Subject_Tenth : '')}}" nbInput type="text"  formControlName="subjectTenthCtrl" placeholder="name" id="" class="form-control" disabled></textarea> -->
            </div>
            <div class="col-md-1">
              <input
                ngModel="{{ edu_data?.marks_obt_Tenth }}"
                nbInput
                type="text"
                formControlName="markTenthCtrl"
                style="margin-left: 68px;"
                placeholder="Credits Earned"
                (change)="getTotalCredits()"
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1">
              <input
                ngModel="{{ edu_data?.marks_out_Tenth }}"
                nbInput
                type="text"
                formControlName="markTenthOutCtrl"
                placeholder="Grade Point"
                style="margin-left: 70px;"
                (change)="getcXg()"
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1" *ngIf="admin_show != 'true'">
              <input
                ngModel="{{ edu_data?.grade_Tenth }}"
                nbInput
                type="text"
                formControlName="gradeTenthCtrl"
                style="text-transform: uppercase;margin-left: 70px; "
                placeholder="Grade"
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1" *ngIf="admin_show == 'true'">
              <input
                ngModel="{{ edu_data?.grade_Tenth }}"
                nbInput
                type="text"
                formControlName="gradeTenthCtrl"
                style="text-transform: uppercase;margin-left: 70px; "
                placeholder="Grade"
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1">
              <input
                ngModel="{{
                  getcxg10 ? getcxg10 : edu_data?.grade_credits_Tenth
                }}"
                nbInput
                type="text"
                formControlName="gradecreditsTenthCtrl"
                placeholder="C * G"
                (change)="getTotalCnG()"
                style="margin-left: 70px; "
                id=""
                class="form-control"
              />
            </div>

            <div class="col-md-1">
              <input
                [attr.disabled]="isDisabled ? '' : null"
                style="margin-left: 70px; "
                ngModel="{{
                  lec_per_week10
                    ? lec_per_week10
                    : edu_data
                    ? edu_data.lecture_hour_Tenth
                    : ''
                }}"
                nbInput
                type="text"
                formControlName="lecturehourtenthCtrl"
                placeholder="No. of lec per week"
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-1">
              <input
                [attr.disabled]="isDisabled ? '' : null"
                *ngIf="
                  edu_form.controls['courseNameCtrl'].value == 'B.Sc.' ||
                  edu_form.controls['courseNameCtrl'].value == 'M.Sc.' ||
                  edu_form.controls['courseNameCtrl'].value ==  'BVOC'
                "
                ngModel="{{
                  prac_hour10
                    ? prac_hour10
                    : edu_data
                    ? edu_data.practical_hour_Tenth
                    : ''
                }}"
                nbInput
                type="text"
                formControlName="practicalhourtenthCtrl"
                placeholder="No. of hrs/week in practs"
                style="margin-left: 70px; "
                id=""
                class="form-control"
              />
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-md-2">S.G.P.A. :</div>
            <div class="col-md-2">
              <input (click)= "totalSgpa()"
                ngModel="{{ this.totalsgpa ? this.totalsgpa : edu_data?.OverAllGrade }}"
                nbInput
                type="text"
                [ngClass]="{
                  'form-control-danger':
                    edu_form.controls.overAllGradeCtrl.invalid &&
                    (edu_form.controls.overAllGradeCtrl.dirty ||
                      edu_form.controls.overAllGradeCtrl.touched)
                }"
                formControlName="overAllGradeCtrl"
                placeholder=""
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-2">C.G.P.A. :</div>
            <div class="col-md-2">
              <input (click) = "totalCgpa()"
                ngModel="{{
                  overallCGPA ? overallCGPA : edu_data?.overall_gpa_sgpi
                }}"
                nbInput
                type="text"
                [ngClass]="{
                  'form-control-danger':
                    edu_form.controls.overAllgpasgpiCtrl.invalid &&
                    (edu_form.controls.overAllgpasgpiCtrl.dirty ||
                      edu_form.controls.overAllgpasgpiCtrl.touched)
                }"
                formControlName="overAllgpasgpiCtrl"
                placeholder=""
                id=""
                class="form-control"
              />
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-md-2">Overall CGPA(I-VI):</div>
            <div class="col-md-2">
              <input
                ngModel="{{ edu_data?.overAllYearlyGrade }}"
                nbInput
                type="text"
                formControlName="overAllCgpaCtrl"
                placeholder=""
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-2">Total Credits Awarded :</div>
            <div class="col-md-2">
              <input
                ngModel="{{
                  this.totalMarks ? this.totalMarks : edu_data?.total_weeks
                }}"
                nbInput
                type="text"
                formControlName="totalweeksCtrl"
                placeholder="Total Credits"
                id=""
                class="form-control"
              />
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-md-2">Medium of instruction :</div>
            <div class="col-md-2">
              <input
                [ngModel]="edu_data ? edu_data?.medium_instruction : 'English'"
                nbInput
                type="text"
                [ngClass]="{
                  'form-control-danger':
                    edu_form.controls.instructionCtrl.invalid &&
                    (edu_form.controls.instructionCtrl.dirty ||
                      edu_form.controls.instructionCtrl.touched)
                }"
                formControlName="instructionCtrl"
                placeholder=""
                id=""
                class="form-control"
              />
            </div>
            <div class="col-md-2" *ngIf="admin_show == 'true'">
              Special Instruction :
            </div>
            <div class="col-md-2" *ngIf="admin_show == 'true'">
              <input
                ngModel="{{ edu_data?.special_instruction }}"
                nbInput
                type="text"
                formControlName="specialInstrctionCtrl"
                placeholder=""
                id=""
                class="form-control"
              />
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-md-2" *ngIf="admin_show == 'true'">
              Final Grade Special Instruction :
            </div>
            <div class="col-md-2" *ngIf="admin_show == 'true'">
              <input
                ngModel="{{ edu_data?.final_grade_special_instruction }}"
                nbInput
                type="text"
                formControlName="finalgradespecialInstrctionCtrl"
                placeholder=""
                id=""
                class="form-control"
              />
            </div>
          </div>
        </form>
      </nb-card-body>
      <nb-card-footer>
        <button nbButton hero status="primary" (click)="dismiss()">
          Close
        </button>
        <button
          *ngIf="amount_paid == 'false'"
          nbButton
          status="info"
          (click)="saveEdu()"
        >
          save
        </button>
      </nb-card-footer>
    </nb-card>
    <p-confirmDialog [style]="{ width: '425px' }"></p-confirmDialog>
  `,
  providers: [ConfirmationService],
})
export class addEducationalDetailsSemesterGPA implements OnInit {
  FeedbackForm: FormGroup;
  @Input() user_id: any;
  @Input() edu_id: any;
  @Input() amount_paid: string;
  @Input() admin_show: string;
  @Input() autoName: string;
  @Input() yearValue = [];
  @Input() showSpecialization: string;
  @Input() course_name_auto: string;
  @Input() college_year_auto: string;  
  // @Input() courseShortName: string;
  validations_flag = false;
  feedback_message;
  showExperience = false;
  classValidation = true;
  totalMarks: number;
  totalCnG: number;
  totalcgpa: number;
  totalsgpa : number;
  validPattern = "^[a-zA-Z0-9]+$"; //"[a-zA-Z0-9]+";

  edu_form: FormGroup;
  public filterInput = new FormControl(
    "",
    Validators.pattern(this.validPattern)
  );
  public filterInput2 = new FormControl("", [
    Validators.required,
    Validators.pattern(this.validPattern),
  ]);
  public filterInput3 = new FormControl(
    "",
    Validators.pattern(this.validPattern)
  );
  public filterInput4 = new FormControl(
    "",
    Validators.pattern(this.validPattern)
  );
  public filterInput5 = new FormControl(
    "",
    Validators.pattern(this.validPattern)
  );
  public filterInput6 = new FormControl(
    "",
    Validators.pattern(this.validPattern)
  );
  public filterInput7 = new FormControl(
    "",
    Validators.pattern(this.validPattern)
  );
  public filterInput8 = new FormControl(
    "",
    Validators.pattern(this.validPattern)
  );
  public filterInput9 = new FormControl(
    "",
    Validators.pattern(this.validPattern)
  );
  public filterInput10 = new FormControl(
    "",
    Validators.pattern(this.validPattern)
  );
  years_array;
  courseValidation = true;
  collegeYearValidation = true;
  semesterValidation = true;
  monthValidation = true;
  yearValidation = true;
  attemptValidation = true;
  instructionValidation = true;
  edu_data;
  course_data;
  user: any;
  hourValue: any;
  hourValue8: any;
  hourValue7: any;
  hourValue6: any;
  hourValue5: any;
  hourValue4: any;
  hourValue3: any;
  hourValue2: any;
  lecVal1: any;
  lecVal2: any;
  lecVal3: any;
  lecVal4: any;
  lecVal5: any;
  lecVal6: any;
  lecVal7: any;
  lecVal8: any;
  getcxg1: number;
  getcxg2: number;
  getcxg3: number;
  getcxg4: number;
  getcxg5: number;
  getcxg6: number;
  getcxg7: number;
  getcxg8: number;
  getcxg10: number;
  getcxg9: number;
  totalcg : number;

  overallCGPA : number;
  CgpaValue : number;
  
  lecVal9: any;
  hourValue9: any;
  lecVal10: any;
  hourValue10: any;
  subjectName: any;
  withoutCodeSub: any;
  subjectName2: any;
  withoutCodeSub2: any;
  withoutCodeSub3: any;
  subjectName3: any;
  subjectName4: any;
  withoutCodeSub4: any;
  subjectName5: any;
  withoutCodeSub5: any;
  subjectName6: any;
  withoutCodeSub6: any;
  subjectName7: any;
  withoutCodeSub7: any;
  subjectName8: any;
  withoutCodeSub8: any;
  subjectName9: any;
  withoutCodeSub9: any;
  subjectName10: any;
  withoutCodeSub10: any;
  course_code2: any;
  course_code3: any;
  course_code4: any;
  course_code5: any;
  course_code6: any;
  course_code7: any;
  course_code9: any;
  course_code10: any;
  term2: string;
  term1: string;
  course_data2: any;
  course_data4: any;
  course_data5: any;
  course_data6: any;
  course_data7: any;
  course_data8: any;
  course_data9: any;
  course_data10: any;
  abc5: any;
  course_code: any;
  course_code8: any;
  prac_hour1: any;
  prac_hour2: any;
  prac_hour3: any;
  prac_hour4: any;
  prac_hour5: any;
  prac_hour6: any;
  prac_hour7: any;
  prac_hour8: any;
  prac_hour9: any;
  prac_hour10: any;
  lec_per_week1: string;
  lec_per_week2: string;
  lec_per_week3: string;
  lec_per_week4: string;
  lec_per_week5: string;
  lec_per_week6: string;
  lec_per_week7: string;
  lec_per_week8: string;
  lec_per_week9: string;
  lec_per_week10: string;
  public filterText: any;
  public filterText2: any;
  filterText3: any;
  public filterText4: any;
  public filterText5: any;
  public filterText6: any;
  public filterText7: any;
  public filterText8: any;
  public filterText9: any;
  public filterText10: any;
  autocheck = true;
  markFirstOut: any;
  markSecondOut: string;
  markThirdOut: string;
  markFourthOut: string;
  markFifthOut: string;
  markSixthOut: string;
  markSeventhOut: string;
  markEighthOut: string;
  markNinthOut: string;
  markTenthOut: string;
  showcodeDropdown: boolean = true;
  showcodeDropdown2: boolean = true;
  showcodeDropdown3: boolean = true;
  showcodeDropdown4: boolean = true;
  showcodeDropdown5: boolean = true;
  showcodeDropdown6: boolean = true;
  showcodeDropdown7: boolean = true;
  showcodeDropdown8: boolean = true;
  showcodeDropdown9: boolean = true;
  showcodeDropdown10: boolean = true;
  newcourse_code = [];
  course_name;
  college_year;
  edu_level;
  semester_auto;
  subjectArray = [];
  subjectArray2 = [];
  subjectArray3 = [];
  subjectArray4 = [];
  subjectArray5 = [];
  subjectArray6 = [];
  subjectArray7 = [];
  subjectArray8 = [];
  subjectArray9 = [];
  subjectArray10 = [];
  educationData = [];
  isDisabled = false;
  note: string;
  constructor(
    protected api: ApiService,
    private router: Router,
    private userService: UserService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private confirmationService: ConfirmationService,
    protected ref: NbDialogRef<addEducationalDetailsSemesterGPA>
  ) {}

  async ngOnInit() {
    if (this.admin_show == "true") {
      this.isDisabled = false;
    } else {
      //this.edu_form.controls['lecturehourFirstCtrl'].disable();

      this.isDisabled = !this.isDisabled;
    }
    // this.userService.onUserChange()
    // .subscribe((user: any) => this.user = user);
    // if(this.user.role == 'admin'){
    //   this.admin_show = false;
    // }
    // <div class="col-md-3" *ngIf="admin_show == 'true'">Total Hours : </div>
    // <div class="col-md-2" *ngIf="admin_show == 'true'">
    //   <input  ngModel="{{edu_data?.total_weeks}}" nbInput type="text" formControlName="totalweeksCtrl" placeholder="" id="" class="form-control">
    // </div>
    
    this.edu_form = this.formBuilder.group({
      courseNameCtrl: ["", Validators.required],
      overallclassCtrl: ["", Validators.required],
      collegeYearCtrl: ["", Validators.required],
      semesterCtrl: ["", Validators.required],
      monthCtrl: ["", Validators.required],
      yearCtrl: ["", Validators.required],
      overAllGradeCtrl: ["", Validators.required],
      overAllgpasgpiCtrl: ["", Validators.required],
      subjectFirstCtrl: ["", Validators.required],
      markFirstCtrl: ["", Validators.required],
      markFirstOutCtrl: ["", Validators.required],
      gradeFirstCtrl: ["", Validators.required],
      subjectSecondCtrl: [""],
      markSecondCtrl: [""],
      markSecondOutCtrl: [""],
      gradeSecondCtrl: [""],
      subjectThirdCtrl: [""],
      markThirdCtrl: [""],
      markThirdOutCtrl: [""],
      gradeThirdCtrl: [""],
      subjectFourthCtrl: [""],
      markFourthCtrl: [""],
      markFourthOutCtrl: [""],
      gradeFourthCtrl: [""],
      subjectFifthCtrl: [""],
      markFifthCtrl: [""],
      markFifthOutCtrl: [""],
      gradeFifthCtrl: [""],
      subjectSixthCtrl: [""],
      markSixthCtrl: [""],
      markSixthOutCtrl: [""],
      gradeSixthCtrl: [""],
      subjectSeventhCtrl: [""],
      markSeventhCtrl: [""],
      markSeventhOutCtrl: [""],
      gradeSeventhCtrl: [""],
      subjectEighthCtrl: [""],
      markEighthCtrl: [""],
      markEighthOutCtrl: [""],
      gradeEighthCtrl: [""],
      subjectNinthCtrl: [""],
      markNinthCtrl: [""],
      markNinthOutCtrl: [""],
      gradeNinthCtrl: [""],
      subjectTenthCtrl: [""],
      markTenthCtrl: [""],
      markTenthOutCtrl: [""],
      gradeTenthCtrl: [""],
      instructionCtrl: ["", Validators.required],
      specialInstrctionCtrl: [""],
      finalgradespecialInstrctionCtrl: [""],
      totalweeksCtrl: [""],
      lecturehourFirstCtrl: [""],
      lecturehourSecondCtrl: [""],
      lecturehourThirdCtrl: [""],
      lecturehourFourthCtrl: [""],
      lecturehourFifthCtrl: [""],
      lecturehourSixCtrl: [""],
      lecturehourSeventhCtrl: [""],
      lecturehourEighthCtrl: [""],
      practicalhourFirstCtrl: [""],
      practicalhourSecondCtrl: [""],
      practicalhourThirdCtrl: [""],
      practicalhourFourthCtrl: [""],
      practicalhourFifthCtrl: [""],
      practicalhourSixCtrl: [""],
      practicalhourSeventhCtrl: [""],
      practicalhourEighthCtrl: [""],
      gradecreditsFirstCtrl: ["", Validators.required],
      gradecreditsSecondCtrl: [""],
      gradecreditsThirdCtrl: [""],
      gradecreditsFourthCtrl: [""],
      gradecreditsFifthCtrl: [""],
      gradecreditsSixthCtrl: [""],
      gradecreditsSeventhCtrl: [""],
      gradecreditsEighthCtrl: [""],
      gradecreditsNinthCtrl: [""],
      lecturehourNinthCtrl: [""],
      practicalhourNinthCtrl: [""],
      lecturehourtenthCtrl: [""],
      practicalhourtenthCtrl: [""],
      gradecreditsTenthCtrl: [""],
      specializationCtrl: [""],
      overAllCgpaCtrl: [""],
    });

    if (
      this.college_year_auto == "SemesterOne" ||
      this.college_year_auto == "SemesterTwo"
    ) {
      this.college_year = "First";
      if (this.college_year_auto == "SemesterOne") {
        this.semester_auto = "I";
      } else {
        this.semester_auto = "II";
      }
    } else if (
      this.college_year_auto == "SemesterThree" ||
      this.college_year_auto == "SemesterFour"
    ) {
      this.college_year = "Second";
      if (this.college_year_auto == "SemesterThree") {
        this.semester_auto = "III";
      } else {
        this.semester_auto = "IV";
      }
    } else if (
      this.college_year_auto == "SemesterFive" ||
      this.college_year_auto == "SemesterSix"
    ) {
      this.college_year = "Third";
      if (this.college_year_auto == "SemesterFive") {
        this.semester_auto = "V";
      } else {
        this.semester_auto = "VI";
      }
    }

    this.api.getCouses().subscribe((data: any) => {
      if (data["status"] == 200) {
        this.course_data = data["data"];
       
        if (this.course_name_auto != null) {
          this.course_data.forEach((element) => {
            if (
              element.CourseName == this.course_name_auto ||
              element.CourseShortForm == this.course_name_auto
            ) {
              this.course_name = element.CourseShortForm;
              
              this.edu_level = element.type;
            }
          });
        }
      } else {
      }
   

    
    this.api.getCouseCode(this.course_name,this.semester_auto).subscribe((data: any) => {
      if (data["status"] == 200) {
        this.autocheck = false;
        this.filterText = "";
        this.course_code = data["data"];
        this.filterInput.valueChanges.debounceTime(200).subscribe((term) => {
          this.filterText = term;
        });

       
      } else {
      }
    });

    this.api.getCouseCode(this.course_name,this.semester_auto).subscribe((info: any) => {
      if (info["status"] == 200) {
        this.autocheck = false;
        this.filterText2 = "";
        this.course_code2 = info["data"];

        this.filterInput2.valueChanges.debounceTime(200).subscribe((term) => {
          //this.filterText = term;
          this.filterText2 = term;
        });
       
      } else {
      }
    });
    this.api.getCouseCode(this.course_name,this.semester_auto).subscribe((data: any) => {
      if (data["status"] == 200) {
        this.autocheck = false;
        this.course_code3 = data["data"];
        this.filterText3 = "";
        this.filterInput3.valueChanges.debounceTime(200).subscribe((term) => {
          this.filterText3 = term;
          //this.filterText2 = term;
        });

       
      } else {
      }
    });

    this.api.getCouseCode(this.course_name,this.semester_auto).subscribe((data: any) => {
      if (data["status"] == 200) {
        this.autocheck = false;
        this.course_code4 = data["data"];
        this.filterText4 = "";
        this.filterInput4.valueChanges.debounceTime(200).subscribe((term) => {
          this.filterText4 = term;
          //this.filterText2 = term;
        });

       
      } else {
      }
    });

    this.api.getCouseCode(this.course_name,this.semester_auto).subscribe((data: any) => {
      if (data["status"] == 200) {
        this.autocheck = false;
        this.course_code5 = data["data"];
        this.filterText5 = "";
        this.filterInput5.valueChanges.debounceTime(200).subscribe((term) => {
          this.filterText5 = term;
          //this.filterText2 = term;
        });

       
      } else {
      }
    });

    this.api.getCouseCode(this.course_name,this.semester_auto).subscribe((data: any) => {
      if (data["status"] == 200) {
        this.autocheck = false;
        this.course_code6 = data["data"];
        this.filterText6 = "";
        this.filterInput6.valueChanges.debounceTime(200).subscribe((term) => {
          this.filterText6 = term;
          //this.filterText2 = term;
        });

       
      } else {
      }
    });

    this.api.getCouseCode(this.course_name,this.semester_auto).subscribe((data: any) => {
      if (data["status"] == 200) {
        this.autocheck = false;
        this.course_code7 = data["data"];
        this.filterText7 = "";
        this.filterInput7.valueChanges.debounceTime(200).subscribe((term) => {
          this.filterText7 = term;
          //this.filterText2 = term;
        });

       
      } else {
      }
    });

    this.api.getCouseCode(this.course_name,this.semester_auto).subscribe((data: any) => {
      if (data["status"] == 200) {
        this.autocheck = false;
        this.course_code8 = data["data"];
        this.filterText8 = "";
        this.filterInput8.valueChanges.debounceTime(200).subscribe((term) => {
          this.filterText8 = term;
          //this.filterText2 = term;
        });

       
      } else {
      }
    });

    this.api.getCouseCode(this.course_name,this.semester_auto).subscribe((data: any) => {
      if (data["status"] == 200) {
        this.autocheck = false;
        this.course_code9 = data["data"];
        this.filterText9 = "";
        this.filterInput9.valueChanges.debounceTime(200).subscribe((term) => {
          this.filterText9 = term;
          //this.filterText2 = term;
        });

       
      } else {
      }
    });

    this.api.getCouseCode(this.course_name,this.semester_auto).subscribe((data: any) => {
      if (data["status"] == 200) {
        this.autocheck = false;
        this.course_code10 = data["data"];
        this.filterText10 = "";
        this.filterInput10.valueChanges.debounceTime(200).subscribe((term) => {
          this.filterText10 = term;
          //this.filterText2 = term;
        });

       
      } else {
      }
    });

  });
  

    const now = new Date().getUTCFullYear();
    this.years_array = Array(now - (now - 50))
      .fill("")
      .map((v, idx) => now - idx);
    
    
    if (this.user_id != null && this.edu_id != null) {
      this.api
        .getEducation(this.edu_id, this.user_id)
        .subscribe((data: any) => {
          if (data["status"] == 200) {
            this.edu_data = data["data"];
            this.course_name = this.edu_data.CourseName;
            this.college_year = this.edu_data.CollegeYear;
            this.semester_auto = this.edu_data.Semester;            
            if (
              this.edu_data.course_code_one != null &&
              this.edu_data.course_code_one != "" &&
              this.edu_data.course_code_one != undefined
            ) {
              this.showcodeDropdown = false;
              setTimeout(() => {
                this.course_code.forEach((element) => {
                  if (element["Course_Code"] == this.edu_data.course_code_one) {
                    this.subjectArray.push(element);
                  }
                });
              }, 3000);
              
            } else {
              
              this.showcodeDropdown = true;
            }
            if (this.edu_data.course_code_two != null) {
              this.showcodeDropdown2 = false;
              setTimeout(() => {
                this.course_code2.forEach((element) => {
                  if (element["Course_Code"] == this.edu_data.course_code_two) {
                    this.subjectArray2.push(element);
                  }
                });
              }, 3000);
              
            } else {
              
              this.showcodeDropdown2 = true;
            }
            if (
              this.edu_data.course_code_third != null &&
              this.edu_data.course_code_third != "" &&
              this.edu_data.course_code_third != undefined
            ) {
              this.showcodeDropdown3 = false;
              
              setTimeout(() => {
                this.course_code3.forEach((element) => {
                  if (
                    element["Course_Code"] == this.edu_data.course_code_third
                  ) {
                    this.subjectArray3.push(element);
                  }
                });
              }, 3000);
            } else {
              
              this.showcodeDropdown3 = true;
            }
            if (
              this.edu_data.course_code_Fourth != null &&
              this.edu_data.course_code_Fourth != "" &&
              this.edu_data.course_code_Fourth != undefined
            ) {
              this.showcodeDropdown4 = false;
              
              setTimeout(() => {
                this.course_code4.forEach((element) => {
                  if (
                    element["Course_Code"] == this.edu_data.course_code_Fourth
                  ) {
                    this.subjectArray4.push(element);
                  }
                });
              }, 4000);
            } else {
              
              this.showcodeDropdown4 = true;
            }
            if (
              this.edu_data.course_code_Fifth != null &&
              this.edu_data.course_code_Fifth != "" &&
              this.edu_data.course_code_Fifth != undefined
            ) {
              this.showcodeDropdown5 = false;
              
              setTimeout(() => {
                this.course_code5.forEach((element) => {
                  if (
                    element["Course_Code"] == this.edu_data.course_code_Fifth
                  ) {
                    this.subjectArray5.push(element);
                  }
                });
              }, 4000);
            } else {
              
              this.showcodeDropdown5 = true;
            }
            if (
              this.edu_data.course_code_Six != null &&
              this.edu_data.course_code_Six != "" &&
              this.edu_data.course_code_Six != undefined
            ) {
              this.showcodeDropdown6 = false;
              
              setTimeout(() => {
                this.course_code6.forEach((element) => {
                  if (element["Course_Code"] == this.edu_data.course_code_Six) {
                    this.subjectArray6.push(element);
                  }
                });
              }, 4000);
            } else {
              
              this.showcodeDropdown6 = true;
            }
            if (
              this.edu_data.course_code_Seventh != null &&
              this.edu_data.course_code_Seventh != "" &&
              this.edu_data.course_code_Seventh != undefined
            ) {
              this.showcodeDropdown7 = false;
              
              setTimeout(() => {
                this.course_code7.forEach((element) => {
                  if (
                    element["Course_Code"] == this.edu_data.course_code_Seventh
                  ) {
                    this.subjectArray7.push(element);
                  }
                });
              }, 4000);
            } else {
              
              this.showcodeDropdown7 = true;
            }
            if (
              this.edu_data.course_code_Eighth != null &&
              this.edu_data.course_code_Eighth != "" &&
              this.edu_data.course_code_Eighth != undefined
            ) {
              this.showcodeDropdown8 = false;
              setTimeout(() => {
                this.course_code8.forEach((element) => {
                  if (
                    element["Course_Code"] == this.edu_data.course_code_Eighth
                  ) {
                    this.subjectArray8.push(element);
                  }
                });
              }, 5000);
              
            } else {
              
              this.showcodeDropdown8 = true;
            }
            if (
              this.edu_data.course_code_Ninth != null &&
              this.edu_data.course_code_Ninth != "" &&
              this.edu_data.course_code_Ninth != undefined
            ) {
              this.showcodeDropdown9 = false;
              setTimeout(() => {
                this.course_code9.forEach((element) => {
                  if (
                    element["Course_Code"] == this.edu_data.course_code_Ninth
                  ) {
                    this.subjectArray9.push(element);
                  }
                });
              }, 4000);
              
            } else {
              
              this.showcodeDropdown9 = true;
            }
            if (
              this.edu_data.course_code_Tenth != null &&
              this.edu_data.course_code_Tenth != "" &&
              this.edu_data.course_code_Tenth != undefined
            ) {
              this.showcodeDropdown10 = false;
              setTimeout(() => {
                this.course_code10.forEach((element) => {
                  if (
                    element["Course_Code"] == this.edu_data.course_code_Tenth
                  ) {
                    this.subjectArray10.push(element);
                  }
                });
              }, 4000);
              
            } else {
              
              this.showcodeDropdown10 = true;
            }
            if (this.edu_data.practical_hour_First) {
            } else {
              // if(this.edu_data.Subject_First && this.edu_data.Subject_Second && this.edu_data.Subject_Third && this.edu_data.Subject_Fourth && this.edu_data.Subject_Fifth && this.edu_data.Subject_Six && this.edu_data.Subject_Seventh && this.edu_data.Subject_Eighth && this.edu_data.Subject_Ninth && this.edu_data.Subject_Tenth){
              //   this.hourValue = '-'
              //   this.hourValue8= '-'
              //   this.hourValue7= '-'
              //   this.hourValue6= '-'
              //   this.hourValue5= '-'
              //   this.hourValue4= '-'
              //   this.hourValue3= '-'
              //   this.hourValue2= '-'
              //   this.hourValue9= '-'
              //   this.hourValue10= '-'
              //   this.lecVal1= '-'
              //   this.lecVal2= '-'
              //   this.lecVal3= '-'
              //   this.lecVal4= '-'
              //   this.lecVal5= '-'
              //   this.lecVal6= '-'
              //   this.lecVal7= '-'
              //   this.lecVal8= '-'
              //   this.lecVal9='-'
              //   this.lecVal10='-'
              // }else if(this.edu_data.Subject_First && this.edu_data.Subject_Second && this.edu_data.Subject_Third && this.edu_data.Subject_Fourth && this.edu_data.Subject_Fifth && this.edu_data.Subject_Six && this.edu_data.Subject_Seventh && this.edu_data.Subject_Eighth && this.edu_data.Subject_Ninth ){
              //   this.hourValue = '-'
              //   this.hourValue8= '-'
              //   this.hourValue7= '-'
              //   this.hourValue6= '-'
              //   this.hourValue5= '-'
              //   this.hourValue4= '-'
              //   this.hourValue3= '-'
              //   this.hourValue2= '-'
              //   this.hourValue9= '-'
              //   this.lecVal1= '-'
              //   this.lecVal2= '-'
              //   this.lecVal3= '-'
              //   this.lecVal4= '-'
              //   this.lecVal5= '-'
              //   this.lecVal6= '-'
              //   this.lecVal7= '-'
              //   this.lecVal8= '-'
              //   this.lecVal9='-'
              // }else if(this.edu_data.Subject_First && this.edu_data.Subject_Second && this.edu_data.Subject_Third && this.edu_data.Subject_Fourth && this.edu_data.Subject_Fifth && this.edu_data.Subject_Six && this.edu_data.Subject_Seventh && this.edu_data.Subject_Eighth ){
              //   this.hourValue = '-'
              //   this.hourValue8= '-'
              //   this.hourValue7= '-'
              //   this.hourValue6= '-'
              //   this.hourValue5= '-'
              //   this.hourValue4= '-'
              //   this.hourValue3= '-'
              //   this.hourValue2= '-'
              //   this.lecVal1= '-'
              //   this.lecVal2= '-'
              //   this.lecVal3= '-'
              //   this.lecVal4= '-'
              //   this.lecVal5= '-'
              //   this.lecVal6= '-'
              //   this.lecVal7= '-'
              //   this.lecVal8= '-'
              // }else if(this.edu_data.Subject_First && this.edu_data.Subject_Second && this.edu_data.Subject_Third && this.edu_data.Subject_Fourth && this.edu_data.Subject_Fifth && this.edu_data.Subject_Six && this.edu_data.Subject_Seventh  ){
              //   this.hourValue = '-'
              //   this.hourValue7= '-'
              //   this.hourValue6= '-'
              //   this.hourValue5= '-'
              //   this.hourValue4= '-'
              //   this.hourValue3= '-'
              //   this.hourValue2= '-'
              //   this.lecVal1= '-'
              //   this.lecVal2= '-'
              //   this.lecVal3= '-'
              //   this.lecVal4= '-'
              //   this.lecVal5= '-'
              //   this.lecVal6= '-'
              //   this.lecVal7= '-'
              // }else if(this.edu_data.Subject_First && this.edu_data.Subject_Second && this.edu_data.Subject_Third && this.edu_data.Subject_Fourth && this.edu_data.Subject_Fifth && this.edu_data.Subject_Six ){
              //   this.hourValue = '-'
              //   this.hourValue6= '-'
              //   this.hourValue5= '-'
              //   this.hourValue4= '-'
              //   this.hourValue3= '-'
              //   this.hourValue2= '-'
              //   this.lecVal1= '-'
              //   this.lecVal2= '-'
              //   this.lecVal3= '-'
              //   this.lecVal4= '-'
              //   this.lecVal5= '-'
              //   this.lecVal6= '-'
              // }else if(this.edu_data.Subject_First && this.edu_data.Subject_Second && this.edu_data.Subject_Third && this.edu_data.Subject_Fourth && this.edu_data.Subject_Fifth  ){
              //   this.hourValue = '-'
              //   this.hourValue5= '-'
              //   this.hourValue4= '-'
              //   this.hourValue3= '-'
              //   this.hourValue2= '-'
              //   this.lecVal1= '-'
              //   this.lecVal2= '-'
              //   this.lecVal3= '-'
              //   this.lecVal4= '-'
              //   this.lecVal5= '-'
              // }else if(this.edu_data.Subject_First && this.edu_data.Subject_Second && this.edu_data.Subject_Third && this.edu_data.Subject_Fourth){
              //   this.hourValue = '-'
              //   this.hourValue4= '-'
              //   this.hourValue3= '-'
              //   this.hourValue2= '-'
              //   this.lecVal1= '-'
              //   this.lecVal2= '-'
              //   this.lecVal3= '-'
              //   this.lecVal4= '-'
              // }else if(this.edu_data.Subject_First && this.edu_data.Subject_Second && this.edu_data.Subject_Third){
              //   this.hourValue = '-'
              //   this.hourValue3= '-'
              //   this.hourValue2= '-'
              //   this.lecVal1= '-'
              //   this.lecVal2= '-'
              //   this.lecVal3= '-'
              // }else if(this.edu_data.Subject_First && this.edu_data.Subject_Second){
              //   this.hourValue = '-'
              //   this.hourValue2= '-'
              //   this.lecVal1= '-'
              //   this.lecVal2= '-'
              // }else if(this.edu_data.Subject_First){
              //   this.hourValue = '-'
              //   this.lecVal1= '-'
              // }
            }
           
          } else {
            
          }
        });
    } else {
      
  
    }
  }

  getCourse(value) {
    
    //this.filterText = "";
    this.subjectName = value;
    if(this.course_name_auto == 'B.Sc.' || this.course_name_auto == 'Bachelor of Science' || this.course_name_auto == 'M.Sc.' || this.course_name_auto == 'Master of Science' || this.course_name_auto ==  'BVOC' || value.Course_name == 'BVOC'){
      if (value.prac == "yes") {
        
        this.prac_hour1 = value.Lec_per_Week;
        this.lec_per_week1 = "-";
      } else {
        this.lec_per_week1 = value.Lec_per_Week;
        this.prac_hour1 = "-";
        
      }
    }else{
      this.lec_per_week1 = value.Lec_per_Week;
      this.prac_hour1 = "";
    }

    var count = 0;
    this.newcourse_code = [];
    this.course_code.forEach((element) => {
      if (element["Course_Code"] == value["Course_Code"]) {
        this.subjectArray.push(element);
      }
      count++;
      if (element["id"] == this.subjectName["id"]) {
        
      } else {
        
        this.newcourse_code.push(element);

        
      }

      if (count == this.course_code.length) {
        this.course_code = this.newcourse_code;
      }
    });
    //this.filterPlaceholder = "Search";
    // this.adminApi.getAllCourse(value).subscribe((data)=>{
    //   this.courses = data['data'];
    // })
    // this.filterInput
    //   .valueChanges
    //   .debounceTime(200)
    //   .subscribe(term => {
    //   this.filterText = term;
    // });
  }

  getSubject(event: any) {
    
    this.withoutCodeSub = event.target.value;
    for (let a of this.course_code2) {
     
      if (a.Course_Code == this.withoutCodeSub) {
        
        this.subjectName = a;
      }
    }
    if (this.subjectName) {
      
    } else {
      
    }
  }

  getCourse2(value) {
    
    //this.filterText = "";
    this.subjectName2 = value;
    this.course_code2.forEach((element) => {
      if (element["Course_Code"] == value["Course_Code"]) {
        this.subjectArray2.push(element);
      }
    });
    if(this.course_name_auto == 'B.Sc.' || this.course_name_auto == 'Bachelor of Science' || this.course_name_auto == 'M.Sc.' || this.course_name_auto == 'Master of Science' || this.course_name_auto ==  'BVOC' || value.Course_name == 'BVOC'){
      if (value.prac == "yes") {
        
        this.prac_hour2 = value.Lec_per_Week;
        this.lec_per_week2 = "-";
      } else {
        this.lec_per_week2 = value.Lec_per_Week;
        this.prac_hour2 = "-";
        
      }
    }else{
      this.lec_per_week2 = value.Lec_per_Week;
        this.prac_hour2 = "";
    }

    var count = 0;
    this.newcourse_code = [];
    this.course_code3.forEach((element) => {
      count++;
      if (
        element["id"] == this.subjectName["id"] ||
        element["id"] == this.subjectName2["id"]
      ) {
        
      } else {
        
        this.newcourse_code.push(element);

        
      }

      if (count == this.course_code3.length) {
        this.course_code3 = [];
        this.course_code3 = this.newcourse_code;
      }
    });
  }

  getSubject2(event: any) {
    
    this.withoutCodeSub2 = event.target.value;
    for (let a of this.course_code) {
     
      if (a.Course_Code == this.withoutCodeSub2) {
        
        this.subjectName2 = a;
      }
    }
  }

  getCourse3(value) {
    this.subjectName3 = value;
    this.course_code3.forEach((element) => {
      if (element["Course_Code"] == value["Course_Code"]) {
        this.subjectArray3.push(element);
      }
    });
    if(this.course_name_auto == 'B.Sc.' || this.course_name_auto == 'Bachelor of Science' || this.course_name_auto == 'M.Sc.' || this.course_name_auto == 'Master of Science' || this.course_name_auto ==  'BVOC' || value.Course_name == 'BVOC'){
      if (value.prac == "yes") {
        
        this.prac_hour3 = value.Lec_per_Week;
        this.lec_per_week3 = "-";
      } else {
        this.lec_per_week3 = value.Lec_per_Week;
        this.prac_hour3 = "-";
        
      }
    }else{
      this.lec_per_week3 = value.Lec_per_Week;
      this.prac_hour3 = "-";
    }

    var count = 0;
    this.newcourse_code = [];
    this.course_code4.forEach((element) => {
      count++;
      if (
        element["id"] == this.subjectName["id"] ||
        element["id"] == this.subjectName2["id"] ||
        element["id"] == this.subjectName3["id"]
      ) {
        
      } else {
        
        this.newcourse_code.push(element);

        
      }

      if (count == this.course_code4.length) {
        this.course_code4 = [];
        this.course_code4 = this.newcourse_code;
      }
    });
  }

  getSubject3(event: any) {
    this.withoutCodeSub3 = event.target.value;
    for (let a of this.course_code3) {
     
      if (a.Course_Code == this.withoutCodeSub3) {
        this.subjectName3 = a;
      }
    }
  }

  getCourse4(value) {
    this.subjectName4 = value;
    this.course_code4.forEach((element) => {
      if (element["Course_Code"] == value["Course_Code"]) {
        this.subjectArray4.push(element);
      }
    });
    if(this.course_name_auto == 'B.Sc.' || this.course_name_auto == 'Bachelor of Science' || this.course_name_auto == 'M.Sc.' || this.course_name_auto == 'Master of Science' || this.course_name_auto ==  'BVOC' || value.Course_name == 'BVOC'){
      if (value.prac == "yes") {
        
        this.prac_hour4 = value.Lec_per_Week;
        this.lec_per_week4 = "-";
      } else {
        this.lec_per_week4 = value.Lec_per_Week;
        this.prac_hour4 = "-";
        
      }
    }else{
      this.lec_per_week4 = value.Lec_per_Week;
        this.prac_hour4 = "";
    }

    var count = 0;
    this.newcourse_code = [];
    this.course_code5.forEach((element) => {
      count++;
      if (
        element["id"] == this.subjectName["id"] ||
        element["id"] == this.subjectName2["id"] ||
        element["id"] == this.subjectName3["id"] ||
        element["id"] == this.subjectName4["id"]
      ) {
      } else {
        this.newcourse_code.push(element);

        
      }

      if (count == this.course_code5.length) {
        this.course_code5 = [];
        this.course_code5 = this.newcourse_code;
      }
    });
  }

  getSubject4(event: any) {
   
    this.withoutCodeSub4 = event.target.value;
    for (let a of this.course_code4) {
     
      if (a.Course_Code == this.withoutCodeSub4) {
       
        this.subjectName4 = a;
      }
    }
  }

  getCourse5(value) {
    this.subjectName5 = value;
    this.course_code5.forEach((element) => {
      if (element["Course_Code"] == value["Course_Code"]) {
        this.subjectArray5.push(element);
      }
    });
    if(this.course_name_auto == 'B.Sc.' || this.course_name_auto == 'Bachelor of Science' || this.course_name_auto == 'M.Sc.' || this.course_name_auto == 'Master of Science' || this.course_name_auto ==  'BVOC' || value.Course_name == 'BVOC'){

      if (value.prac == "yes") {
        
        this.prac_hour5 = value.Lec_per_Week;
        this.lec_per_week5 = "-";
      } else {
        this.lec_per_week5 = value.Lec_per_Week;
        this.prac_hour5 = "-";
        
      }
    }else{
      this.lec_per_week5 = value.Lec_per_Week;
        this.prac_hour5 = "";
    }

    var count = 0;
    this.newcourse_code = [];
    this.course_code6.forEach((element) => {
      count++;
      if (
        element["id"] == this.subjectName["id"] ||
        element["id"] == this.subjectName2["id"] ||
        element["id"] == this.subjectName3["id"] ||
        element["id"] == this.subjectName4["id"] ||
        element["id"] == this.subjectName5["id"]
      ) {
      } else {
        this.newcourse_code.push(element);

        
      }

      if (count == this.course_code6.length) {
        this.course_code6 = [];
        this.course_code6 = this.newcourse_code;
      }
    });
  }

  getSubject5(event: any) {
    
    this.withoutCodeSub5 = event.target.value;
    for (let a of this.course_code5) {
     
      if (a.Course_Code == this.withoutCodeSub5) {
        
        this.subjectName5 = a;
      }
    }
  }

  getCourse6(value) {
    this.subjectName6 = value;
    this.course_code6.forEach((element) => {
      if (element["Course_Code"] == value["Course_Code"]) {
        this.subjectArray6.push(element);
      }
    });
    if(this.course_name_auto == 'B.Sc.' || this.course_name_auto == 'Bachelor of Science' || this.course_name_auto == 'M.Sc.' || this.course_name_auto == 'Master of Science' || this.course_name_auto ==  'BVOC' || value.Course_name == 'BVOC'){
      if (value.prac == "yes") {
        
        this.prac_hour6 = value.Lec_per_Week;
        this.lec_per_week6 = "-";
      } else {
        this.lec_per_week6 = value.Lec_per_Week;
        this.prac_hour6 = "-";
        
      }
    }else{
      this.lec_per_week6 = value.Lec_per_Week;
        this.prac_hour6 = "";
    }

    var count = 0;
    this.newcourse_code = [];
    this.course_code7.forEach((element) => {
      count++;
      if (
        element["id"] == this.subjectName["id"] ||
        element["id"] == this.subjectName2["id"] ||
        element["id"] == this.subjectName3["id"] ||
        element["id"] == this.subjectName4["id"] ||
        element["id"] == this.subjectName5["id"] ||
        element["id"] == this.subjectName6["id"]
      ) {
      } else {
        this.newcourse_code.push(element);

        
      }

      if (count == this.course_code7.length) {
        this.course_code7 = [];
        this.course_code7 = this.newcourse_code;
      }
    });
  }

  getSubject6(event: any) {
    
    this.withoutCodeSub6 = event.target.value;
    for (let a of this.course_code6) {
     
      if (a.Course_Code == this.withoutCodeSub6) {
        
        this.subjectName6 = a;
      }
    }
  }

  getCourse7(value) {
    this.subjectName7 = value;
    this.course_code7.forEach((element) => {
      if (element["Course_Code"] == value["Course_Code"]) {
        this.subjectArray7.push(element);
      }
    });
    if(this.course_name_auto == 'B.Sc.' || this.course_name_auto == 'Bachelor of Science' || this.course_name_auto == 'M.Sc.' || this.course_name_auto == 'Master of Science' || this.course_name_auto ==  'BVOC' || value.Course_name == 'BVOC'){
      if (value.prac == "yes") {
        
        this.prac_hour7 = value.Lec_per_Week;
        this.lec_per_week7 = "-";
      } else {
        this.lec_per_week7 = value.Lec_per_Week;
        this.prac_hour7 = "-";
        
      }
    }else{
      this.lec_per_week7 = value.Lec_per_Week;
        this.prac_hour7 = "";
    }

    var count = 0;
    this.newcourse_code = [];
    this.course_code8.forEach((element) => {
      count++;
      if (
        element["id"] == this.subjectName["id"] ||
        element["id"] == this.subjectName2["id"] ||
        element["id"] == this.subjectName3["id"] ||
        element["id"] == this.subjectName4["id"] ||
        element["id"] == this.subjectName5["id"] ||
        element["id"] == this.subjectName6["id"] ||
        element["id"] == this.subjectName7["id"]
      ) {
      } else {
        this.newcourse_code.push(element);

        
      }

      if (count == this.course_code8.length) {
        this.course_code8 = [];
        this.course_code8 = this.newcourse_code;
      }
    });
  }

  getSubject8(event: any) {
   
    this.withoutCodeSub8 = event.target.value;
    for (let a of this.course_code8) {
     
      if (a.Course_Code == this.withoutCodeSub8) {
       
        this.subjectName8 = a;
      }
    }
  }
  getCourse8(value) {
    this.subjectName8 = value;
    this.course_code8.forEach((element) => {
      if (element["Course_Code"] == value["Course_Code"]) {
        this.subjectArray8.push(element);
      }
    });
    if(this.course_name_auto == 'B.Sc.' || this.course_name_auto == 'Bachelor of Science' || this.course_name_auto == 'M.Sc.' || this.course_name_auto == 'Master of Science' || this.course_name_auto ==  'BVOC' || value.Course_name == 'BVOC'){
      if (value.prac == "yes") {
        
        this.prac_hour8 = value.Lec_per_Week;
        this.lec_per_week8 = "-";
      } else {
        this.lec_per_week8 = value.Lec_per_Week;
        this.prac_hour8 = "-";
        
      }
    }else{
      this.lec_per_week8 = value.Lec_per_Week;
        this.prac_hour8 = "";
    }
  }
  getSubject9(event: any) {
    
    this.withoutCodeSub9 = event.target.value;
    for (let a of this.course_code9) {
     
      if (a.Course_Code == this.withoutCodeSub9) {
        
        this.subjectName9 = a;
      }
    }
  }
  getCourse10(value) {
    this.subjectName10 = value;
    this.course_code10.forEach((element) => {
      if (element["Course_Code"] == value["Course_Code"]) {
        this.subjectArray10.push(element);
      }
    });
    if(this.course_name_auto == 'B.Sc.' || this.course_name_auto == 'Bachelor of Science' || this.course_name_auto == 'M.Sc.' || this.course_name_auto == 'Master of Science' || this.course_name_auto ==  'BVOC' || value.Course_name == 'BVOC'){
      if (value.prac == "yes") {
        
        this.prac_hour10 = value.Lec_per_Week;
        this.lec_per_week10 = "-";
      } else {
        this.lec_per_week10 = value.Lec_per_Week;
        this.prac_hour10 = "-";
        
      }
    }else{
      this.lec_per_week10 = value.Lec_per_Week;
        this.prac_hour10 = "";
    }
  }

  getSubject10(event: any) {
    
    this.withoutCodeSub10 = event.target.value;
    for (let a of this.course_code10) {
     
      if (a.Course_Code == this.withoutCodeSub10) {
        
        this.subjectName10 = a;
      }
    }
  }
  getCourse9(value) {
    this.subjectName9 = value;
    this.course_code9.forEach((element) => {
      if (element["Course_Code"] == value["Course_Code"]) {
        this.subjectArray9.push(element);
      }
    });
    if(this.course_name_auto == 'B.Sc.' || this.course_name_auto == 'Bachelor of Science' || this.course_name_auto == 'M.Sc.' || this.course_name_auto == 'Master of Science' || this.course_name_auto ==  'BVOC' || value.Course_name == 'BVOC'){
      if (value.prac == "yes") {
        
        this.prac_hour9 = value.Lec_per_Week;
        this.lec_per_week9 = "-";
      } else {
        this.lec_per_week9 = value.Lec_per_Week;
        this.prac_hour9 = "-";
        
      }
    }else{
      this.lec_per_week9 = value.Lec_per_Week;
        this.prac_hour9 = "";
    }
  }

  getSubject7(event: any) {
    
    this.withoutCodeSub7 = event.target.value;
    for (let a of this.course_code7) {
     
      if (a.Course_Code == this.withoutCodeSub7) {
       
        this.subjectName7 = a;
      }
    }
  }

  saveEdu() {
    this.courseValidation = true;
    this.collegeYearValidation = true;
    this.semesterValidation = true;
    this.monthValidation = true;
    this.yearValidation = true;
    this.attemptValidation = true;
    this.instructionValidation = true;
    this.classValidation = true;

    if (this.edu_form.valid == false) {
      this.edu_form.controls.courseNameCtrl.markAsDirty();
      this.edu_form.controls.overAllGradeCtrl.markAsDirty();
      this.edu_form.controls.overAllgpasgpiCtrl.markAsDirty();
      this.edu_form.controls.subjectFirstCtrl.markAsDirty();
      this.edu_form.controls.markFirstCtrl.markAsDirty();
      this.edu_form.controls.markFirstOutCtrl.markAsDirty();
      this.edu_form.controls.gradeFirstCtrl.markAsDirty();
      // this.edu_form.controls.subjectSecondCtrl.markAsDirty();
      // this.edu_form.controls.markSecondCtrl.markAsDirty();
      // this.edu_form.controls.markSecondOutCtrl.markAsDirty();
      // this.edu_form.controls.gradeSecondCtrl.markAsDirty();
      // this.edu_form.controls.subjectThirdCtrl.markAsDirty();
      // this.edu_form.controls.markThirdCtrl.markAsDirty();
      // this.edu_form.controls.markThirdOutCtrl.markAsDirty();
      // this.edu_form.controls.gradeThirdCtrl.markAsDirty();
      // this.edu_form.controls.subjectFourthCtrl.markAsDirty();
      // this.edu_form.controls.markFourthCtrl.markAsDirty();
      // this.edu_form.controls.markFourthOutCtrl.markAsDirty();
      // this.edu_form.controls.gradeFourthCtrl.markAsDirty();
      this.edu_form.controls.instructionCtrl.markAsDirty();

      if (
        this.edu_form.controls.instructionCtrl.value === null ||
        this.edu_form.controls.instructionCtrl.value === "" ||
        this.edu_form.controls.instructionCtrl.value === undefined
      ) {
        this.instructionValidation = false;
      } else {
        this.instructionValidation = true;
      }

      if (
        this.edu_form.controls.courseNameCtrl.value === null ||
        this.edu_form.controls.courseNameCtrl.value === "" ||
        this.edu_form.controls.courseNameCtrl.value === undefined
      ) {
        this.courseValidation = false;
      } else {
        this.courseValidation = true;
      }

      if (
        this.edu_form.controls.collegeYearCtrl.value === null ||
        this.edu_form.controls.collegeYearCtrl.value === "" ||
        this.edu_form.controls.collegeYearCtrl.value === undefined
      ) {
        this.collegeYearValidation = false;
      } else {
        this.collegeYearValidation = true;
      }

      if (
        this.edu_form.controls.overallclassCtrl.value === null ||
        this.edu_form.controls.overallclassCtrl.value === "" ||
        this.edu_form.controls.overallclassCtrl.value === undefined
      ) {
        this.classValidation = false;
      } else {
        this.classValidation = true;
      }

      if (
        this.edu_form.controls.semesterCtrl.value === null ||
        this.edu_form.controls.semesterCtrl.value === "" ||
        this.edu_form.controls.semesterCtrl.value === undefined
      ) {
        this.semesterValidation = false;
      } else {
        this.semesterValidation = true;
      }

      if (
        this.edu_form.controls.monthCtrl.value === null ||
        this.edu_form.controls.monthCtrl.value === "" ||
        this.edu_form.controls.monthCtrl.value === undefined
      ) {
        this.monthValidation = false;
      } else {
        this.monthValidation = true;
      }

      if (
        this.edu_form.controls.yearCtrl.value === null ||
        this.edu_form.controls.yearCtrl.value === "" ||
        this.edu_form.controls.yearCtrl.value === undefined
      ) {
        this.yearValidation = false;
      } else {
        this.yearValidation = true;
      }
    } else {
      if (this.edu_form.controls.markFirstOutCtrl.value) {
        var a = this.edu_form.controls.markFirstOutCtrl.value.split(".");

        if (a[1]) {
        } else {
          this.markFirstOut =
            this.edu_form.controls.markFirstOutCtrl.value + ".00";
        }
        // if(this.edu_form.controls.markFirstOutCtrl.value.indexOf(".") == 1){
        
        // }else{
        //   this.markFirstOut = this.edu_form.controls.markFirstOutCtrl.value+'.00'

        // }
      }

      if (this.edu_form.controls.markSecondOutCtrl.value) {
        var a = this.edu_form.controls.markSecondOutCtrl.value.split(".");

        if (a[1]) {
        } else {
          this.markSecondOut =
            this.edu_form.controls.markSecondOutCtrl.value + ".00";
        }
        // if(this.edu_form.controls.markSecondOutCtrl.value.indexOf(".") == 1){
        
        // }else{
        //   this.markSecondOut = this.edu_form.controls.markSecondOutCtrl.value+'.00'

        // }
      }

      if (this.edu_form.controls.markThirdOutCtrl.value) {
        var a = this.edu_form.controls.markThirdOutCtrl.value.split(".");

        if (a[1]) {
        } else {
          this.markThirdOut =
            this.edu_form.controls.markThirdOutCtrl.value + ".00";
        }
        // if(this.edu_form.controls.markThirdOutCtrl.value.indexOf(".") == 1){
        
        // }else{
        //   this.markThirdOut = this.edu_form.controls.markThirdOutCtrl.value+'.00'

        // }
      }

      if (this.edu_form.controls.markFourthOutCtrl.value) {
        var a = this.edu_form.controls.markFourthOutCtrl.value.split(".");

        if (a[1]) {
        } else {
          this.markFourthOut =
            this.edu_form.controls.markFourthOutCtrl.value + ".00";
        }
        // if(this.edu_form.controls.markFourthOutCtrl.value.indexOf(".") == 1){

        // }else{
        //   this.markFourthOut = this.edu_form.controls.markFourthOutCtrl.value+'.00'

        // }
      }

      if (this.edu_form.controls.markFifthOutCtrl.value) {
        var a = this.edu_form.controls.markFifthOutCtrl.value.split(".");

        if (a[1]) {
        } else {
          this.markFifthOut =
            this.edu_form.controls.markFifthOutCtrl.value + ".00";
        }
        // if(this.edu_form.controls.markFifthOutCtrl.value.indexOf(".") == 1){

        // }else{
        //   this.markFifthOut = this.edu_form.controls.markFifthOutCtrl.value+'.00'

        // }
      }

      if (this.edu_form.controls.markSixthOutCtrl.value) {
        var a = this.edu_form.controls.markSixthOutCtrl.value.split(".");

        if (a[1]) {
        } else {
          this.markSixthOut =
            this.edu_form.controls.markSixthOutCtrl.value + ".00";
        }
        // if(this.edu_form.controls.markSixthOutCtrl.value.indexOf(".") == 1){

        // }else{
        //   this.markSixthOut = this.edu_form.controls.markSixthOutCtrl.value+'.00'

        // }
      }

      if (this.edu_form.controls.markSeventhOutCtrl.value) {
        var a = this.edu_form.controls.markSeventhOutCtrl.value.split(".");

        if (a[1]) {
        } else {
          this.markSeventhOut =
            this.edu_form.controls.markSeventhOutCtrl.value + ".00";
        }

        // if(this.edu_form.controls.markSeventhOutCtrl.value.indexOf(".") == 1){

        // }else{
        //   this.markSeventhOut = this.edu_form.controls.markSeventhOutCtrl.value+'.00'

        // }
      }

      if (this.edu_form.controls.markEighthOutCtrl.value) {
        var a = this.edu_form.controls.markEighthOutCtrl.value.split(".");

        if (a[1]) {
        } else {
          this.markEighthOut =
            this.edu_form.controls.markEighthOutCtrl.value + ".00";
        }
        // if(this.edu_form.controls.markEighthOutCtrl.value.indexOf(".") == 1){

        // }else{
        //   this.markEighthOut = this.edu_form.controls.markEighthOutCtrl.value+'.00'

        // }
      }

      if (this.edu_form.controls.markNinthOutCtrl.value) {
        var a = this.edu_form.controls.markNinthOutCtrl.value.split(".");

        if (a[1]) {
        } else {
          this.markNinthOut =
            this.edu_form.controls.markNinthOutCtrl.value + ".00";
        }
        // if(this.edu_form.controls.markNinthOutCtrl.value.indexOf(".") == 1){

        // }else{
        //   this.markNinthOut = this.edu_form.controls.markNinthOutCtrl.value+'.00'

        // }
      }

      if (this.edu_form.controls.markTenthOutCtrl.value) {
        var a = this.edu_form.controls.markTenthOutCtrl.value.split(".");

        if (a[1]) {
        } else {
          this.markTenthOut =
            this.edu_form.controls.markTenthOutCtrl.value + ".00";
        }
        // if(this.edu_form.controls.markTenthOutCtrl.value.indexOf(".") == 1){

        // }else{
        //   this.markTenthOut = this.edu_form.controls.markTenthOutCtrl.value+'.00'

        // }
      }

      var edu_data = {
        courseName: this.edu_form.controls.courseNameCtrl.value,
        collegeYear: this.edu_form.controls.collegeYearCtrl.value,
        semester: this.edu_form.controls.semesterCtrl.value,
        month: this.edu_form.controls.monthCtrl.value,
        year: this.edu_form.controls.yearCtrl.value,
        overAllGrade: this.edu_form.controls.overAllGradeCtrl.value,
        overall_gpa_sgpi: this.edu_form.controls.overAllgpasgpiCtrl.value,
        subjectFirst: this.edu_form.controls.subjectFirstCtrl.value,
        marks_obt_First: this.edu_form.controls.markFirstCtrl.value,
        marks_out_First: this.markFirstOut
          ? this.markFirstOut
          : this.edu_form.controls.markFirstOutCtrl.value,
        markFirst: this.edu_form.controls.gradeFirstCtrl.value.toUpperCase(),
        subjectSecond: this.edu_form.controls.subjectSecondCtrl.value,
        marks_obt_Second: this.edu_form.controls.markSecondCtrl.value,
        marks_out_Second: this.markSecondOut
          ? this.markSecondOut
          : this.edu_form.controls.markSecondOutCtrl.value,
        markSecond: this.edu_form.controls.gradeSecondCtrl.value
          ? this.edu_form.controls.gradeSecondCtrl.value.toUpperCase()
          : "",
        subjectThird: this.edu_form.controls.subjectThirdCtrl.value,
        marks_obt_Third: this.edu_form.controls.markThirdCtrl.value,
        marks_out_Third: this.markThirdOut
          ? this.markThirdOut
          : this.edu_form.controls.markThirdOutCtrl.value,
        markThird: this.edu_form.controls.gradeThirdCtrl.value
          ? this.edu_form.controls.gradeThirdCtrl.value.toUpperCase()
          : "",
        subjectFourth: this.edu_form.controls.subjectFourthCtrl.value,
        marks_obt_Fourth: this.edu_form.controls.markFourthCtrl.value,
        marks_out_Fourth: this.markFourthOut
          ? this.markFourthOut
          : this.edu_form.controls.markFourthOutCtrl.value,
        markFourth: this.edu_form.controls.gradeFourthCtrl.value
          ? this.edu_form.controls.gradeFourthCtrl.value.toUpperCase()
          : "",
        subjectFifth: this.edu_form.controls.subjectFifthCtrl.value,
        marks_obt_Fifth: this.edu_form.controls.markFifthCtrl.value,
        marks_out_Fifth: this.markFifthOut
          ? this.markFifthOut
          : this.edu_form.controls.markFifthOutCtrl.value,
        markFifth: this.edu_form.controls.gradeFifthCtrl.value
          ? this.edu_form.controls.gradeFifthCtrl.value.toUpperCase()
          : "",
        overall_class: this.edu_form.controls.overallclassCtrl.value,
        subjectSixth: this.edu_form.controls.subjectSixthCtrl.value,
        marks_obt_Six: this.edu_form.controls.markSixthCtrl.value,
        marks_out_Six: this.markSixthOut
          ? this.markSixthOut
          : this.edu_form.controls.markSixthOutCtrl.value,
        markSixth: this.edu_form.controls.gradeSixthCtrl.value
          ? this.edu_form.controls.gradeSixthCtrl.value.toUpperCase()
          : "",
        subjectSeventh: this.edu_form.controls.subjectSeventhCtrl.value,
        marks_obt_Seventh: this.edu_form.controls.markSeventhCtrl.value,
        marks_out_Seventh: this.markSeventhOut
          ? this.markSeventhOut
          : this.edu_form.controls.markSeventhOutCtrl.value,
        markSeventh: this.edu_form.controls.gradeSeventhCtrl.value
          ? this.edu_form.controls.gradeSeventhCtrl.value.toUpperCase()
          : "",
        subjectEighth: this.edu_form.controls.subjectEighthCtrl.value,
        marks_obt_Eighth: this.edu_form.controls.markEighthCtrl.value,
        marks_out_Eighth: this.markEighthOut
          ? this.markEighthOut
          : this.edu_form.controls.markEighthOutCtrl.value,
        markEighth: this.edu_form.controls.gradeEighthCtrl.value
          ? this.edu_form.controls.gradeEighthCtrl.value.toUpperCase()
          : "",
        subjectNinth: this.edu_form.controls.subjectNinthCtrl.value,
        marks_obt_Ninth: this.edu_form.controls.markNinthCtrl.value,
        marks_out_Ninth: this.markNinthOut
          ? this.markNinthOut
          : this.edu_form.controls.markNinthOutCtrl.value,
        markNinth: this.edu_form.controls.gradeNinthCtrl.value
          ? this.edu_form.controls.gradeNinthCtrl.value.toUpperCase()
          : "",
        subjectTenth: this.edu_form.controls.subjectTenthCtrl.value,
        marks_obt_Tenth: this.edu_form.controls.markTenthCtrl.value,
        marks_out_Tenth: this.markTenthOut
          ? this.markTenthOut
          : this.edu_form.controls.markTenthOutCtrl.value,
        markTenth: this.edu_form.controls.gradeTenthCtrl.value
          ? this.edu_form.controls.gradeTenthCtrl.value.toUpperCase()
          : "",
        user_id: this.user_id,
        medium_instruction: this.edu_form.controls.instructionCtrl.value,
        id: this.edu_id,
        total_weeks: this.edu_form.controls.totalweeksCtrl.value,
        special_instruction: this.edu_form.controls.specialInstrctionCtrl.value,
        lecture_hour_First: this.subjectName
          ? this.lec_per_week1
          : this.edu_form.controls.lecturehourFirstCtrl.value,
        lecture_hour_Second: this.subjectName2
          ? this.lec_per_week2
          : this.edu_form.controls.lecturehourSecondCtrl.value,
        lecture_hour_Third: this.subjectName3
          ? this.lec_per_week3
          : this.edu_form.controls.lecturehourThirdCtrl.value,
        lecture_hour_Fourth: this.subjectName4
          ? this.lec_per_week4
          : this.edu_form.controls.lecturehourFourthCtrl.value,
        lecture_hour_Fifth: this.subjectName5
          ? this.lec_per_week5
          : this.edu_form.controls.lecturehourFifthCtrl.value,
        lecture_hour_Six: this.subjectName6
          ? this.lec_per_week6
          : this.edu_form.controls.lecturehourSixCtrl.value,
        lecture_hour_Seventh: this.subjectName7
          ? this.lec_per_week7
          : this.edu_form.controls.lecturehourSeventhCtrl.value,
        lecture_hour_Eighth: this.subjectName8
          ? this.lec_per_week8
          : this.edu_form.controls.lecturehourEighthCtrl.value,
        practical_hour_First: this.subjectName
          ? this.prac_hour1
          : this.edu_form.controls.practicalhourFirstCtrl.value,
        practical_hour_Second: this.subjectName2
          ? this.prac_hour2
          : this.edu_form.controls.practicalhourSecondCtrl.value,
        practical_hour_Third: this.subjectName3
          ? this.prac_hour3
          : this.edu_form.controls.practicalhourThirdCtrl.value,
        practical_hour_Fourth: this.subjectName4
          ? this.prac_hour4
          : this.edu_form.controls.practicalhourFourthCtrl.value,
        practical_hour_Fifth: this.subjectName5
          ? this.prac_hour5
          : this.edu_form.controls.practicalhourFifthCtrl.value,
        practical_hour_Six: this.subjectName6
          ? this.prac_hour6
          : this.edu_form.controls.practicalhourSixCtrl.value,
        practical_hour_Seventh: this.subjectName7
          ? this.prac_hour7
          : this.edu_form.controls.practicalhourSeventhCtrl.value,
        practical_hour_Eighth: this.subjectName8
          ? this.prac_hour8
          : this.edu_form.controls.practicalhourEighthCtrl.value,
        final_grade_special_instruction:
          this.edu_form.controls.finalgradespecialInstrctionCtrl.value,
        grade_credits_Six: this.edu_form.controls.gradecreditsSixthCtrl.value,
        grade_credits_First: this.edu_form.controls.gradecreditsFirstCtrl.value,
        grade_credits_Second:
          this.edu_form.controls.gradecreditsSecondCtrl.value,
        grade_credits_Third: this.edu_form.controls.gradecreditsThirdCtrl.value,
        grade_credits_Fourth:
          this.edu_form.controls.gradecreditsFourthCtrl.value,
        grade_credits_Fifth: this.edu_form.controls.gradecreditsFifthCtrl.value,
        grade_credits_Seventh:
          this.edu_form.controls.gradecreditsSeventhCtrl.value,
        grade_credits_Eighth:
          this.edu_form.controls.gradecreditsEighthCtrl.value,
        lecture_hour_Ninth: this.subjectName9
          ? this.lec_per_week9
          : this.edu_form.controls.lecturehourNinthCtrl.value,
        lecture_hour_Tenth: this.subjectName10
          ? this.lec_per_week10
          : this.edu_form.controls.lecturehourtenthCtrl.value,
        grade_credits_Ninth: this.edu_form.controls.gradecreditsNinthCtrl.value,
        grade_credits_Tenth: this.edu_form.controls.gradecreditsTenthCtrl.value,
        practical_hour_Ninth: this.subjectName9
          ? this.prac_hour9
          : this.edu_form.controls.practicalhourNinthCtrl.value,
        practical_hour_Tenth: this.subjectName10
          ? this.prac_hour10
          : this.edu_form.controls.practicalhourtenthCtrl.value,
        specialization: this.edu_form.controls.specializationCtrl.value,
        overAllYearlyGrade: this.edu_form.controls.overAllCgpaCtrl.value,
        course_code_one: this.subjectName
          ? this.subjectName["Course_Code"]
          : this.withoutCodeSub,
        course_code_two: this.subjectName2
          ? this.subjectName2["Course_Code"]
          : this.withoutCodeSub2,
        course_code_third: this.subjectName3
          ? this.subjectName3["Course_Code"]
          : this.withoutCodeSub3,
        course_code_Fourth: this.subjectName4
          ? this.subjectName4["Course_Code"]
          : this.withoutCodeSub4,
        course_code_Fifth: this.subjectName5
          ? this.subjectName5["Course_Code"]
          : this.withoutCodeSub5,
        course_code_Six: this.subjectName6
          ? this.subjectName6["Course_Code"]
          : this.withoutCodeSub6,
        course_code_Seventh: this.subjectName7
          ? this.subjectName7["Course_Code"]
          : this.withoutCodeSub7,
        course_code_Eighth: this.subjectName8
          ? this.subjectName8["Course_Code"]
          : this.withoutCodeSub8,
        course_code_Ninth: this.subjectName9
          ? this.subjectName9["Course_Code"]
          : this.withoutCodeSub9,
        course_code_Tenth: this.subjectName10
          ? this.subjectName10["Course_Code"]
          : this.withoutCodeSub10,
      };

      this.api.setEducationalValues(edu_data).subscribe((data: any) => {
        this.ref.close(data);
        (err) => console.error(err);
      });
    }
  }

  dismiss() {
    this.ref.close();
  }

  getTotalCredits() {
    
    this.totalMarks = 0;
    

    if (
      this.edu_form.controls.markSecondCtrl.value != "" &&
      this.edu_form.controls.markThirdCtrl.value != "" &&
      this.edu_form.controls.markFourthCtrl.value != "" &&
      this.edu_form.controls.markFifthCtrl.value != "" &&
      this.edu_form.controls.markSixthCtrl.value != "" &&
      this.edu_form.controls.markSeventhCtrl.value != "" &&
      this.edu_form.controls.markEighthCtrl.value != "" &&
      this.edu_form.controls.markNinthCtrl.value != "" &&
      this.edu_form.controls.markTenthCtrl.value != ""
    ) {
      this.totalMarks =
        Number(this.edu_form.controls.markFirstCtrl.value) +
        Number(this.edu_form.controls.markSecondCtrl.value) +
        Number(this.edu_form.controls.markThirdCtrl.value) +
        Number(this.edu_form.controls.markFourthCtrl.value) +
        Number(this.edu_form.controls.markFifthCtrl.value) +
        Number(this.edu_form.controls.markSixthCtrl.value) +
        Number(this.edu_form.controls.markSeventhCtrl.value) +
        Number(this.edu_form.controls.markEighthCtrl.value) +
        Number(this.edu_form.controls.markNinthCtrl.value) +
        Number(this.edu_form.controls.markTenthCtrl.value);
      
    } else if (
      this.edu_form.controls.markSecondCtrl.value != "" &&
      this.edu_form.controls.markThirdCtrl.value != "" &&
      this.edu_form.controls.markFourthCtrl.value != "" &&
      this.edu_form.controls.markFifthCtrl.value != "" &&
      this.edu_form.controls.markSixthCtrl.value != "" &&
      this.edu_form.controls.markSeventhCtrl.value != "" &&
      this.edu_form.controls.markEighthCtrl.value != "" &&
      this.edu_form.controls.markNinthCtrl.value != ""
    ) {
      this.totalMarks =
      Number(this.edu_form.controls.markFirstCtrl.value) +
      Number(this.edu_form.controls.markSecondCtrl.value) +
      Number(this.edu_form.controls.markThirdCtrl.value) +
      Number(this.edu_form.controls.markFourthCtrl.value) +
      Number(this.edu_form.controls.markFifthCtrl.value) +
      Number(this.edu_form.controls.markSixthCtrl.value) +
      Number(this.edu_form.controls.markSeventhCtrl.value) +
      Number(this.edu_form.controls.markEighthCtrl.value) +
      Number(this.edu_form.controls.markNinthCtrl.value);
      
    } 
    //======================
   else if (
      this.edu_form.controls.markSecondCtrl.value != "" &&
      this.edu_form.controls.markThirdCtrl.value != "" &&
      this.edu_form.controls.markFourthCtrl.value != "" &&
      this.edu_form.controls.markFifthCtrl.value != "" &&
      this.edu_form.controls.markSixthCtrl.value != "" &&
      this.edu_form.controls.markSeventhCtrl.value != "" &&
      this.edu_form.controls.markEighthCtrl.value != ""
    ) {
      this.totalMarks =
        Number(this.edu_form.controls.markFirstCtrl.value) +
        Number(this.edu_form.controls.markSecondCtrl.value) +
        Number(this.edu_form.controls.markThirdCtrl.value) +
        Number(this.edu_form.controls.markFourthCtrl.value) +
        Number(this.edu_form.controls.markFifthCtrl.value) +
        Number(this.edu_form.controls.markSixthCtrl.value) +
        Number(this.edu_form.controls.markSeventhCtrl.value) +
        Number(this.edu_form.controls.markEighthCtrl.value);
      
    } else if (
      this.edu_form.controls.markSecondCtrl.value != "" &&
      this.edu_form.controls.markThirdCtrl.value != "" &&
      this.edu_form.controls.markFourthCtrl.value != "" &&
      this.edu_form.controls.markFifthCtrl.value != "" &&
      this.edu_form.controls.markSixthCtrl.value != "" &&
      this.edu_form.controls.markSeventhCtrl.value != ""
    ) {
      this.totalMarks =
        Number(this.edu_form.controls.markFirstCtrl.value) +
        Number(this.edu_form.controls.markSecondCtrl.value) +
        Number(this.edu_form.controls.markThirdCtrl.value) +
        Number(this.edu_form.controls.markFourthCtrl.value) +
        Number(this.edu_form.controls.markFifthCtrl.value) +
        Number(this.edu_form.controls.markSixthCtrl.value) +
        Number(this.edu_form.controls.markSeventhCtrl.value);
      
    } else if (
      this.edu_form.controls.markSecondCtrl.value != "" &&
      this.edu_form.controls.markThirdCtrl.value != "" &&
      this.edu_form.controls.markFourthCtrl.value != "" &&
      this.edu_form.controls.markFifthCtrl.value != "" &&
      this.edu_form.controls.markSixthCtrl.value != ""
    ) {
      this.totalMarks =
        Number(this.edu_form.controls.markFirstCtrl.value) +
        Number(this.edu_form.controls.markSecondCtrl.value) +
        Number(this.edu_form.controls.markThirdCtrl.value) +
        Number(this.edu_form.controls.markFourthCtrl.value) +
        Number(this.edu_form.controls.markFifthCtrl.value) +
        Number(this.edu_form.controls.markSixthCtrl.value);
      
    } else if (
      this.edu_form.controls.markSecondCtrl.value != "" &&
      this.edu_form.controls.markThirdCtrl.value != "" &&
      this.edu_form.controls.markFourthCtrl.value != "" &&
      this.edu_form.controls.markFifthCtrl.value != ""
    ) {
      this.totalMarks =
        Number(this.edu_form.controls.markFirstCtrl.value) +
        Number(this.edu_form.controls.markSecondCtrl.value) +
        Number(this.edu_form.controls.markThirdCtrl.value) +
        Number(this.edu_form.controls.markFourthCtrl.value) +
        Number(this.edu_form.controls.markFifthCtrl.value);
      
    } else if (
      this.edu_form.controls.markSecondCtrl.value != "" &&
      this.edu_form.controls.markThirdCtrl.value != "" &&
      this.edu_form.controls.markFourthCtrl.value != ""
    ) {
      this.totalMarks =
        Number(this.edu_form.controls.markFirstCtrl.value) +
        Number(this.edu_form.controls.markSecondCtrl.value) +
        Number(this.edu_form.controls.markThirdCtrl.value) +
        Number(this.edu_form.controls.markFourthCtrl.value);
      
    } else if (
      this.edu_form.controls.markSecondCtrl.value != "" &&
      this.edu_form.controls.markThirdCtrl.value != ""
    ) {
      this.totalMarks =
        Number(this.edu_form.controls.markFirstCtrl.value) +
        Number(this.edu_form.controls.markSecondCtrl.value) +
        Number(this.edu_form.controls.markThirdCtrl.value);
      
    } else if (this.edu_form.controls.markSecondCtrl.value != "") {
      this.totalMarks =
        Number(this.edu_form.controls.markSecondCtrl.value) +
        Number(this.edu_form.controls.markFirstCtrl.value);
      
    }
  }
  getTotalCnG() {
    
    this.totalCnG = 0;
    
    if (
      this.edu_form.controls.gradecreditsFirstCtrl.value &&
      this.edu_form.controls.gradecreditsSecondCtrl.value &&
      this.edu_form.controls.gradecreditsThirdCtrl.value &&
      this.edu_form.controls.gradecreditsFourthCtrl.value &&
      this.edu_form.controls.gradecreditsFifthCtrl.value &&
      this.edu_form.controls.gradecreditsSixthCtrl.value &&
      this.edu_form.controls.gradecreditsSeventhCtrl.value &&
      this.edu_form.controls.gradecreditsEighthCtrl.value &&
      this.edu_form.controls.gradecreditsNinthCtrl.value &&
      this.edu_form.controls.gradecreditsTenthCtrl.value
    ) {
      this.totalCnG =
        Number(this.edu_form.controls.gradecreditsFirstCtrl.value) +
        Number(this.edu_form.controls.gradecreditsSecondCtrl.value) +
        Number(this.edu_form.controls.gradecreditsThirdCtrl.value) +
        Number(this.edu_form.controls.gradecreditsFourthCtrl.value) +
        Number(this.edu_form.controls.gradecreditsFifthCtrl.value) +
        Number(this.edu_form.controls.gradecreditsSixthCtrl.value) +
        Number(this.edu_form.controls.gradecreditsSeventhCtrl.value) +
        Number(this.edu_form.controls.gradecreditsEighthCtrl.value) +
        Number(this.edu_form.controls.gradecreditsNinthCtrl.value) +
        Number(this.edu_form.controls.gradecreditsTenthCtrl.value);
        
    } else if (
      this.edu_form.controls.gradecreditsFirstCtrl.value &&
      this.edu_form.controls.gradecreditsSecondCtrl.value &&
      this.edu_form.controls.gradecreditsThirdCtrl.value &&
      this.edu_form.controls.gradecreditsFourthCtrl.value &&
      this.edu_form.controls.gradecreditsFifthCtrl.value &&
      this.edu_form.controls.gradecreditsSixthCtrl.value &&
      this.edu_form.controls.gradecreditsSeventhCtrl.value &&
      this.edu_form.controls.gradecreditsEighthCtrl.value &&
      this.edu_form.controls.gradecreditsNinthCtrl.value
    ) {
      this.totalCnG =
        Number(this.edu_form.controls.gradecreditsFirstCtrl.value) +
        Number(this.edu_form.controls.gradecreditsSecondCtrl.value) +
        Number(this.edu_form.controls.gradecreditsThirdCtrl.value) +
        Number(this.edu_form.controls.gradecreditsFourthCtrl.value) +
        Number(this.edu_form.controls.gradecreditsFifthCtrl.value) +
        Number(this.edu_form.controls.gradecreditsSixthCtrl.value) +
        Number(this.edu_form.controls.gradecreditsSeventhCtrl.value) +
        Number(this.edu_form.controls.gradecreditsEighthCtrl.value) +
        Number(this.edu_form.controls.gradecreditsNinthCtrl.value);
        
    } else if (
      this.edu_form.controls.gradecreditsFirstCtrl.value &&
      this.edu_form.controls.gradecreditsSecondCtrl.value &&
      this.edu_form.controls.gradecreditsThirdCtrl.value &&
      this.edu_form.controls.gradecreditsFourthCtrl.value &&
      this.edu_form.controls.gradecreditsFifthCtrl.value &&
      this.edu_form.controls.gradecreditsSixthCtrl.value &&
      this.edu_form.controls.gradecreditsSeventhCtrl.value &&
      this.edu_form.controls.gradecreditsEighthCtrl.value
    ) {
      this.totalCnG =
        Number(this.edu_form.controls.gradecreditsFirstCtrl.value) +
        Number(this.edu_form.controls.gradecreditsSecondCtrl.value) +
        Number(this.edu_form.controls.gradecreditsThirdCtrl.value) +
        Number(this.edu_form.controls.gradecreditsFourthCtrl.value) +
        Number(this.edu_form.controls.gradecreditsFifthCtrl.value) +
        Number(this.edu_form.controls.gradecreditsSixthCtrl.value) +
        Number(this.edu_form.controls.gradecreditsSeventhCtrl.value) +
        Number(this.edu_form.controls.gradecreditsEighthCtrl.value);
        
    } else if (
      this.edu_form.controls.gradecreditsFirstCtrl.value &&
      this.edu_form.controls.gradecreditsSecondCtrl.value &&
      this.edu_form.controls.gradecreditsThirdCtrl.value &&
      this.edu_form.controls.gradecreditsFourthCtrl.value &&
      this.edu_form.controls.gradecreditsFifthCtrl.value &&
      this.edu_form.controls.gradecreditsSixthCtrl.value &&
      this.edu_form.controls.gradecreditsSeventhCtrl.value
    ) {
      this.totalCnG =
        Number(this.edu_form.controls.gradecreditsFirstCtrl.value) +
        Number(this.edu_form.controls.gradecreditsSecondCtrl.value) +
        Number(this.edu_form.controls.gradecreditsThirdCtrl.value) +
        Number(this.edu_form.controls.gradecreditsFourthCtrl.value) +
        Number(this.edu_form.controls.gradecreditsFifthCtrl.value) +
        Number(this.edu_form.controls.gradecreditsSixthCtrl.value) +
        Number(this.edu_form.controls.gradecreditsSeventhCtrl.value);
        
    } else if (
      this.edu_form.controls.gradecreditsFirstCtrl.value &&
      this.edu_form.controls.gradecreditsSecondCtrl.value &&
      this.edu_form.controls.gradecreditsThirdCtrl.value &&
      this.edu_form.controls.gradecreditsFourthCtrl.value &&
      this.edu_form.controls.gradecreditsFifthCtrl.value &&
      this.edu_form.controls.gradecreditsSixthCtrl.value
    ) {
      this.totalCnG =
        Number(this.edu_form.controls.gradecreditsFirstCtrl.value) +
        Number(this.edu_form.controls.gradecreditsSecondCtrl.value) +
        Number(this.edu_form.controls.gradecreditsThirdCtrl.value) +
        Number(this.edu_form.controls.gradecreditsFourthCtrl.value) +
        Number(this.edu_form.controls.gradecreditsFifthCtrl.value) +
        Number(this.edu_form.controls.gradecreditsSixthCtrl.value);
        
    } else if (
      this.edu_form.controls.gradecreditsFirstCtrl.value &&
      this.edu_form.controls.gradecreditsSecondCtrl.value &&
      this.edu_form.controls.gradecreditsThirdCtrl.value &&
      this.edu_form.controls.gradecreditsFourthCtrl.value &&
      this.edu_form.controls.gradecreditsFifthCtrl.value
    ) {
      this.totalCnG =
        Number(this.edu_form.controls.gradecreditsFirstCtrl.value) +
        Number(this.edu_form.controls.gradecreditsSecondCtrl.value) +
        Number(this.edu_form.controls.gradecreditsThirdCtrl.value) +
        Number(this.edu_form.controls.gradecreditsFourthCtrl.value) +
        Number(this.edu_form.controls.gradecreditsFifthCtrl.value);
        
    } else if (
      this.edu_form.controls.gradecreditsFirstCtrl.value &&
      this.edu_form.controls.gradecreditsSecondCtrl.value &&
      this.edu_form.controls.gradecreditsThirdCtrl.value &&
      this.edu_form.controls.gradecreditsFourthCtrl.value
    ) {
      this.totalCnG =
        Number(this.edu_form.controls.gradecreditsFirstCtrl.value) +
        Number(this.edu_form.controls.gradecreditsSecondCtrl.value) +
        Number(this.edu_form.controls.gradecreditsThirdCtrl.value) +
        Number(this.edu_form.controls.gradecreditsFourthCtrl.value);
        
    } else if (
      this.edu_form.controls.gradecreditsFirstCtrl.value &&
      this.edu_form.controls.gradecreditsSecondCtrl.value &&
      this.edu_form.controls.gradecreditsThirdCtrl.value
    ) {
      this.totalCnG =
        Number(this.edu_form.controls.gradecreditsFirstCtrl.value) +
        Number(this.edu_form.controls.gradecreditsSecondCtrl.value) +
        Number(this.edu_form.controls.gradecreditsThirdCtrl.value);
        
    } else if (
      this.edu_form.controls.gradecreditsFirstCtrl.value &&
      this.edu_form.controls.gradecreditsSecondCtrl.value
    ) {
      this.totalCnG =
        Number(this.edu_form.controls.gradecreditsFirstCtrl.value) +
        Number(this.edu_form.controls.gradecreditsSecondCtrl.value);
        
    }

    if (this.totalCnG && this.totalMarks) {
      this.totalcgpa = Number(this.totalCnG) / Number(this.totalMarks);
      this.totalcgpa = Number(this.totalcgpa.toFixed(2));
    }

    

  }

  totalSgpa(){
    
    
    
    this.totalsgpa = 0;
    if (
      this.edu_form.controls.gradecreditsFirstCtrl.value &&
      this.edu_form.controls.gradecreditsSecondCtrl.value &&
      this.edu_form.controls.gradecreditsThirdCtrl.value &&
      this.edu_form.controls.gradecreditsFourthCtrl.value &&
      this.edu_form.controls.gradecreditsFifthCtrl.value &&
      this.edu_form.controls.gradecreditsSixthCtrl.value &&
      this.edu_form.controls.gradecreditsSeventhCtrl.value &&
      this.edu_form.controls.gradecreditsEighthCtrl.value &&
      this.edu_form.controls.gradecreditsNinthCtrl.value &&
      this.edu_form.controls.gradecreditsTenthCtrl.value
    ){
      this.totalcg = Number(this.edu_form.controls.gradecreditsFirstCtrl.value) + Number(this.edu_form.controls.gradecreditsSecondCtrl.value) + Number(this.edu_form.controls.gradecreditsThirdCtrl.value) + Number(this.edu_form.controls.gradecreditsFourthCtrl.value) + Number(this.edu_form.controls.gradecreditsFifthCtrl.value) + Number(this.edu_form.controls.gradecreditsSixthCtrl.value) + Number(this.edu_form.controls.gradecreditsSeventhCtrl.value) + Number(this.edu_form.controls.gradecreditsEighthCtrl.value) + Number(this.edu_form.controls.gradecreditsNinthCtrl.value) + Number(this.edu_form.controls.gradecreditsTenthCtrl.value);
    }else if (
      this.edu_form.controls.gradecreditsFirstCtrl.value &&
      this.edu_form.controls.gradecreditsSecondCtrl.value &&
      this.edu_form.controls.gradecreditsThirdCtrl.value &&
      this.edu_form.controls.gradecreditsFourthCtrl.value &&
      this.edu_form.controls.gradecreditsFifthCtrl.value &&
      this.edu_form.controls.gradecreditsSixthCtrl.value &&
      this.edu_form.controls.gradecreditsSeventhCtrl.value &&
      this.edu_form.controls.gradecreditsEighthCtrl.value &&
      this.edu_form.controls.gradecreditsNinthCtrl.value
    ) {
      this.totalcg = Number(this.edu_form.controls.gradecreditsFirstCtrl.value) + Number(this.edu_form.controls.gradecreditsSecondCtrl.value) + Number(this.edu_form.controls.gradecreditsThirdCtrl.value) + Number(this.edu_form.controls.gradecreditsFourthCtrl.value) + Number(this.edu_form.controls.gradecreditsFifthCtrl.value) + Number(this.edu_form.controls.gradecreditsSixthCtrl.value) + Number(this.edu_form.controls.gradecreditsSeventhCtrl.value) + Number(this.edu_form.controls.gradecreditsEighthCtrl.value) + Number(this.edu_form.controls.gradecreditsNinthCtrl.value);
        
    } else if (
      this.edu_form.controls.gradecreditsFirstCtrl.value &&
      this.edu_form.controls.gradecreditsSecondCtrl.value &&
      this.edu_form.controls.gradecreditsThirdCtrl.value &&
      this.edu_form.controls.gradecreditsFourthCtrl.value &&
      this.edu_form.controls.gradecreditsFifthCtrl.value &&
      this.edu_form.controls.gradecreditsSixthCtrl.value &&
      this.edu_form.controls.gradecreditsSeventhCtrl.value &&
      this.edu_form.controls.gradecreditsEighthCtrl.value
    ) {
      this.totalcg = Number(this.edu_form.controls.gradecreditsFirstCtrl.value) + Number(this.edu_form.controls.gradecreditsSecondCtrl.value) + Number(this.edu_form.controls.gradecreditsThirdCtrl.value) + Number(this.edu_form.controls.gradecreditsFourthCtrl.value) + Number(this.edu_form.controls.gradecreditsFifthCtrl.value) + Number(this.edu_form.controls.gradecreditsSixthCtrl.value) + Number(this.edu_form.controls.gradecreditsSeventhCtrl.value) + Number(this.edu_form.controls.gradecreditsEighthCtrl.value);
        
    } else if (
      this.edu_form.controls.gradecreditsFirstCtrl.value &&
      this.edu_form.controls.gradecreditsSecondCtrl.value &&
      this.edu_form.controls.gradecreditsThirdCtrl.value &&
      this.edu_form.controls.gradecreditsFourthCtrl.value &&
      this.edu_form.controls.gradecreditsFifthCtrl.value &&
      this.edu_form.controls.gradecreditsSixthCtrl.value &&
      this.edu_form.controls.gradecreditsSeventhCtrl.value
    ) {
      this.totalcg = Number(this.edu_form.controls.gradecreditsFirstCtrl.value) + Number(this.edu_form.controls.gradecreditsSecondCtrl.value) + Number(this.edu_form.controls.gradecreditsThirdCtrl.value) + Number(this.edu_form.controls.gradecreditsFourthCtrl.value) + Number(this.edu_form.controls.gradecreditsFifthCtrl.value) + Number(this.edu_form.controls.gradecreditsSixthCtrl.value) + Number(this.edu_form.controls.gradecreditsSeventhCtrl.value);
        
    } else if (
      this.edu_form.controls.gradecreditsFirstCtrl.value &&
      this.edu_form.controls.gradecreditsSecondCtrl.value &&
      this.edu_form.controls.gradecreditsThirdCtrl.value &&
      this.edu_form.controls.gradecreditsFourthCtrl.value &&
      this.edu_form.controls.gradecreditsFifthCtrl.value &&
      this.edu_form.controls.gradecreditsSixthCtrl.value
    ) {
      this.totalcg = Number(this.edu_form.controls.gradecreditsFirstCtrl.value) + Number(this.edu_form.controls.gradecreditsSecondCtrl.value) + Number(this.edu_form.controls.gradecreditsThirdCtrl.value) + Number(this.edu_form.controls.gradecreditsFourthCtrl.value) + Number(this.edu_form.controls.gradecreditsFifthCtrl.value) + Number(this.edu_form.controls.gradecreditsSixthCtrl.value);
        
    } else if (
      this.edu_form.controls.gradecreditsFirstCtrl.value &&
      this.edu_form.controls.gradecreditsSecondCtrl.value &&
      this.edu_form.controls.gradecreditsThirdCtrl.value &&
      this.edu_form.controls.gradecreditsFourthCtrl.value &&
      this.edu_form.controls.gradecreditsFifthCtrl.value
    ) {
      this.totalcg =
        Number(this.edu_form.controls.gradecreditsFirstCtrl.value) + Number(this.edu_form.controls.gradecreditsSecondCtrl.value) + Number(this.edu_form.controls.gradecreditsThirdCtrl.value) + Number(this.edu_form.controls.gradecreditsFourthCtrl.value) + Number(this.edu_form.controls.gradecreditsFifthCtrl.value);
        
    } else if (
        this.edu_form.controls.gradecreditsFourthCtrl.value &&
        this.edu_form.controls.gradecreditsThirdCtrl.value &&
        this.edu_form.controls.gradecreditsSecondCtrl.value &&
        this.edu_form.controls.gradecreditsFirstCtrl.value
      ){
        this.totalcg = Number(this.edu_form.controls.gradecreditsFirstCtrl.value) + Number(this.edu_form.controls.gradecreditsSecondCtrl.value) + Number(this.edu_form.controls.gradecreditsThirdCtrl.value) + Number(this.edu_form.controls.gradecreditsFourthCtrl.value) ;
      }else if (
        this.edu_form.controls.gradecreditsFirstCtrl.value &&
        this.edu_form.controls.gradecreditsSecondCtrl.value &&
        this.edu_form.controls.gradecreditsThirdCtrl.value
      ) {
        this.totalcg = Number(this.edu_form.controls.gradecreditsFirstCtrl.value) + Number(this.edu_form.controls.gradecreditsSecondCtrl.value) + Number(this.edu_form.controls.gradecreditsThirdCtrl.value);
          
      } else if (
        this.edu_form.controls.gradecreditsFirstCtrl.value &&
        this.edu_form.controls.gradecreditsSecondCtrl.value
      ) {
        this.totalcg = Number(this.edu_form.controls.gradecreditsFirstCtrl.value) + Number(this.edu_form.controls.gradecreditsSecondCtrl.value);
          
      }
      
    this.totalsgpa = Number(this.totalcg)/Number(this.totalMarks)
    this.totalsgpa = Number(this.totalsgpa.toFixed(2))
    
  }

  totalCgpa(){
    
    
    
    this.overallCGPA = 0;
    this.CgpaValue = 0;
    

    
    
    this.api.getSgpa(this.user_id).subscribe((data: any) => {
      this.educationData = data['data'];
      
      var flag = 0;
      var total = 0;
      switch(this.semester_auto){
          case 'I' :  if(this.educationData.length>0){
            this.educationData.forEach(year=>{
                        if(year.Semester == 'I'){
                          
                          this.overallCGPA = year.OverAllGrade;
                          
                          // this.overallCGPA = Number(this.overallCGPA.toFixed(2))
                        }else{
                          this.overallCGPA = this.totalsgpa;
                          
                          // this.overallCGPA = Number(this.overallCGPA.toFixed(2))
                        }
                      })
                    }else{
                      this.overallCGPA = this.totalsgpa;
                          
                    }
                    break;
          case 'II' : if(this.educationData.length>0){
                        this.educationData.forEach(year=>{
                          if(year.Semester == 'I'){
                            flag ++;
                            total = parseFloat(year.OverAllGrade);
                          }
                        })
                        if(flag == 1){
                          this.overallCGPA += total + this.totalsgpa;
                          this.overallCGPA /= 2;
                          this.overallCGPA = Number(this.overallCGPA.toFixed(2))
                        } else{
                          this.note = "";
                        }
                      }
                      else
                      {
                        this.note = "";
                      }
                    break;
          case 'III' : if(this.educationData.length>0){
                          this.educationData.forEach(year=>{
                        if(year.Semester == 'I' || year.Semester == 'II'){
                          flag ++;
                          total += parseFloat(year.OverAllGrade);
                        }
                      })
                      if(flag == 2){
                        this.overallCGPA += total  + this.totalsgpa;
                        this.overallCGPA /= 3;
                        this.overallCGPA = Number(this.overallCGPA.toFixed(2))
                      }else{
                        this.note = "";
                      }
                    }
                    else{
                      this.note = "";
                    }
                    break;
          case 'IV' : if(this.educationData.length>0){
                        this.educationData.forEach(year=>{
                        
                        if(year.Semester == 'I' || year.Semester == 'II' || year.Semester == 'III'){
                          
                          flag ++;
                          total += parseFloat(year.OverAllGrade);
                          
                        }
                      })
                    
                      if(flag == 3){
                        this.overallCGPA += total  + this.totalsgpa;
                        this.overallCGPA /= 4;
                        this.overallCGPA = Number(this.overallCGPA.toFixed(2))
                      
                      }else{
                        this.note = "";
                      }
                  }
                  else{
                    this.note = ""
                  }

                  break;
          case 'V' : if(this.educationData.length>0){
                      this.educationData.forEach(year=>{
                      if(year.Semester == 'I' || year.Semester == 'II' || year.Semester == 'III' || year.Semester == 'IV'){
                        flag ++;
                        total += parseFloat(year.OverAllGrade);
                      }
                    })
                    if(flag == 4){
                      
                      this.overallCGPA += total  + this.totalsgpa;
                      
                      this.overallCGPA /= 5;
                      this.overallCGPA = Number(this.overallCGPA.toFixed(2))
                      
                    }else{
                      this.note = "";
                    }
                  }
                else{
                  this.note = "";
                }
                break;
          case 'VI' : if(this.educationData.length>0){
                  this.educationData.forEach(year=>{
                    if(year.Semester == 'I' || year.Semester == 'II' || year.Semester == 'III' || year.Semester == 'IV'||  year.Semester == 'V'){
                      flag ++;
                      total += parseFloat(year.OverAllGrade);
                    }
                  })
                  if(flag == 5){
                    this.overallCGPA += total  + this.totalsgpa;
                    this.overallCGPA /= 6;
                    this.overallCGPA = Number(this.overallCGPA.toFixed(2))
                  }else{
                    this.note = "";
                  }
                }else{
                  this.note = "";
                }
              break;
        }
       
      
    })
    

    
   
    

    


  }

  copyHoursValue() {
    
    if (this.edu_form.controls.practicalhourFirstCtrl.value) {
      if (
        this.edu_form.controls.subjectFirstCtrl.value &&
        this.edu_form.controls.subjectSecondCtrl.value &&
        this.edu_form.controls.subjectThirdCtrl.value &&
        this.edu_form.controls.subjectFourthCtrl.value &&
        this.edu_form.controls.subjectFifthCtrl.value &&
        this.edu_form.controls.subjectSixthCtrl.value &&
        this.edu_form.controls.subjectSeventhCtrl.value &&
        this.edu_form.controls.subjectEighthCtrl.value
      ) {
        this.hourValue = this.edu_form.controls.practicalhourFirstCtrl.value;
        this.hourValue2 = this.edu_form.controls.practicalhourFirstCtrl.value;
        this.hourValue3 = this.edu_form.controls.practicalhourFirstCtrl.value;
        this.hourValue4 = this.edu_form.controls.practicalhourFirstCtrl.value;
        this.hourValue5 = this.edu_form.controls.practicalhourFirstCtrl.value;
        this.hourValue6 = this.edu_form.controls.practicalhourFirstCtrl.value;
        this.hourValue7 = this.edu_form.controls.practicalhourFirstCtrl.value;
        this.hourValue8 = this.edu_form.controls.practicalhourFirstCtrl.value;
      } else if (
        this.edu_form.controls.subjectFirstCtrl.value &&
        this.edu_form.controls.subjectSecondCtrl.value &&
        this.edu_form.controls.subjectThirdCtrl.value &&
        this.edu_form.controls.subjectFourthCtrl.value &&
        this.edu_form.controls.subjectFifthCtrl.value &&
        this.edu_form.controls.subjectSixthCtrl.value &&
        this.edu_form.controls.subjectSeventhCtrl.value
      ) {
        this.hourValue = this.edu_form.controls.practicalhourFirstCtrl.value;
        this.hourValue2 = this.edu_form.controls.practicalhourFirstCtrl.value;
        this.hourValue3 = this.edu_form.controls.practicalhourFirstCtrl.value;
        this.hourValue4 = this.edu_form.controls.practicalhourFirstCtrl.value;
        this.hourValue5 = this.edu_form.controls.practicalhourFirstCtrl.value;
        this.hourValue6 = this.edu_form.controls.practicalhourFirstCtrl.value;
        this.hourValue7 = this.edu_form.controls.practicalhourFirstCtrl.value;
      } else if (
        this.edu_form.controls.subjectFirstCtrl.value &&
        this.edu_form.controls.subjectSecondCtrl.value &&
        this.edu_form.controls.subjectThirdCtrl.value &&
        this.edu_form.controls.subjectFourthCtrl.value &&
        this.edu_form.controls.subjectFifthCtrl.value &&
        this.edu_form.controls.subjectSixthCtrl.value
      ) {
        this.hourValue = this.edu_form.controls.practicalhourFirstCtrl.value;
        this.hourValue2 = this.edu_form.controls.practicalhourFirstCtrl.value;
        this.hourValue3 = this.edu_form.controls.practicalhourFirstCtrl.value;
        this.hourValue4 = this.edu_form.controls.practicalhourFirstCtrl.value;
        this.hourValue5 = this.edu_form.controls.practicalhourFirstCtrl.value;
        this.hourValue6 = this.edu_form.controls.practicalhourFirstCtrl.value;
      } else if (
        this.edu_form.controls.subjectFirstCtrl.value &&
        this.edu_form.controls.subjectSecondCtrl.value &&
        this.edu_form.controls.subjectThirdCtrl.value &&
        this.edu_form.controls.subjectFourthCtrl.value &&
        this.edu_form.controls.subjectFifthCtrl.value
      ) {
        this.hourValue = this.edu_form.controls.practicalhourFirstCtrl.value;
        this.hourValue2 = this.edu_form.controls.practicalhourFirstCtrl.value;
        this.hourValue3 = this.edu_form.controls.practicalhourFirstCtrl.value;
        this.hourValue4 = this.edu_form.controls.practicalhourFirstCtrl.value;
        this.hourValue5 = this.edu_form.controls.practicalhourFirstCtrl.value;
      } else if (
        this.edu_form.controls.subjectFirstCtrl.value &&
        this.edu_form.controls.subjectSecondCtrl.value &&
        this.edu_form.controls.subjectThirdCtrl.value &&
        this.edu_form.controls.subjectFourthCtrl.value
      ) {
        this.hourValue = this.edu_form.controls.practicalhourFirstCtrl.value;
        this.hourValue2 = this.edu_form.controls.practicalhourFirstCtrl.value;
        this.hourValue3 = this.edu_form.controls.practicalhourFirstCtrl.value;
        this.hourValue4 = this.edu_form.controls.practicalhourFirstCtrl.value;
      } else if (
        this.edu_form.controls.subjectFirstCtrl.value &&
        this.edu_form.controls.subjectSecondCtrl.value &&
        this.edu_form.controls.subjectThirdCtrl.value
      ) {
        this.hourValue = this.edu_form.controls.practicalhourFirstCtrl.value;
        this.hourValue2 = this.edu_form.controls.practicalhourFirstCtrl.value;
        this.hourValue3 = this.edu_form.controls.practicalhourFirstCtrl.value;
      } else if (
        this.edu_form.controls.subjectFirstCtrl.value &&
        this.edu_form.controls.subjectSecondCtrl.value
      ) {
        this.hourValue = this.edu_form.controls.practicalhourFirstCtrl.value;
        this.hourValue2 = this.edu_form.controls.practicalhourFirstCtrl.value;
      }
    }
  }

  copyLecValue() {
    if (this.edu_form.controls.lecturehourFirstCtrl.value) {
      if (
        this.edu_form.controls.subjectFirstCtrl.value &&
        this.edu_form.controls.subjectSecondCtrl.value &&
        this.edu_form.controls.subjectThirdCtrl.value &&
        this.edu_form.controls.subjectFourthCtrl.value &&
        this.edu_form.controls.subjectFifthCtrl.value &&
        this.edu_form.controls.subjectSixthCtrl.value &&
        this.edu_form.controls.subjectSeventhCtrl.value &&
        this.edu_form.controls.subjectEighthCtrl.value
      ) {
        this.lecVal1 = this.edu_form.controls.lecturehourFirstCtrl.value;
        this.lecVal2 = this.edu_form.controls.lecturehourFirstCtrl.value;
        this.lecVal3 = this.edu_form.controls.lecturehourFirstCtrl.value;
        this.lecVal4 = this.edu_form.controls.lecturehourFirstCtrl.value;
        this.lecVal5 = this.edu_form.controls.lecturehourFirstCtrl.value;
        this.lecVal6 = this.edu_form.controls.lecturehourFirstCtrl.value;
        this.lecVal7 = this.edu_form.controls.lecturehourFirstCtrl.value;
        this.lecVal8 = this.edu_form.controls.lecturehourFirstCtrl.value;
      } else if (
        this.edu_form.controls.subjectFirstCtrl.value &&
        this.edu_form.controls.subjectSecondCtrl.value &&
        this.edu_form.controls.subjectThirdCtrl.value &&
        this.edu_form.controls.subjectFourthCtrl.value &&
        this.edu_form.controls.subjectFifthCtrl.value &&
        this.edu_form.controls.subjectSixthCtrl.value &&
        this.edu_form.controls.subjectSeventhCtrl.value
      ) {
        this.lecVal1 = this.edu_form.controls.lecturehourFirstCtrl.value;
        this.lecVal2 = this.edu_form.controls.lecturehourFirstCtrl.value;
        this.lecVal3 = this.edu_form.controls.lecturehourFirstCtrl.value;
        this.lecVal4 = this.edu_form.controls.lecturehourFirstCtrl.value;
        this.lecVal5 = this.edu_form.controls.lecturehourFirstCtrl.value;
        this.lecVal6 = this.edu_form.controls.lecturehourFirstCtrl.value;
        this.lecVal7 = this.edu_form.controls.lecturehourFirstCtrl.value;
      } else if (
        this.edu_form.controls.subjectFirstCtrl.value &&
        this.edu_form.controls.subjectSecondCtrl.value &&
        this.edu_form.controls.subjectThirdCtrl.value &&
        this.edu_form.controls.subjectFourthCtrl.value &&
        this.edu_form.controls.subjectFifthCtrl.value &&
        this.edu_form.controls.subjectSixthCtrl.value
      ) {
        this.lecVal1 = this.edu_form.controls.lecturehourFirstCtrl.value;
        this.lecVal2 = this.edu_form.controls.lecturehourFirstCtrl.value;
        this.lecVal3 = this.edu_form.controls.lecturehourFirstCtrl.value;
        this.lecVal4 = this.edu_form.controls.lecturehourFirstCtrl.value;
        this.lecVal5 = this.edu_form.controls.lecturehourFirstCtrl.value;
        this.lecVal6 = this.edu_form.controls.lecturehourFirstCtrl.value;
      } else if (
        this.edu_form.controls.subjectFirstCtrl.value &&
        this.edu_form.controls.subjectSecondCtrl.value &&
        this.edu_form.controls.subjectThirdCtrl.value &&
        this.edu_form.controls.subjectFourthCtrl.value &&
        this.edu_form.controls.subjectFifthCtrl.value
      ) {
        this.lecVal1 = this.edu_form.controls.lecturehourFirstCtrl.value;
        this.lecVal2 = this.edu_form.controls.lecturehourFirstCtrl.value;
        this.lecVal3 = this.edu_form.controls.lecturehourFirstCtrl.value;
        this.lecVal4 = this.edu_form.controls.lecturehourFirstCtrl.value;
        this.lecVal5 = this.edu_form.controls.lecturehourFirstCtrl.value;
      } else if (
        this.edu_form.controls.subjectFirstCtrl.value &&
        this.edu_form.controls.subjectSecondCtrl.value &&
        this.edu_form.controls.subjectThirdCtrl.value &&
        this.edu_form.controls.subjectFourthCtrl.value
      ) {
        this.lecVal1 = this.edu_form.controls.lecturehourFirstCtrl.value;
        this.lecVal2 = this.edu_form.controls.lecturehourFirstCtrl.value;
        this.lecVal3 = this.edu_form.controls.lecturehourFirstCtrl.value;
        this.lecVal4 = this.edu_form.controls.lecturehourFirstCtrl.value;
      } else if (
        this.edu_form.controls.subjectFirstCtrl.value &&
        this.edu_form.controls.subjectSecondCtrl.value &&
        this.edu_form.controls.subjectThirdCtrl.value
      ) {
        this.lecVal1 = this.edu_form.controls.lecturehourFirstCtrl.value;
        this.lecVal2 = this.edu_form.controls.lecturehourFirstCtrl.value;
        this.lecVal3 = this.edu_form.controls.lecturehourFirstCtrl.value;
      } else if (
        this.edu_form.controls.subjectFirstCtrl.value &&
        this.edu_form.controls.subjectSecondCtrl.value
      ) {
        this.lecVal1 = this.edu_form.controls.lecturehourFirstCtrl.value;
        this.lecVal2 = this.edu_form.controls.lecturehourFirstCtrl.value;
      }
    }
  }

  getcXg() {
    if (
      this.edu_form.controls.markFirstCtrl.value &&
      this.edu_form.controls.markFirstOutCtrl.value
    ) {
      this.getcxg1 =
        Number(this.edu_form.controls.markFirstCtrl.value) *
        Number(this.edu_form.controls.markFirstOutCtrl.value);
    }

    if (
      this.edu_form.controls.markSecondCtrl.value &&
      this.edu_form.controls.markSecondOutCtrl.value
    ) {
      this.getcxg2 =
        Number(this.edu_form.controls.markSecondCtrl.value) *
        Number(this.edu_form.controls.markSecondOutCtrl.value);
    }

    if (
      this.edu_form.controls.markThirdCtrl.value &&
      this.edu_form.controls.markThirdOutCtrl.value
    ) {
      this.getcxg3 =
        Number(this.edu_form.controls.markThirdCtrl.value) *
        Number(this.edu_form.controls.markThirdOutCtrl.value);
    }

    if (
      this.edu_form.controls.markFourthCtrl.value &&
      this.edu_form.controls.markFourthOutCtrl.value
    ) {
      this.getcxg4 =
        Number(this.edu_form.controls.markFourthCtrl.value) *
        Number(this.edu_form.controls.markFourthOutCtrl.value);
    }

    if (
      this.edu_form.controls.markFifthCtrl.value &&
      this.edu_form.controls.markFifthOutCtrl.value
    ) {
      this.getcxg5 =
        Number(this.edu_form.controls.markFifthCtrl.value) *
        Number(this.edu_form.controls.markFifthOutCtrl.value);
    }

    if (
      this.edu_form.controls.markSixthCtrl.value &&
      this.edu_form.controls.markSixthOutCtrl.value
    ) {
      this.getcxg6 =
        Number(this.edu_form.controls.markSixthCtrl.value) *
        Number(this.edu_form.controls.markSixthOutCtrl.value);
    }

    if (
      this.edu_form.controls.markSeventhCtrl.value &&
      this.edu_form.controls.markSeventhOutCtrl.value
    ) {
      this.getcxg7 =
        Number(this.edu_form.controls.markSeventhCtrl.value) *
        Number(this.edu_form.controls.markSeventhOutCtrl.value);
    }

    if (
      this.edu_form.controls.markEighthCtrl.value &&
      this.edu_form.controls.markEighthOutCtrl.value
    ) {
      this.getcxg8 =
        Number(this.edu_form.controls.markEighthCtrl.value) *
        Number(this.edu_form.controls.markEighthOutCtrl.value);
    }

    if (
      this.edu_form.controls.markNinthCtrl.value &&
      this.edu_form.controls.markNinthOutCtrl.value
    ) {
      this.getcxg9 =
        Number(this.edu_form.controls.markNinthCtrl.value) *
        Number(this.edu_form.controls.markNinthOutCtrl.value);
    }

    if (
      this.edu_form.controls.markTenthCtrl.value &&
      this.edu_form.controls.markTenthOutCtrl.value
    ) {
      this.getcxg10 =
        Number(this.edu_form.controls.markTenthCtrl.value) *
        Number(this.edu_form.controls.markTenthOutCtrl.value);
    }

    // if (
    //   this.edu_form.controls.gradecreditsFirstCtrl.value &&
    //   this.edu_form.controls.gradecreditsSecondCtrl.value &&
    //   this.edu_form.controls.gradecreditsThirdCtrl.value &&
    //   this.edu_form.controls.gradecreditsFourthCtrl.value &&
    //   this.edu_form.controls.gradecreditsFifthCtrl.value &&
    //   this.edu_form.controls.gradecreditsSixthCtrl.value &&
    //   this.edu_form.controls.gradecreditsSeventhCtrl.value &&
    //   this.edu_form.controls.gradecreditsEighthCtrl.value &&
    //   this.edu_form.controls.gradecreditsNinthCtrl.value &&
    //   this.edu_form.controls.gradecreditsTenthCtrl.value
    // ){
    //   this.totalcg = Number(this.edu_form.controls.gradecreditsFirstCtrl.value) + Number(this.getcxg2) + Number(this.getcxg3) + Number(this.getcxg4) + Number(this.getcxg5) + Number(this.getcxg6) + Number(this.getcxg7) + Number(this.getcxg8) + Number(this.getcxg9) + Number(this.getcxg10);
    // } else if (
    //   this.edu_form.controls.gradecreditsFourthCtrl.value &&
    //   this.edu_form.controls.gradecreditsThirdCtrl.value &&
    //   this.edu_form.controls.gradecreditsSecondCtrl.value &&
    //   this.edu_form.controls.gradecreditsFirstCtrl.value
    // ){
    //   this.totalcg = Number(this.edu_form.controls.gradecreditsFirstCtrl.value) + Number(this.edu_form.controls.gradecreditsSecondCtrl.value) + Number(this.edu_form.controls.gradecreditsThirdCtrl.value) + Number(this.edu_form.controls.gradecreditsFourthCtrl.value) ;
    // }
    

  }

  showcoursecodeDropdown() {
    this.showcodeDropdown = true;
  }

  showcoursecodeDropdown2() {
    this.showcodeDropdown2 = true;
  }

  showcoursecodeDropdown3() {
    this.showcodeDropdown3 = true;
  }

  showcoursecodeDropdown4() {
    this.showcodeDropdown4 = true;
  }

  showcoursecodeDropdown5() {
    this.showcodeDropdown5 = true;
  }

  showcoursecodeDropdown6() {
    this.showcodeDropdown6 = true;
  }

  showcoursecodeDropdown7() {
    this.showcodeDropdown7 = true;
  }

  showcoursecodeDropdown8() {
    this.showcodeDropdown8 = true;
  }

  showcoursecodeDropdown9() {
    this.showcodeDropdown9 = true;
  }

  showcoursecodeDropdown10() {
    this.showcodeDropdown10 = true;
  }

  showContactDetails() {
    this.confirmationService.confirm({
      message:
        "Email - stxaviers@etranscript.in &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp;" +
        " Whatsapp - +91 99306 00066",
      header: "Please contact the admin on ",
      icon: "pi pi-exclamation-triangle",
      accept: () => {},
      reject: () => {},
    });
  }
}
